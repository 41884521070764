import { Select } from 'antd'
import { IconPlus } from '_svg'
import { useParams } from 'react-router-dom'
import Layout from 'components/layout/Layout'
import Button from 'components/buttons/Button'
import Sidebar from 'components/_widgets/Sidebar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { widgetsActions, modalActions, userActions, accountActions } from '_actions'
import ThemeButton from 'components/buttons/ThemeButton'
import StatusRadio from 'components/buttons/StatusRadio'
import HeaderWithTabs from 'components/_widgets/HeaderWithTabs'
import CollectionListItem from 'components/_collections/CollectionListItem'
import { accountEmpty, userEmpty } from '_services/Utils'

function WidgetSettings() {
  const dispatch = useDispatch()
  const { Option } = Select
  const urlParams = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const [widget, setWidget] = useState({
    theme: null,
    collections: [],
    created_at: '',
    hash: '',
    id: null,
    last_updated: '',
    limit: null,
    name: null,
    sort_by: null,
    font: null,
    font_url: null,
    color1: null,
    color2: null,
  })

  const [widgetForHeader, setWidgetForHeader] = useState({
    name: null,
  })

  const account = useSelector((state) => state.account)
  const user = useSelector((state) => state.authentication.user)

  useEffect(() => {
    if (accountEmpty(account)) dispatch(accountActions.getSelf())
    if (userEmpty(user)) dispatch(userActions.getSelf(`/widgets/${urlParams.id}/settings`))
  }, [user, account, dispatch, urlParams])

  const widgetsRdux = useSelector((state) => state.widgets.items)
  // to update widget collection
  const widgetCreationCollection = useSelector((state) => state.widgets.create.collection)

  useEffect(() => {
    if (widgetsRdux.length <= 0) {
      loadWidgets()
    } else {
      const myWidget = widgetsRdux.find((widget) => widget.id === urlParams.id)
      setWidget({ ...widget, ...myWidget })
      setWidgetForHeader(myWidget)
    }
    setIsLoading(false)
  }, [widgetsRdux])

  useEffect(() => {
    if (widget.id) setWidget({ ...widget, collections: [widgetCreationCollection] })
  }, [widgetCreationCollection])

  const loadWidgets = () => {
    if (isLoading) return
    setIsLoading(true)
    dispatch(widgetsActions.getAllWidgets())
  }

  const submitForm = (e) => {
    e.preventDefault()
    setIsLoading(true)
    // destructuring to keep only wanted parameters
    const { created_at, hash, last_updated, assets, ...formData } = widget
    // convert array of objects to array of object's id
    formData.collections = formData.collections.map((c) => c.id)
    dispatch(widgetsActions.updateWidget(formData))
  }

  const changeStatus = (value) => {
    setWidget((widget) => {
      return { ...widget, ...{ status: value } }
    })
  }

  const changeTheme = (value) => {
    setWidget((widget) => {
      return { ...widget, ...{ theme: value } }
    })
  }

  const deleteWidget = (e) => {
    dispatch(modalActions.deleteWidget(widget))
  }

  const chooseCollection = (e) => {
    dispatch(modalActions.chooseCollection(widget))
  }

  const getCollection = () => {
    if (widget.id) return <CollectionListItem data={widget.collections[0]} />
  }

  return (
    <Layout className="page page-widget-settings">
      <HeaderWithTabs widget={widgetForHeader} />
      <div className="with-sidebar">
        <div className="page__content">
          <div className="page__intro">Modifiez les réglages de votre widget et enregistrez vos changements.</div>
          <div className="fluid-grid">
            <form action="" onSubmit={submitForm}>
              <div className="col-12 md-col-8">
                <div className="text-24">Général</div>
                <label htmlFor="name" className="label">
                  Nom de votre widget
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="text-input"
                  defaultValue={widget.name}
                  placeholder="Nom*"
                  required
                  onChange={(e) => {
                    const temp = {}
                    temp.name = e.currentTarget.value
                    setWidget({ ...widget, ...temp })
                  }}
                />

                <label htmlFor="limit" className="label">
                  Nombre de medias maximum
                </label>
                <Select
                  name="limit"
                  value={widget.limit}
                  onChange={(value) => {
                    const temp = {}
                    temp.limit = value
                    setWidget({ ...widget, ...temp })
                  }}
                >
                  <Option value="10">10</Option>
                  <Option value="20">20</Option>
                  <Option value="50">50</Option>
                </Select>

                <label htmlFor="status" className="label">
                  Statut du feed
                </label>
                <div className="flex">
                  <StatusRadio
                    value="1"
                    label="Actif"
                    onChangeStatus={changeStatus}
                    isDefaultChecked={widget.status === '1'}
                  />
                  <StatusRadio
                    value="0"
                    label="Brouillon"
                    onChangeStatus={changeStatus}
                    isDefaultChecked={widget.status === '0'}
                  />
                </div>

                <label htmlFor="theme" className="label">
                  Thème du widget
                </label>
                <div className="flex">
                  <ThemeButton
                    value="slider"
                    label="Slider"
                    onChangeTheme={changeTheme}
                    isDefaultChecked={widget.theme === 'slider'}
                  />
                  <ThemeButton
                    value="wall"
                    label="Mosaïque"
                    onChangeTheme={changeTheme}
                    isDefaultChecked={widget.theme === 'wall'}
                  />
                </div>

                <label htmlFor="width" className="label">
                  Largeur du widget (ex. 80%, 600px)
                </label>
                <input
                  type="text"
                  name="width"
                  id="width"
                  className="text-input"
                  defaultValue={widget.width}
                  placeholder="Largeur*"
                  onChange={(e) => {
                    const temp = {}
                    temp.width = e.currentTarget.value
                    setWidget({ ...widget, ...temp })
                  }}
                />

                <label htmlFor="height" className="label">
                  Hauteur du widget (ex. 50%, 300px)
                </label>
                <input
                  type="text"
                  name="height"
                  id="height"
                  className="text-input"
                  defaultValue={widget.height}
                  placeholder="Hauteur*"
                  onChange={(e) => {
                    const temp = {}
                    temp.height = e.currentTarget.value
                    setWidget({ ...widget, ...temp })
                  }}
                />

                <label htmlFor="font" className="label">
                  Font Google (nom)
                </label>
                <input
                  type="text"
                  name="font"
                  id="font"
                  className="text-input"
                  defaultValue={widget.font}
                  placeholder="Police*"
                  onChange={(e) => {
                    const temp = {}
                    temp.font = e.currentTarget.value
                    setWidget({ ...widget, ...temp })
                  }}
                />

                <label htmlFor="font_url" className="label">
                  Url font Google
                </label>
                <input
                  type="text"
                  name="font_url"
                  id="font_url"
                  className="text-input"
                  defaultValue={widget.font_url}
                  placeholder="Police*"
                  onChange={(e) => {
                    const temp = {}
                    temp.font_url = e.currentTarget.value
                    setWidget({ ...widget, ...temp })
                  }}
                />
                <label htmlFor="color1" className="label">
                  Couleur Primaire
                </label>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <input
                    type="text"
                    name="color1"
                    id="color1"
                    className="text-input"
                    defaultValue={widget.color1}
                    placeholder="Code hexa*"
                    onChange={(e) => {
                      const temp = {}
                      temp.color1 = e.currentTarget.value
                      setWidget({ ...widget, ...temp })
                    }}
                  />

                  <div
                    style={{
                      width: '2rem',
                      height: '2rem',
                      marginLeft: '10px',
                      borderRadius: '5px',
                      backgroundColor: widget.color1,
                    }}
                  ></div>
                </div>
                <label htmlFor="color2" className="label">
                  Couleur Secondaire
                </label>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <input
                    type="text"
                    name="color2"
                    id="color2"
                    className="text-input"
                    defaultValue={widget.color2}
                    placeholder="Code hexa*"
                    onChange={(e) => {
                      const temp = {}
                      temp.color2 = e.currentTarget.value
                      setWidget({ ...widget, ...temp })
                    }}
                  />

                  <div
                    style={{
                      width: '2rem',
                      height: '2rem',
                      marginLeft: '10px',
                      borderRadius: '5px',
                      backgroundColor: widget.color2,
                    }}
                  ></div>
                </div>

                <div className="text-24 mt-10">Source du widget</div>

                <label htmlFor="sorting" className="label">
                  Trier les medias par
                </label>
                <Select
                  name="sortBy"
                  value={widget.sort_by}
                  onChange={(value) => {
                    const temp = {}
                    temp.sort_by = value
                    setWidget({ ...widget, ...temp })
                  }}
                >
                  <Option value="random">Ordre aléatoire</Option>
                  <Option value="created_at">Date de création</Option>
                  {/* <Option value="timestamp">Date du post</Option> */}
                  <Option value="commentsCount">Nombre de commentaires</Option>
                  <Option value="likeCount">Nombre de likes</Option>
                </Select>

                <label htmlFor="locale" className="label">
                  Remontée
                </label>
                <Select
                  name="source"
                  defaultValue="by_collection" /* required onChange={e => {
                        const temp = {}
                        temp.locale = e.currentTarget.value
                        setFormDatas({...formDatas, ...temp })
                        }} */
                >
                  <Option value="by_collection">Par collection</Option>
                </Select>
                <div className="selection-wrapper mt-6">
                  <div className="selection-wrapper-content">{getCollection()}</div>
                  <div className="selection-cta mt-6" onClick={chooseCollection}>
                    <div className="picto">
                      <IconPlus className="icon" />
                    </div>
                    {widget.collections.length > 0 ? 'changer de collection' : 'Lier à une collection'}
                  </div>
                </div>
              </div>
              <div className="col-12 md-col-8">&nbsp;</div>
              <div className="col-12 md-col-8 flex justify-start items-start">
                <Button btnStyle="border" color="grey" addedclass="mr-4" onClickFn={deleteWidget}>
                  {'Supprimer le widget'}
                </Button>
                <Button
                  type="submit"
                  btnStyle="plain"
                  loading={isLoading}
                  disabled={isLoading || widget.collections.length <= 0}
                >
                  Modifier le widget
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="sidebar">
          <div className={`sidebar__content${isLoading ? ' is-loading' : ''}`}>
            {widget && <Sidebar data={widget} />}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WidgetSettings
