import { userActions } from '_actions'
import { LoginLeft, LoginRight } from '_svg'
import Button from 'components/buttons/Button'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import NoticeRoot from 'components/notices/NoticeRoot'
import MobileBlocker from 'components/layout/MobileBlocker'
import HeaderShowcase from 'components/layout/HeaderShowcase'

function Login() {
  // const user = useSelector(state => state.user)
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  })
  const { username, password } = inputs
  const loggingIn = useSelector((state) => state.authentication.loggingIn)
  const dispatch = useDispatch()
  const location = useLocation()

  function handleChange(e) {
    const { name, value } = e.target
    setInputs((inputs) => ({ ...inputs, [name]: value }))
  }

  const submitForm = (e) => {
    e.preventDefault()
    if (username && password) {
      // get return url from location state or default to home page
      const { from } = location.state || { from: { pathname: '/' } }
      dispatch(userActions.login(username, password, from))
    }
  }

  return (
    <div className="page-login">
      <HeaderShowcase />
      <div className="fluid-grid login-grid">
        <div className="row justify-center items-center">
          <div className="col-4">
            <LoginLeft className="shape-left" />
          </div>
          <form name="login-form col-4" onSubmit={submitForm}>
            <div className="title">
              Connectez-vous
              <div className="text-gradient text-gradient--H">à votre compte Colegram !</div>
            </div>
            <div className="form-group mt-6 mx-auto">
              <input
                required
                type="text"
                name="username"
                value={username}
                className="text-input"
                autoComplete="username"
                onChange={handleChange}
                placeholder="Votre identifiant"
              />
            </div>
            <div className="form-group mt-6 mx-auto">
              <input
                required
                type="password"
                name="password"
                value={password}
                className="text-input"
                onChange={handleChange}
                autoComplete="current-password"
                placeholder="Votre mot de passe"
              />
              <Link to="/password" className="info block mt-4">
                Mot de passe oublié ?
              </Link>
            </div>
            <div className="mt-2 gen-text">
            <label style={{ fontSize: 14, color: '#ffffff' }}>
                <input type="checkbox" name={"cgu"} required/>
                <span className="checkmark mr-4" />
                J'accepte les <a href='COLEGRAM-CONDITIONS-GENERALES-D-UTILISATION.pdf' target='_blank'>CGU</a>
              </label>
                </div>
              <div className="mt-2 gen-text">
                <label style={{ fontSize: 14, color: '#ffffff' }}>
                <input type="checkbox" name={"cgu"} required/>
                <span className="checkmark mr-4" />
                J'accepte la <a href='https://colegram.io/COLEGRAM-PrivacyPolicy-v02-07032023.pdf' target='_blank'>politique de confidentialité</a>
              </label>
              </div>
            <Button loading={loggingIn} type="submit" btnStyle="plain" disabled={loggingIn} addedclass="mt-8 mx-auto">
              se connecter
            </Button>

            {alert.message && <div className={`alert ${alert.type} mt-6`}>{alert.message}</div>}
          </form>
          <div className="col-4">
            <LoginRight className="shape-right" />
          </div>
        </div>
      </div>
      <NoticeRoot />
      <MobileBlocker />
    </div>
  )
}

export default Login
