export const modalsConstants = {
  OPEN_MODAL: 'MODAL_OPEN',
  AUTHOR_NAME: 'AUTHOR_NAME',
  CLOSE_MODAL: 'MODAL_CLOSE',
  SHOW_MEDIA_DETAILS: 'MODAL_SHOW_MEDIA_DETAILS',
  LINK_TO_PRODUCT: 'LINK_TO_PRODUCT',
  CREATE_COLLECTION: 'MODAL_CREATE_COLLECTION',
  DELETE_COLLECTION: 'MODAL_DELETE_COLLECTION',
  ADD_TO_COLLECTION: 'MODAL_ADD_TO_COLLECTION',
  DOWNLOAD_TO_COLLECTION: 'MODAL_DOWNLOAD_TO_COLLECTION',
  SHARE_COLLECTION: 'MODAL_SHARE_COLLECTION',
  DELETE_FROM_COLLECTION: 'MODAL_DELETE_FROM_COLLECTION',
  REJECT_FROM_FEED: 'MODAL_REJECT_FROM_FEED',
  ADD_FEED_MEDIA_MANUALLY: 'MODAL_ADD_FEED_MEDIA_MANUALLY',
  DELETE_FEED: 'MODAL_DELETE_FEED',
  DELETE_FEEDS: 'MODAL_DELETE_FEEDS',
  CREATE_MESSAGE: 'MODAL_CREATE_MESSAGE',
  DELETE_MESSAGE: 'MODAL_DELETE_MESSAGE',
  UPDATE_MESSAGE: 'MODAL_UPDATE_MESSAGE',
  DELETE_CREDS: 'MODAL_DELETE_CREDS',
  ASK_MESSAGE: 'MODAL_ASK_MESSAGE',
  CREATE_USER: 'MODAL_CREATE_USER',
  DELETE_USER: 'MODAL_DELETE_USER',
  UPDATE_USER: 'MODAL_UPDATE_USER',
  DELETE_FROM_LIBRARY: 'MODAL_DELETE_FROM_LIBRARY',
  CHANGE_PASSWORD: 'MODAL_CHANGE_PASSWORD',
  CHOOSE_COLLECTION: 'MODAL_CHOOSE_COLLECTION',
  UPDATE_MODAL: 'MODAL_UPDATE',
  DELETE_WIDGET: 'MODAL_DELETE_WIDGET',
}
