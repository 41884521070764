import React, { useState } from 'react'
import Layout from 'components/layout/Layout'
import PageHeader from 'components/layout/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import { messagesActions, modalActions } from '_actions'
import useSingleton from 'components/utils/useSingleton'
import Button from 'components/buttons/Button'
import MessagesListItem from 'components/_messages/MessagesListItem'

function Messages() {
  const dispatch = useDispatch()
  const messages = useSelector((state) => state.messages)
  const [selectedItems, setSelectedItems] = useState([])

  useSingleton(() => {
    // will always be triggered once
    if (messages.length <= 0) {
      dispatch(messagesActions.getAllMessages())
    }
  })

  const selectItem = (id, isChecked) => {
    if (isChecked) {
      setSelectedItems([...selectedItems, id])
    } else {
      var newSelectedItems = selectedItems.filter((value) => {
        return value !== id
      })
      setSelectedItems(newSelectedItems)
    }
  }

  const handleCreateMessage = () => {
    dispatch(modalActions.createMessage())
  }

  const handleDuplicateMessage = (item) => {
    dispatch(messagesActions.createMessage(item.translations, item.commentTranslations ?? {}))
  }

  const getMessagesList = () => {
    if (!messages) return
    if (messages.length > 0) {
      return messages.map((el, i) => {
        return (
          <li key={el.id}>
            <MessagesListItem
              data={el}
              duplicateFn={handleDuplicateMessage}
              canDuplicate={messages.length < 3}
              selectFunction={selectItem}
              isSelected={selectedItems.includes(el.id)}
            />
          </li>
        )
      })
    } else {
      return (
        <li className="mt-6 gen-text stagger-anim">
          Vous n'avez encore créé aucun message.{' '}
          <span onClick={handleCreateMessage} className="info">
            Créez-en un maintenant !
          </span>
        </li>
      )
    }
  }

  return (
    <Layout className="page page-messages">
      <PageHeader data={[{ label: 'Messages de demande de droits' }]} />
      <div className="page__content">
        <div className="page__intro">
          Ajoutez un template de message que vous enverrez aux créateurs de contenus dont vous souhaitez récupérer les
          droits d'utilisation. Modifiez ou supprimez un message existant en cliquant sur les boutons d'actions
          correspondant.
        </div>
        <div className="sticky-wrapper">
          <div className="flex justify-between items-end">
            <div className="page__title">Tous vos messages créés</div>
            {messages.length < 3 && (
              <Button btnStyle="plain" onClickFn={handleCreateMessage}>
                Créer un message
              </Button>
            )}
          </div>
        </div>
        <ul className="messages-list flex flex-wrap list-reset">{getMessagesList()}</ul>
      </div>
      <div className={`grouped-actions ${selectedItems.length > 1 ? 'is-active' : ''}`}>
        <div className="grouped-actions__counter">
          Vous avez sélectionné{' '}
          <span className="text-gradient">
            {selectedItems.length} Message{selectedItems.length > 1 && 's'}
          </span>
        </div>
        <div className="grouped-actions__ctas"></div>
      </div>
    </Layout>
  )
}

export default Messages
