import { useDispatch } from 'react-redux'
import Button from 'components/buttons/Button'
import CheckBox from 'components/filters/CheckBox'
import { modalActions } from '_actions/modals.actions'
import { IconDelete, IconEdit, IconJoin, IconDownload, IconDate } from '_svg'
import moment from 'moment'

export default function MediasListItem({ data, selectFunction, isSelected = false, downloadFunction, deleteFunction }) {
  const dispatch = useDispatch()

  const handleSelection = (e) => {
    selectFunction(data, e.checked)
  }

  const handleDetails = (e) => {
    dispatch(modalActions.showMediaDetails([data]))
  }

  const handleDelete = (e) => {
    deleteFunction([data])
    // dispatch(modalActions.deleteFromLibrary([data]))
  }

  const handleAddToCol = (e) => {
    dispatch(modalActions.addToCollection([data]))
  }

  const handleLinkProduct = (e) => {
    console.log(data)
    dispatch(modalActions.handleLinkProduct([data]))
  }

  const handleDownload = (e) => {
    downloadFunction([data])
  }

  const getMedia = () => {
    if (data.type === 'VIDEO') {
      return (
        <div className="thumb">
          <video controls muted loop>
            <source src={data.path} type="video/mp4" />
          </video>
        </div>
      )
    } else if (data.type === 'IMAGE') {
      return <div className="thumb" style={{ backgroundImage: `url('${data.path}')` }} />
    }
  }

  return (
    <div className="library-medias-list__item">
      <CheckBox name="actions" callback={handleSelection} isChecked={isSelected} />
      {getMedia()}
      <div className="content">
        <span className="media-username" style={{ lineHeight: 1.2 }}> Identifiant : {data.id}</span>
        <div className="content--top">
          {' '}
          {data.caption}
        </div>
        <div className="content--bottom">
          <div className="media-inner-ctas">
            <button
              onClick={(e) => {
                handleDetails(e)
              }}
            >
              voir le détail
            </button>
            <button
              onClick={(e) => {
                handleDelete(e)
              }}
            >
              <IconDelete className="icon" />
              supprimer
            </button>
            {/* <button onClick={(e) => { handleDownload(e) }}><IconDownload className="icon" />Télécharger</button> */}
          </div>
          <div className="media-inner-ctas" style={{ marginTop: "10px" }}>
            <button style={{ textTransform: "initial", color: "#7b7b7b" }}>
              <IconDate width={12} height={12} className="icon" style={{ marginTop: -2 }} />
              Droits accordés le {moment(data.created_at).format('DD.MM.YYYY')} à {moment(data.created_at).format('H:m')}
            </button>
          </div>
        </div>
      </div>
      <div className="ctas">
        <Button btnStyle="plain" addedclass="button-icon" onClickFn={handleAddToCol}>
          <IconJoin className="icon" />
          Associer à une collection
        </Button>
        <Button btnStyle="plain" type={null} onClickFn={handleLinkProduct} addedclass="button-icon">
          <IconEdit className="icon" />
          Lier un produit
        </Button>
        <Button
          btnStyle="border"
          color="grey-lighter"
          type={null}
          addedclass="button button--border button--border--white button-icon button--white"
          onClickFn={handleDownload}
        >
          <IconDownload className="icon" />
          Télécharger le media
        </Button>
      </div>
    </div >
  )
}
