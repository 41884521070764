import React, { useEffect, useState } from 'react'
import Layout from 'components/layout/Layout'
import PageHeader from 'components/layout/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import { feedActions } from '_actions/feed.actions'
import Button from 'components/buttons/Button'
import SortBar from 'components/filters/SortBar'
import FeedsListItem from 'components/_feeds/FeedsListItem'
import Pagination from 'components/navigation/Pagination'
import { Skeleton } from 'antd'
import { Link } from 'react-router-dom'
import { accountActions, modalActions, userActions } from '_actions'
import { FEED_QUOTA, PLAN } from '_constants'
import { accountEmpty, userEmpty } from '_services/Utils'
import { IconDelete } from '_svg'
import CheckBox from 'components/filters/CheckBox'

function Feeds() {
  const [requestOptions, setRequestOptions] = useState({
    q: '',
    page: 1,
    limit: 20,
    sort_by: 'last_update',
    sort_order: 'DESC',
  })
  const [pagination, setPagination] = useState({
    current_page: 1,
    limit: 20,
    total_count: 0,
  })
  const feedsRdux = useSelector((state) => state.feeds)
  const [feeds, setFeeds] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const account = useSelector((state) => state.account)

  const user = useSelector((state) => state.authentication.user)
  const [canCreate, setCanCreate] = useState(true)

  const [selectedFeeds, setSelectedFeeds] = useState([])

  useEffect(() => {
    if (accountEmpty(account)) dispatch(accountActions.getSelf())
    if (userEmpty(user)) dispatch(userActions.getSelf('/feed'))

    switch (account.plan) {
      case PLAN.LIGHT:
        setCanCreate(pagination.total_count < FEED_QUOTA.PLAN_LIGHT)
        break

      case PLAN.PRO:
        setCanCreate(pagination.total_count < FEED_QUOTA.PLAN_PRO)
        break

      case PLAN.ULTRA:
        setCanCreate(user?.isAdmin ? true : pagination.total_count < FEED_QUOTA.PLAN_ULTRA_CHILDREN)
        break

      default:
        setCanCreate(false)
        break
    }
  }, [user, account, feeds, dispatch, pagination])

  useEffect(() => {
    // default sorting by last update:
    const feeds = [...feedsRdux.items.sort(dynamicSort('-last_update'))]
    setFeeds(feeds)
    if (feedsRdux.pagination !== pagination) setPagination({ ...pagination, ...feedsRdux.pagination })

    // setinitialFeeds(feeds)
    setIsLoading(false)
  }, [feedsRdux])

  useEffect(() => {
    // condition te prevent loading on first loading
    if (!feeds) return
    loadFeeds()
  }, [requestOptions])

  const loadFeeds = () => {
    if (isLoading) return
    setIsLoading(true)
    dispatch(feedActions.getAllFeeds(requestOptions))
  }

  const deleteFeed = () => {
    dispatch(modalActions.deleteFeeds(selectedFeeds, loadFeeds))
    setSelectedFeeds([])
  }

  const selectFeed = (feed, isChecked) => {
    if (isChecked) {
      setSelectedFeeds([...selectedFeeds, feed])
    } else {
      var newSelectedFeeds = selectedFeeds.filter((a) => {
        return a.id !== feed.id
      })
      setSelectedFeeds(newSelectedFeeds)
    }
  }

  const selectAllFeeds = (el) => {
    const selectedFeeds = []
    if (el.checked) {
      feeds.forEach((el) => {
        selectedFeeds.push(el)
      })
    }
    setSelectedFeeds(selectedFeeds)
  }

  const getFeedsList = () => {
    if (isLoading) {
      return Array.apply(null, Array(requestOptions.limit)).map((el, i) => {
        return (
          <li className="mt-10 stagger-anim" key={i}>
            <Skeleton active paragraph={{ rows: 1 }} />
          </li>
        )
      })
    } else {
      if (feeds.length > 0) {
        return feeds.map((el, i) => {
          return (
            <li key={i} className="stagger-anim">
              <FeedsListItem
                selectFunction={selectFeed}
                isSelected={selectedFeeds.includes(el)}
                data={el}
              />
            </li>
          )
        })
      } else {
        if (requestOptions.q !== '') {
          // search result
          return <li className="mt-6 gen-text stagger-anim">Votre recherche n'a donné aucun résultat.</li>
        } else {
          return (
            <li className="mt-6 gen-text stagger-anim">
              Vous n'avez encore créé aucun feed.{' '}
              <Link to="/feed/create" className="info">
                Créez-en un maintenant !
              </Link>
            </li>
          )
        }
      }
    }
  }

  const sortBy = (attr) => {
    setFeeds([...feeds.sort(dynamicSort(attr))])
  }

  const dynamicSort = (property) => {
    // https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
    var sortOrder = 1
    if (property[0] === '-') {
      sortOrder = -1
      property = property.substr(1)
    }
    // if(property === 'last_update' || property === 'date_creation') sortOrder = -1;
    if (property === 'status') sortOrder = -1
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
      return result * sortOrder
    }
  }

  const handlePagination = (index) => {
    setRequestOptions({ ...requestOptions, page: index })
  }

  return (
    <Layout className="page page-feeds">
      <PageHeader data={[{ label: 'Feed' }]} />
      <div className="page__content">
        <div className="page__intro">
          Ajoutez un feed et commencez à collectez les contenus de vos communautés. Gérez un feed existant en cliquant
          sur son nom.
          {!canCreate && (
            <>
              <br />
              <br />
              <div className="gen-text gen-text--red">Vous avez atteint votre quota de feeds disponibles !</div>
            </>
          )}
        </div>
        <div className="sticky-wrapper">
          <div className="flex justify-between items-end">
            <CheckBox
              name="action"
              label="Tout selectionner"
              isSelected={selectedFeeds.length > 0}
              isChecked={selectedFeeds.length > 0 && selectedFeeds.length === feeds.length}
              groupAction={true}
              callback={selectAllFeeds}
            />
            {
              <span className="assets-total-count">
                {pagination && pagination.total_count > 0
                  ? `${pagination.total_count} Feed${pagination.total_count > 1 ? 's' : ''}`
                  : `0 Feed`}
              </span>
            }

            <Button
              target="_self"
              btnStyle="plain"
              url="/feed/create"
              disabled={!canCreate}
              color={canCreate ? 'red' : 'grey'}
            >
              Créer un feed
            </Button>
          </div>
          <SortBar
            data={[
              { label: '', sortingAttribute: '' },
              { label: 'nom du feed', sortingAttribute: 'name' },
              { label: 'date de début', sortingAttribute: '-start_at' },
              { label: 'médias', sortingAttribute: '-assets_count' },
              { label: 'type', sortingAttribute: 'source' },
              { label: 'statut', sortingAttribute: 'status' },
              { label: 'Crée par', sortingAttribute: 'created_by' },
              { label: 'Crée le', sortingAttribute: '-date_creation' },
              { label: 'dernier update', sortingAttribute: '-last_update' },
            ]}
            sortFunction={sortBy}
          />
        </div>
        <ul className="feeds-list">{getFeedsList()}</ul>

        <Pagination data={pagination} requestFn={handlePagination} />
      </div>
      <div className={`grouped-actions ${selectedFeeds.length > 0 ? 'is-active' : ''}`}>
        <div className="grouped-actions__counter">
          Vous avez sélectionné{' '}
          <span className="text-gradient">
            {selectedFeeds.length} Feed{selectedFeeds.length > 1 && 's'}
          </span>
        </div>
        <div className="grouped-actions__ctas">
          <Button
            btnStyle="border"
            color="grey-lighter"
            type={null}
            addedclass="button-icon"
            onClickFn={deleteFeed}
          >
            <IconDelete className="icon" />
            Supprimer les contenus
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export default Feeds
