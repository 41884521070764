export const landingsConstants = {
  GET_ALL: 'LANDINGS_GET_ALL',
  CREATE: 'LANDING_CREATE',
  CHOOSE_COLLECTION: 'LANDING_CHOOSE_COLLECTION',
  CREATE_FAILURE: 'LANDING_CREATE_FAILURE',
  UPDATE: 'LANDING_UPDATE',
  DELETE: 'LANDING_DELETE',
  ERROR_QUOTA_EXCEEDED:
    'Vous avez atteint le nombre de landings autorisé. Veuillez en supprimer un pour en créer un nouveau',
}
