import React from 'react'
import { useDispatch } from 'react-redux'
import { messagesActions, modalActions } from '_actions'
import Button from 'components/buttons/Button'

export default function DeleteMessage({ ...modalProps }) {
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(modalActions.closeModal())
  }

  const deleteMsg = () => {
    dispatch(messagesActions.deleteMessage(modalProps.id))
    dispatch(modalActions.closeModal())
  }

  return (
    <div className="modal-delete-message">
      <div className="modal__title text-center">Suppression de message</div>
      <div className="modal__grip text-center">Voulez-vous vraiment supprimer ce message ?</div>
      <div className="flex justify-between">
        <Button btnStyle="plain" onClickFn={deleteMsg}>
          Supprimer
        </Button>
        <Button btnStyle="border" color="grey-lighter" onClickFn={closeModal}>
          Annuler
        </Button>
      </div>
    </div>
  )
}
