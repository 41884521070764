import classNames from 'classnames'
import { isValidElement } from 'react'

export default function CheckBox(props) {
  const { name, label = null, callback = null, isChecked = null, customClass = null, groupAction = false, textColor = "#fff", noPurple = false } = props
  const handleClick = (e) => {
    e.currentTarget.value = !e.currentTarget.value
    if (callback) callback(e.currentTarget)
  }

  return (
    <label
      className={classNames('action_cb', `custom-checkbox${noPurple ? "-no-purple" : ""}`, customClass, {
        'custom-checkbox--labeled': label,
        'custom-checkbox--group-actions': groupAction,
      })}
    >
      <input type="checkbox" name={name} onChange={(e) => handleClick(e)} checked={isChecked && 'checked'} />
      <span className="checkmark mr-4" />
      {isValidElement(label) && label}
      {!isValidElement(label) && label && <div className="text-label" style={{ color: textColor }}>{label}</div>}
    </label>
  )
}
