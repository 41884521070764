import React from 'react'
import 'moment/locale/fr'

import moment from 'moment'

import { Link, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function PageHeader({ data, tabs = null }) {
  const auth = useSelector((state) => state.authentication)

  const getBreadcrumb = () => {
    return data.map((el, i) => {
      if (el.url) {
        return (
          <li key={i}>
            <Link to={el.url}>
              {el.label}
              {el.feed ? getFeedStatus(el.feed) : ''}
            </Link>
          </li>
        )
      } else {
        return (
          <React.Fragment key={i}>
            <li>
              <span>
                {el.label}
              </span>
            </li>
            <li className='no-chevron'>
              {el.feed ? getFeedStatus(el.feed) : ''}
            </li>
          </React.Fragment>
        )
      }
    })
  }

  const getDescription = () => {
    return data.map((el, i) => {
      if (el.label === 'Bibliothèque') {
        return (
          <div key={i}>
            <p className="description-cat">
              Veuillez toujours <strong className="strong-description">mentionner les auteurs</strong> des médias dont
              vous avez récupéré les droits d'utilisation avant leur diffusion. <br />
              Par ailleurs, le droit moral vous oblige à ne{' '}
              <strong className="strong-description">pas modifier le contenu</strong> récupéré avant sa diffusion sans
              un accord préalable avec le photographe.
            </p>
          </div>
        )
      } else {
        return ''
      }
    })
  }

  const getTabs = () => {
    return tabs.map((el, i) => {
      return (
        <li key={i}>
          <NavLink
            activeClassName="is-active"
            to={el.url}
            isActive={(match, location) => {
              return location.pathname.endsWith(el.url)
            }}
          >
            {el.label}
          </NavLink>
        </li>
      )
    })
  }

  const getFeedStatus = (feed) => {
    let text = ''
    switch (feed.status) {
      default:
      case '0':
        text = `Planifié <span>du <span class="date">${moment(feed.start_at)
          .locale('fr')
          .format('L')}</span> au <span class="date">${moment(feed.end_at).locale('fr').format('L')}</span></span>`
        break
      case '1':
        // text = `Actif <span>jusqu'au <span class="date">${moment(feed.end_at).locale('fr').format('L')}</span></span>`
        text = `Actif`
        break
      case '-1':
        // text = `Inactif ${
        //   '<span>depuis le <span class="date">' + moment(feed.end_at).locale('fr').format('L') + '</span></span>'
        // }`
        text = `Inactif`
        break
    }
    return (
      <div className="status">
        <div className={`status-indic status-indic--${feed.status}`}></div>
        <span dangerouslySetInnerHTML={{ __html: text }}></span>
      </div>
    )
  }

  return (
    <>
      <div className="pageHeader">
        <div className="flex justify-between items-center">
          <ul className="list-reset flex pageHeader__breadcrumb">{getBreadcrumb()}</ul>
          {tabs && <ul className="list-reset flex pageHeader__tabs">{getTabs()}</ul>}

          <ul className="list-reset flex justify-center items-center text-medium text-10 uppercase">
            {/* <li><NavLink to="/upgrade-plan" className="text-gradient">Upgrade plan</NavLink></li> */}
            <li className="ml-10">
              <NavLink to="/account/profile" className="user-account-cta flex justify-center items-center">
                <div className='img'
                  style={{
                    backgroundImage: `url('${auth.user && auth.user.img ? auth.user.img : '/assets/images/default-profile.jpg'}')`,
                  }} ></div>
                {/* <img src={auth.user && auth.user.img ? auth.user.img : '/assets/images/default-profile.jpg'} alt="" /> */}
                <span>mon compte</span>
              </NavLink>
            </li>
          </ul>
        </div>
        {getDescription()}
      </div>
    </>
  )
}
