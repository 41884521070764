export const feedConstants = {
  GET_ALL: 'FEED_GET_ALL',
  CREATE: 'FEED_CREATE',
  CREATE_FAILURE: 'FEED_CREATE_FAILURE',
  UPDATE: 'FEED_UPDATE',
  DELETE: 'FEED_DELETE',
  GET_ASSETS: 'ASSETS_GET',
  GET_ALL_ASSETS: 'ASSETS_GET_ALL',
  GET_ASSET: 'ASSET_GET',
  DELETE_ASSET: 'ASSET_DELETE',
  UPDATE_ASSET: 'ASSET_UPDATE',
  ERROR_QUOTA_EXCEEDED:
    'Vous avez atteint le nombre de feeds autorisé. Veuillez en supprimer un pour en créer un nouveau',
  ERROR_LOCALE_REQUIRED: 'Veuillez saisir une localité pour finaliser la création du feed',
  ERROR_HASHTAGS_QUOTA_EXCEEDED:
    'Vous avez atteint le nombre de 30 hashtags autorisés en 7 jours. Veuillez réessayer plus tard',
  ERROR_HASHTAGS_INVALID: 'Ce(s) hashtag(s) ne donne(nt) aucun résultat',
  UPDATE_DISPLAYMODE: 'FEED_UPDATE_DISPLAYMODE',
}
