import 'moment/locale/fr'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default function WidgetsListItem({ data }) {
  const getStatus = () => {
    // const date = moment(data.date_creation).locale('fr').format("L")
    let text = ''
    if (!data.status) data.status = 1

    switch (parseInt(data.status)) {
      default:
      case 1:
        text = `Actif`
        break
      case 0:
        text = 'Brouillon'
        break
    }
    return (
      <div className="status">
        <div className={`status-indic status-indic--${data.status}`}></div>
        <span dangerouslySetInnerHTML={{ __html: text }}></span>
      </div>
    )
  }

  return (
    <Link
      to={`/widgets/${data.id}/settings`}
      className="widget-list__item"
      data-name={data.name}
      data-status={data.status}
      data-last-update={data.last_update}
    >
      <span className="feeds-list__item__name">{data.name}</span>
      <span className="feeds-list__item__status">{getStatus()}</span>
      <span className="feeds-list__item__last-update">{moment(data.last_update).locale('fr').format('L')}</span>
    </Link>
  )
}
