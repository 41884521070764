import axiosApi from './api'

export const templatesService = {
  getAll,
  create,
  deleteTemplate,
  updateTemplate,
}

function getParams(options) {
  if (!options) return ''
  let params = ''
  for (const [key, value] of Object.entries(options)) {
    params += `${key}=${value}&`
  }
  return params.slice(0, -1)
}

async function getAll(options) {
  const params = getParams(options)
  const res = await axiosApi.get(`${process.env.REACT_APP_API_URL}/api/template?${params}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function create(datas) {
  const res = await axiosApi.post(`${process.env.REACT_APP_API_URL}/api/template`, JSON.stringify(datas))
  if (res.status === 200) {
    const defaultProps = {
      id: res.data.id,
      template: res.data.template,
      /* assets_count: 0,
            memory: 0 */
    }
    return { ...datas, ...defaultProps }
  } else {
    console.log(res.status, res.statusText)
  }
}

async function deleteTemplate(templatesId) {
  const res = await axiosApi.delete(`${process.env.REACT_APP_API_URL}/api/template/${templatesId}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function updateTemplate(template) {
  const res = await axiosApi.put(`${process.env.REACT_APP_API_URL}/api/template`, JSON.stringify(template))
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}
