import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import PageHeader from 'components/layout/PageHeader'

export default function HeaderWithTabs() {
  const user = useSelector((state) => state.authentication.user)

  const [tabs, setTabs] = useState([{ label: 'Mon profil', url: `/account/profile` }])

  useEffect(() => {
    let tempTabs = [
      { label: 'Mon profil', url: `/account/profile` },
      { label: 'Plugins', url: `/account/plugins` },
    ]

    if (user?.permissions?.includes('user_create')) {
      tempTabs = [...tempTabs, { label: 'Gestion des utilisateurs', url: `/account/users` }]
    }
    if (user?.permissions?.includes('account_config_update')) {
      tempTabs = [...tempTabs, { label: 'Configuration globale', url: `/account/config` }]
    }
    setTabs(tempTabs)
  }, [user])

  return <PageHeader data={[{ label: 'Mon profil', url: '/account/profile' }]} tabs={tabs} />
}
