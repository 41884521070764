import 'moment/locale/fr'
import moment from 'moment'
import { modalActions } from '_actions'
import { useDispatch } from 'react-redux'
import { IconDate, IconDelete, IconDeleteColor, IconJoin, IconJoinColor, IconEdit, IconEditColor } from '_svg'

export default function MessagesListItem({ data, duplicateFn, canDuplicate, selectFunction, isSelected = false }) {
  const dispatch = useDispatch()

  const handleDelete = (e) => {
    dispatch(modalActions.deleteMessage(data.id))
  }

  const handleDuplicate = (e) => {
    if (canDuplicate) duplicateFn(data)
  }

  const handleEdit = (e) => {
    dispatch(modalActions.updateMessage(data))
  }

  return (
    <div className="messages-list__item">
      <div className="messages-list__item__date flex">
        <IconDate className="icon" />
        Créé le&nbsp;<span>{moment(data.created_at).locale('fr').format('L')}</span>
      </div>
      <div className="messages-list__item__content">
        {Object.keys(data.translations).filter(lang => data.translations[lang] !== "").map(lang => <p>{`${lang.toUpperCase()} : ${data.translations[lang]}`}</p>)}
      </div>
      <div className="messages-list__item__ctas">
        <button className="bordered-icon" onClick={handleDelete}>
          <IconDelete className="icon" />
          <IconDeleteColor className="icon icon-color" />
        </button>

        <button className={`bordered-icon ${canDuplicate ? '' : 'is-disabled'}`} onClick={handleDuplicate}>
          <IconJoin className="icon" />
          <IconJoinColor className="icon icon-color" />
        </button>

        <button className="bordered-icon" onClick={handleEdit}>
          <IconEdit className="icon" />
          <IconEditColor className="icon icon-color" />
        </button>
      </div>
    </div>
  )
}
