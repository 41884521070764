import { askConstants } from '_constants'
import { askService } from '_services'
import { noticesActions, modalActions, feedActions } from './'

export const askActions = { create }

function create(datas, callback) {
  // name, user_id, locale, hashtags, description
  return (dispatch) => {
    askService.create(datas).then(
      (ask) => {
        dispatch(success(ask))
        callback(true)
        dispatch(noticesActions.askCopied())
        dispatch(feedActions.updateAsset(datas))
      },
      (error) => {
        dispatch(failure(error.toString()))
        callback(false, error)
      }
    )
  }

  // function request() { return { type: askConstants.GET_ALL } }
  function success(ask) {
    return { type: askConstants.CREATE, ask }
  }
  function failure(error) {
    return { type: askConstants.CREATE_FAILURE, error }
  }
}
