import { combineReducers } from 'redux'

import { account } from './account.reducer'
import { authentication } from './authentication.reducer'
import { alert } from './alert.reducer'
import { ask } from './ask.reducer'
import { collections } from './collections.reducer'
import { feeds } from './feed.reducer'
import { library } from './library.reducer'
import { messages } from './messages.reducer'
import { modal } from './modals.reducer'
import { notices } from './notices.reducer'
import { widgets } from './widgets.reducer'
import { landings } from './landings.reducer'
import { templates } from './templates.reducer'
import { userConstants } from '_constants'

const appReducer = combineReducers({
  account,
  authentication,
  alert,
  ask,
  collections,
  feeds,
  library,
  messages,
  modal,
  notices,
  widgets,
  landings,
  templates,
})

const rootReducer = (state, action) => {
  if (action.type === userConstants.LOGOUT) {
    return appReducer({ authentication: { loggedIn: false, loggingIn: false } }, action)
  }

  return appReducer(state, action)
}

export default rootReducer
