import React, { useState } from 'react'
import { userActions } from '_actions'
import { Link } from 'react-router-dom'
import { LoginLeft, LoginRight } from '_svg'
import Button from 'components/buttons/Button'
import NoticeRoot from 'components/notices/NoticeRoot'
import { useSelector, useDispatch } from 'react-redux'
import HeaderShowcase from 'components/layout/HeaderShowcase'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

function ChangePassword() {
  const urlParams = useParams()
  const alert = useSelector((state) => state.alert)

  const [inputs, setInputs] = useState({
    password: '',
    confirmPassword: '',
  })
  const { password, confirmPassword } = inputs
  const loggingIn = useSelector((state) => state.authentication.loggingIn)
  const dispatch = useDispatch()

  function handleChange(e) {
    const { name, value } = e.target
    setInputs((inputs) => ({ ...inputs, [name]: value }))
  }

  const submitForm = (e) => {
    e.preventDefault()
    if (password && confirmPassword && password === confirmPassword) {
      // get return url from location state or default to home page
      // const { from } = location.state || { from: { pathname: "/" } };
      dispatch(
        userActions.forgotPassword({
          hash: urlParams.hash,
          password: password,
          confirmPassword: confirmPassword,
        })
      )
    }
  }

  return (
    <div className="page-login">
      <HeaderShowcase />
      <div className="fluid-grid login-grid">
        <div className="row justify-center items-center">
          <div className="col-4">
            <LoginLeft className="shape-left" />
          </div>
          <form name="login-form col-4" onSubmit={submitForm}>
            <div className="">
              <Link to="/" className="sup-title sup-title--M leading-0 sup-title--white flex items-center">
                <span className="gen-text gen-text--XL gen-text--pink leading-0 mr-4">‹</span> Retour à la connexion
              </Link>
            </div>
            <div className="title mt-10">Renseignez votre nouveau mot de passe</div>
            <div className="form-group mt-6 mx-auto">
              <input
                type="password"
                name="password"
                className="text-input"
                placeholder="Nouveau mot de passe"
                value={password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group mt-6 mx-auto">
              <input
                type="password"
                name="confirmPassword"
                className="text-input"
                placeholder="Confirmez votre mot de passe"
                value={confirmPassword}
                onChange={handleChange}
                required
              />
            </div>

            <Button loading={loggingIn} type="submit" btnStyle="plain" disabled={loggingIn} addedclass="mt-8 mx-auto">
              Modifiez votre mot de passe
            </Button>

            {alert.message && <div className={`alert ${alert.type} mt-6`}>{alert.message}</div>}
          </form>
          <div className="col-4">
            <LoginRight className="shape-right" />
          </div>
        </div>
      </div>
      <NoticeRoot />
    </div>
  )
}

export default ChangePassword
