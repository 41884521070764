import axiosApi from './api'

export const collectionsService = {
  getAssets,
  getCollections,
  shareCollection,
  createCollection,
  deleteCollection,
  updateCollection,
}

function getParams(options) {
  if (!options) return ''
  let params = ''
  for (const [key, value] of Object.entries(options)) {
    params += `${key}=${value}&`
  }
  return params.slice(0, -1)
}

async function getCollections() {
  const res = await axiosApi.get(`${process.env.REACT_APP_API_URL}/api/collection`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function getAssets(collectionId, options) {
  const params = getParams(options)
  const res = await axiosApi.get(`${process.env.REACT_APP_API_URL}/api/collection/${collectionId}/assets?${params}`)
  if (res.status === 200) {
    return { assets: Object.values(res.data.data), pagination: res.data.pagination, collectionId: collectionId }
  } else {
    console.log(res.status, res.statusText)
  }
}

async function createCollection(collectionItem) {
  const res = await axiosApi.post(`${process.env.REACT_APP_API_URL}/api/collection`, JSON.stringify(collectionItem))
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function deleteCollection(collectionId) {
  const res = await axiosApi.delete(`${process.env.REACT_APP_API_URL}/api/collection/${collectionId}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function updateCollection(collectionItem) {
  const res = await axiosApi.put(`${process.env.REACT_APP_API_URL}/api/collection`, JSON.stringify(collectionItem))
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }

  // return  collectionItem
}

async function shareCollection(data) {
  const body = {
    userId: data.user.id,
    collectionId: data.collection.id,
  }

  const res = await axiosApi.post(`${process.env.REACT_APP_API_URL}/api/collection/share`, JSON.stringify(body))
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }

  // return  collectionItem
}
