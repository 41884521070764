import { noticesConstants } from '_constants'

export const noticesActions = {
  closeNotice,
  download,
  downloadCSV,
  collectionCreated,
  collectionDeleted,
  addedToCollection,
  deletedFromCollection,
  deletedFromLibrary,
  feedCreated,
  feedDeleted,
  feedUpdated,
  assetRejected,
  messageCreated,
  messageDeleted,
  messageUpdated,
  askCopied,
  profileUpdated,
  passwordUpdated,
  passwordReset,
  widgetCreated,
  widgetUpdated,
  widgetDeleted,
  landingCreated,
  landingUpdated,
  landingDeleted,
  templateCreated,
  templateUpdated,
  templateDeleted,
  fetchingPost,
  postSaved,
  collectionShared,
}

function closeNotice(noticeID) {
  return (dispatch) => {
    dispatch({
      type: noticesConstants.CLOSE_NOTICE,
      id: noticeID,
    })
  }
}

function download(asset) {
  return (dispatch) => {
    dispatch(action({ asset }))
  }

  function action(asset) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.DOWNLOAD,
      noticeProps: { id: asset.asset[0].id, ...asset },
    }
  }
}

function downloadCSV(asset) {
  return (dispatch) => {
    dispatch(action({ asset }))
  }

  function action(asset) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.DOWNLOAD_CSV,
      noticeProps: { id: asset.asset[0].id, ...asset },
    }
  }
}

function collectionCreated(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.COLLECTION_CREATED,
      noticeProps: { name: result.name },
    }
  }
}

function collectionDeleted(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.COLLECTION_DELETED,
      noticeProps: { name: result.name },
    }
  }
}

function addedToCollection(collection, mediaItem) {
  return (dispatch) => {
    dispatch(action(collection, mediaItem))
  }

  function action(collection, mediaItem) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.ADDED_TO_COLLECTION,
      noticeProps: { id: mediaItem.id, collection, mediaItem },
    }
  }
}

function deletedFromCollection(collection, mediaItem) {
  return (dispatch) => {
    dispatch(action(collection, mediaItem))
  }

  function action(collection, mediaItem) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.DELETED_FROM_COLLECTION,
      noticeProps: { id: mediaItem.id, collection, mediaItem },
    }
  }
}

function deletedFromLibrary(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(mediaItem) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.DELETED_FROM_LIBRARY,
      noticeProps: { id: mediaItem.id, mediaItem },
    }
  }
}

function feedCreated(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.FEED_CREATED,
      noticeProps: { name: result.name },
    }
  }
}

function feedDeleted(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(feed) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.FEED_DELETED,
      noticeProps: feed,
    }
  }
}

function feedUpdated(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(feed) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.FEED_UPDATED,
      noticeProps: feed,
    }
  }
}

function assetRejected(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.REJECTED_FROM_FEED,
      noticeProps: { id: result.id, url: result.mediaUrl },
    }
  }
}

function messageDeleted(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.DELETED_MESSAGE,
      noticeProps: {},
    }
  }
}

function messageCreated(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.CREATED_MESSAGE,
      noticeProps: {},
    }
  }
}

function messageUpdated(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.UPDATED_MESSAGE,
      noticeProps: {},
    }
  }
}

function askCopied() {
  return (dispatch) => {
    dispatch(action())
  }

  function action() {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.ASK_COPIED,
      noticeProps: {},
    }
  }
}

function profileUpdated(data) {
  return (dispatch) => {
    dispatch(action({ data }))
  }

  function action(data) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.PROFILE_UPDATED,
      noticeProps: data,
    }
  }
}

function passwordUpdated() {
  return (dispatch) => {
    dispatch(action())
  }

  function action() {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.PASSWORD_UPDATED,
      noticeProps: {},
    }
  }
}

function passwordReset() {
  return (dispatch) => {
    dispatch(action())
  }

  function action() {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.PASSWORD_RESET,
      noticeProps: {},
    }
  }
}

function widgetCreated(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.WIDGET_CREATED,
      noticeProps: { name: result.name },
    }
  }
}

function widgetUpdated(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.WIDGET_UPDATED,
      noticeProps: { name: result.name },
    }
  }
}

function widgetDeleted(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.WIDGET_DELETED,
      noticeProps: { name: result.name },
    }
  }
}

function landingCreated(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.LANDING_CREATED,
      noticeProps: { title: result.title },
    }
  }
}

function landingUpdated(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.LANDING_UPDATED,
      noticeProps: { title: result.title },
    }
  }
}

function landingDeleted(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.LANDING_DELETED,
      noticeProps: { title: result.title },
    }
  }
}

function templateCreated(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.TEMPLATE_CREATED,
      noticeProps: { name: result.name },
    }
  }
}

function templateUpdated(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.TEMPLATE_UPDATED,
      noticeProps: { name: result.name },
    }
  }
}

function templateDeleted(item) {
  return (dispatch) => {
    dispatch(action(item))
  }

  function action(result) {
    return {
      type: noticesConstants.OPEN_NOTICE,
      noticeType: noticesConstants.TEMPLATE_DELETED,
      noticeProps: { name: result.name },
    }
  }
}

function fetchingPost() {
  return {
    type: noticesConstants.OPEN_NOTICE,
    noticeType: noticesConstants.FETCHING_POST,
    noticeProps: { id: 'fetching-post' },
  }
}
function postSaved() {
  return {
    type: noticesConstants.OPEN_NOTICE,
    noticeType: noticesConstants.POST_SAVED,
    noticeProps: { id: 'post-saved' },
  }
}

function collectionShared(result) {
  return {
    type: noticesConstants.OPEN_NOTICE,
    noticeType: noticesConstants.COLLECTION_SHARED,
    noticeProps: result,
  }
}
