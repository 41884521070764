import { IconCrossSmall } from '_svg'

export default function HashtagListItem({ name, deleteFunction }) {
  return (
    <div className="hashtag-list__item">
      <div className="hashtag-list__item-label">{name}</div>
      <div
        className="bt-remove"
        onClick={(e) => {
          deleteFunction(e.currentTarget.parentNode.querySelector('.hashtag-list__item-label'))
        }}
      >
        <IconCrossSmall className="icon" />
      </div>
    </div>
  )
}
