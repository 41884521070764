import { collectionsConstants } from '_constants'
const initialState = { items: [], pagination: {} }

export function collections(state = initialState, action) {
  switch (action.type) {
    case collectionsConstants.GET_ASSETS:
      return {
        ...state,
        items: state.items.map((collection) => {
          if (collection.id === action.data.collectionId) {
            // return updated item
            return { ...collection, assets: action.data.assets, pagination: action.data.pagination }
          }
          // Leave every other item unchanged
          return collection
        }),
      }

    case collectionsConstants.DELETE_ASSETS:
      const updatedAssets = state.assets.filter((el) => {
        return el.id !== action.mediaID
      })
      return { ...state, assets: updatedAssets }

    case collectionsConstants.GET_COLLECTIONS:
      return { ...state, items: action.data, pagination: action.pagination }

    case collectionsConstants.CREATE_COLLECTION:
      const colItem = {
        assets: [],
        assets_ids: [],
        assets_count: 0,
        previews: [],
        tags: [],
        ...action.item,
      }
      state.items.splice(0, 0, colItem)
      return { ...state }

    case collectionsConstants.DELETE_COLLECTION:
      return {
        ...state,
        items: state.items.filter((collection) => {
          if (collection.id === action.collection.id) return false
          return true
        }),
      }

    case collectionsConstants.UPDATE_COLLECTION:
      return {
        ...state,
        items: state.items.map((collection) => {
          if (collection.id === action.collection.id) {
            // return updated item
            return { ...collection, ...action.collection }
          }
          // Leave every other item unchanged
          return collection
        }),
      }

    case collectionsConstants.SHARE_COLLECTION:
    default:
      return state
  }
}
