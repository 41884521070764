import 'moment/locale/fr'
import moment from 'moment'
import { useState } from 'react'
import { IconCopy, IconCheck } from '_svg'
import Button from 'components/buttons/Button'

export default function Sidebar({ data, template }) {
  const [copyUrlConfirmed, setCopyUrlConfirmed] = useState(false)

  const copyUrl = async (e) => {
    setCopyUrlConfirmed(true)
    await navigator.clipboard.writeText(`https://${template?.domain}/landing/${data.slug}`)
    setTimeout(() => {
      setCopyUrlConfirmed(false)
    }, 2000)
  }

  return (
    <>
      <div className="sidebar__title">{data.title}</div>
      <div className="block label label--med">Date de création</div>
      <span className="block gen-text">{moment(data.created_at).locale('fr').format('L')}</span>
      <div className="block label label--med">Nombre de médias</div>
      <span className="block gen-text">{data.limit}</span>
      <div className="block label label--med">URL de la landing</div>
      <div className="flex input-copy-paste">
        <input
          type="text"
          name="landingEmbedded"
          id="landingEmbedded"
          className="text-input"
          defaultValue={data.slug && `https://${template?.domain}/landing/${data.slug}`}
          readOnly
        />
        <Button btnStyle="plain" onClickFn={copyUrl}>
          {copyUrlConfirmed ? <IconCheck /> : <IconCopy />}
        </Button>
      </div>
      <span color="#000">
        La landing page ne comprends pas de background et s'adaptera à votre site web et son affichage responsive
      </span>
    </>
  )
}
