import { landingsConstants } from '_constants'
const initialState = { items: [], pagination: {}, create: { collection: {} } }

export function landings(state = initialState, action) {
  switch (action.type) {
    case landingsConstants.GET_ALL:
      return { ...state, items: action.landings.data, pagination: action.landings.pagination }

    case landingsConstants.CREATE:
      const items = [state.items]
      items.push(action.data)
      return { ...state, items, create: { collection: {} } }

    case landingsConstants.CHOOSE_COLLECTION:
      return { ...state, create: { collection: action.collection } }

    case landingsConstants.UPDATE:
      return {
        ...state,
        items: state.items.map((landing) => {
          if (landing.id === action.landing.id) {
            // return updated item
            return action.landing
          }
          // Leave every other item unchanged
          return landing
        }),
      }

    case landingsConstants.DELETE:
      return {
        ...state,
        items: state.items.filter((landing) => {
          if (landing.id === action.landing.id) return false
          return true
        }),
      }
    default:
      return state
  }
}
