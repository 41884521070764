import React, { useState } from 'react'
import { IconGrid, IconList, IconGridColor, IconListColor } from '_svg'

export default function ListDisplayer({ callback, currentDisplay }) {
  const [displayMode, setDisplayMode] = useState(currentDisplay)

  const handleDisplay = (display) => {
    setDisplayMode(display)
    callback(display)
  }

  return (
    <div className="list-displayer">
      <div className="list-displayer__label">Vue</div>
      {displayMode === 'list' && (
        <>
          <IconGrid
            className="icon"
            onClick={() => {
              handleDisplay('grid')
            }}
          />
          <IconListColor
            className="icon"
            onClick={() => {
              handleDisplay('list')
            }}
          />
        </>
      )}

      {displayMode === 'grid' && (
        <>
          <IconGridColor
            className="icon"
            onClick={() => {
              handleDisplay('grid')
            }}
          />
          <IconList
            className="icon"
            onClick={() => {
              handleDisplay('list')
            }}
          />
        </>
      )}
    </div>
  )
}
