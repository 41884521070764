import React from 'react'
import { IconCrossSmall } from '_svg'
import { modalActions } from '_actions'
import { useDispatch } from 'react-redux'

export default function Modal({ children }) {
  const dispatch = useDispatch()
  const closeModal = (event) => {
    if (event.target === event.currentTarget || event.currentTarget.className === 'modal__close-button') {
      dispatch(modalActions.closeModal())
    }
  }
  return (
    <div className="modals" onClick={closeModal}>
      <div className="modal ">
        <div className="modal__inner">{children}</div>
        <button className="modal__close-button" onClick={closeModal}>
          <IconCrossSmall className="icon" />
        </button>
      </div>
    </div>
  )
}
