import React from 'react'
import { UploadOutlined, PaperClipOutlined, DeleteOutlined } from '@ant-design/icons'

const MAX_FILE_SIZE = 10485760 // 10MB

const FileUpload = (props) => {
  const {
    files,
    label,
    setFiles,
    backgroundColor,
    subLabel = null,
    maxNumberOfFiles,
    maxFileSizeErrorMessage,
    maxLimitationErrorMessage,
  } = props
  const [error, setError] = React.useState(null)

  const handleInputChange = (e) => {
    if (e.target.files.length > maxNumberOfFiles) {
      setFiles([])
      setError(maxLimitationErrorMessage.replace('{{ limit }}', maxNumberOfFiles))
      return
    }

    const files = Object.values(e.target.files)

    const validFiles = []
    const errors = []
    // check if one of the files is too big
    for (const file of files) {
      if (file.size > MAX_FILE_SIZE) {
        errors.push(maxFileSizeErrorMessage.replace('{{ filename }}', file.name))
      } else {
        validFiles.push(file)
      }
    }

    if (errors.length > 0) {
      setError(errors.join('<br />'))
    }

    if (errors.length === 0 && error !== null) setError(null)
    console.log(validFiles)
    setFiles(validFiles)
  }

  const computeSubLabel = (label) => {
    return label.replace('{{ max_limit }}', maxNumberOfFiles).replace('{{ max_filesize }}', formatBytes(MAX_FILE_SIZE))
  }

  return (
    <div className="flex flex-col items-center justify-center text-center">
      <div>
        <label htmlFor="file-upload" className="button mt-6 mb-3" style={{ backgroundColor, color: '#fff' }}>
          <UploadOutlined /> {label}
        </label>

        <input
          multiple
          type="file"
          id="file-upload"
          accept="image/*,video/*"
          style={{ display: 'none' }}
          onChange={handleInputChange}
        />
      </div>

      {subLabel && (
        <span
          className="mt-2 file-upload__sublabel"
          dangerouslySetInnerHTML={{ __html: computeSubLabel(subLabel) }}
        ></span>
      )}
      {error && (
        <p
          style={{ color: '#ff4d4f' }}
          className="gen-text text-center my-4"
          dangerouslySetInnerHTML={{ __html: error }}
        ></p>
      )}
      {files.length > 0 && (
        <ul className="file-upload__container">
          {files.map((file, i) => (
            <li className="file-upload__item" key={`file-${i}`}>
              <span style={{ flex: 1 }}>
                <PaperClipOutlined style={{ fontSize: 14, color: '#00000073' }} />
                {file.name} ({formatBytes(file.size)})
              </span>
              <button
                onClick={() => setFiles(files.filter((f) => f.name !== file.name))}
                className="file-upload__delete"
              >
                <DeleteOutlined style={{ fontSize: 14, color: '#ff4d4f' }} />
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
/**
 * @description Return readable file size
 * @static
 * @param {intefer} bytes file size in bytes
 * @param {number} [decimals=2] number of decimals
 * @return {string} readable file size
 */
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
export default FileUpload
