import React from 'react'
import { useSelector } from 'react-redux'
import { modalsConstants } from '_constants'
import * as Modals from 'components/modals'

export default function ModalRoot() {
  const MODAL_COMPONENTS = {}
  MODAL_COMPONENTS[modalsConstants.DELETE_FROM_LIBRARY] = Modals.DeleteFromLibrary
  MODAL_COMPONENTS[modalsConstants.SHOW_MEDIA_DETAILS] = Modals.MediaDetails
  MODAL_COMPONENTS[modalsConstants.CREATE_COLLECTION] = Modals.CreateCollection
  MODAL_COMPONENTS[modalsConstants.DELETE_COLLECTION] = Modals.DeleteCollection
  MODAL_COMPONENTS[modalsConstants.ADD_TO_COLLECTION] = Modals.AddToCollection
  MODAL_COMPONENTS[modalsConstants.DOWNLOAD_TO_COLLECTION] = Modals.DownloadToCollection
  MODAL_COMPONENTS[modalsConstants.SHARE_COLLECTION] = Modals.ShareCollection
  MODAL_COMPONENTS[modalsConstants.LINK_TO_PRODUCT] = Modals.LinkToProduct
  MODAL_COMPONENTS[modalsConstants.DELETE_FROM_COLLECTION] = Modals.DeleteFromCollection
  MODAL_COMPONENTS[modalsConstants.REJECT_FROM_FEED] = Modals.DeleteFromFeed
  MODAL_COMPONENTS[modalsConstants.CREATE_MESSAGE] = Modals.CreateMessage
  MODAL_COMPONENTS[modalsConstants.UPDATE_MESSAGE] = Modals.CreateMessage
  MODAL_COMPONENTS[modalsConstants.DELETE_MESSAGE] = Modals.DeleteMessage
  MODAL_COMPONENTS[modalsConstants.ASK_MESSAGE] = Modals.AskMessage
  MODAL_COMPONENTS[modalsConstants.CREATE_USER] = Modals.CreateUser
  MODAL_COMPONENTS[modalsConstants.UPDATE_USER] = Modals.CreateUser
  MODAL_COMPONENTS[modalsConstants.DELETE_USER] = Modals.DeleteUser
  MODAL_COMPONENTS[modalsConstants.DELETE_FEED] = Modals.DeleteFeed
  MODAL_COMPONENTS[modalsConstants.DELETE_FEEDS] = Modals.DeleteFeeds
  MODAL_COMPONENTS[modalsConstants.CHANGE_PASSWORD] = Modals.ChangePassword
  MODAL_COMPONENTS[modalsConstants.CHOOSE_COLLECTION] = Modals.ChooseCollection
  MODAL_COMPONENTS[modalsConstants.DELETE_WIDGET] = Modals.DeleteWidget
  MODAL_COMPONENTS[modalsConstants.AUTHOR_NAME] = Modals.AuthorName
  MODAL_COMPONENTS[modalsConstants.ADD_FEED_MEDIA_MANUALLY] = Modals.AddFeedMediaManually

  return useSelector((state) => {
    if (!state.modal.modalType) return null
    const ModalContent = MODAL_COMPONENTS[state.modal.modalType]
    return (
      <Modals.Modal>
        <ModalContent {...state.modal.modalProps} />
      </Modals.Modal>
    )
  })
}
