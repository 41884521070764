import PageHeader from 'components/layout/PageHeader'

export default function HeaderWithTabs({ landing }) {
  return (
    <PageHeader
      data={[{ label: 'Landings', url: '/landings' }, { label: landing ? landing.name : '' }]}
      tabs={[
        { label: 'Réglages', url: `/landing-pages/${landing ? `${landing.id}/settings` : ''}` },
        // { label: 'Statistiques', url: `/landing-pages/${landing ? `${landing.id}/statistics` : ''}` },
      ]}
    />
  )
}
