import SelectModule from 'components/filters/SelectModule'
import SearchModule from 'components/filters/SearchModule'
import CheckBoxesModule from 'components/filters/CheckBoxesModule'
import { IconHashtag } from '_svg'

export default function Filters({ searchFn, chbxFilterFn, hashtagFn, hashtags = [], askStatusFn = null }) {
  const getHashtags = () => {
    return hashtags.map((el) => {
      return { label: el, value: el }
    })
  }

  return (

    <div className='filters pb-6'>
      <div className='flex filters__first-row'>
        <div className='filters__search'>
          <SearchModule callback={searchFn} placeholder="Expression, hashtag..." />
        </div>
        {hashtags.length > 1 && (
          <SelectModule
            title="Par hashtag"
            items={[{ label: 'tous', value: '' }, ...getHashtags()]}
            // icon={<IconHashtag className="icon" />}
            callback={hashtagFn}
          />
        )}
        <CheckBoxesModule
          title="types de contenu"
          items={[
            { label: 'Photo', value: 'IMAGE' },
            { label: 'Vidéo', value: 'VIDEO' },
          ]}
          callback={chbxFilterFn}
          flex={true}
          isLibrary={true}
        />
      </div>
    </div>
  )
}
