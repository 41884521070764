import { history } from '_helpers'
import { userService } from '_services'
import { userConstants, modalsConstants } from '_constants'
import { alertActions, noticesActions, modalActions, accountActions } from './'

export const userActions = {
  login,
  logout,
  getSelf,
  update,
  create,
  deleteU,
  updateProfilePicture,
  changePassword,
  resetPassword,
  forgotPassword,
}

function login(username, password, from) {
  return (dispatch) => {
    dispatch(request({ username }))

    userService.login(username, password).then(
      (user) => {
        dispatch(success(user))
        history.push(from)
        dispatch(getSelf(from))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user }
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user }
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error }
  }
}

function getSelf(from = null) {
  return (dispatch) => {
    userService.getSelf().then(
      (user) => {
        dispatch(success(user))
        dispatch(alertActions.clear())
        if (user.has_facebook_credentials) {
          const userData = JSON.parse(localStorage.getItem('user'))
          userData.has_facebook_credentials = true
          localStorage.setItem('user', JSON.stringify(userData))
        }

        from ? history.push(from) : history.push('/')
      },
      (error) => {
        logout()
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(user) {
    return { type: userConstants.USER_GET_SELF, user }
  }
  function failure(error) {
    return { type: userConstants.USER_GET_SELF_FAILURE, error }
  }
}

function update(user, name) {
  return (dispatch) => {
    userService.update(user).then(
      (result) => {
        dispatch(accountActions.getSelf())
        dispatch(userActions.getSelf())
        dispatch(modalActions.closeModal())
        dispatch(noticesActions.profileUpdated({ name, id: user.id }))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function success(user) { return { type: userConstants.UPDATE, user } }
}

function deleteU(id) {
  return (dispatch) => {
    userService.deleteU(id).then(
      (result) => {
        dispatch(accountActions.getSelf())
        dispatch(userActions.getSelf())
        dispatch(modalActions.closeModal())
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function success(user) { return { type: userConstants.UPDATE, user } }
}

function create(user, name) {
  return (dispatch) => {
    userService.create(user).then(
      (result) => {
        dispatch(accountActions.getSelf())
        dispatch(userActions.getSelf())
        dispatch(modalActions.closeModal())
        dispatch(noticesActions.profileUpdated({ name, id: user.id }))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function success(user) { return { type: userConstants.CREATE, user } }
}

function updateProfilePicture(base64image, extension) {
  return (dispatch) => {
    userService.updateProfilePicture(base64image, extension).then(
      (result) => {
        dispatch(success(result))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(result) {
    return { type: userConstants.UPDATE_PROFILE_PICTURE, result }
  }
}

function logout() {
  userService.logout()
  history.push('/login')
  return { type: userConstants.LOGOUT }
}

function changePassword(datas) {
  return (dispatch) => {
    userService.changePassword(datas).then(
      (result) => {
        dispatch(modalActions.closeModal())
        dispatch(noticesActions.passwordUpdated())
      },
      (error) => {
        dispatch(failure(error.message.toString()))
      }
    )
  }

  function failure(error) {
    return { type: modalsConstants.UPDATE_MODAL, error }
  }
}

function resetPassword(datas) {
  return (dispatch) => {
    userService.resetPassword(datas).then(
      (result) => {
        dispatch(noticesActions.passwordReset())
        history.push('/login')
      },
      (error) => {
        dispatch(alertActions.error(error))
      }
    )
  }
}

function forgotPassword(datas) {
  return (dispatch) => {
    userService.forgotPassword(datas).then(
      (result) => {
        dispatch(noticesActions.passwordReset())
        history.push('/login')
      },
      (errors) => {
        Object.keys(errors).forEach((key) => {
          dispatch(alertActions.error(key + ' : ' + errors[key][0]))
        })
      }
    )
  }
}
