import CheckBox from './CheckBox'
import React, { useState } from 'react'

export default function CheckBoxesModule({ title = null, items = [], callback, customClass = null, textColor, noPurple = false, flex = false, isLibrary = false }) {
  const originalValues = {}
  items.map((el) => (originalValues[el.value] = false))
  const [values, setValues] = useState(originalValues)

  const handleCbxChange = (el) => {
    const temp = { ...values }
    temp[el.name] = el.checked
    setValues(temp)
    callback(temp)
  }

  const getItems = () => {
    return items.map((el, i) => {
      return (
        <li key={`cb${i}`} style={flex ? { marginTop: 0 } : {}} >
          <CheckBox name={el.value} label={el.label} callback={(el) => handleCbxChange(el)} customClass={customClass} textColor={textColor} noPurple={noPurple} />
        </li>
      )
    })
  }

  return (
    <div className="checkboxes-module">
      {title && <div className="sup-title sup-title--XS sup-title--grey-light">{title}</div>}
      <ul className="list-reset" style={flex ? { "display": "flex", "justifyContent": "space-between", "gap": "1rem", "paddingTop": isLibrary ? ".75rem" : "0" } : {}}>{getItems()}</ul>
    </div>
  )
}
