import { noticesConstants } from '_constants'
const initialState = []

export function notices(state = initialState, action) {
  switch (action.type) {
    case noticesConstants.OPEN_NOTICE:
      const exists = state.filter((el) => el.noticeProps.id === action.noticeProps.id)
      if (exists.length > 0) return state
      return [...state, { noticeType: action.noticeType, noticeProps: action.noticeProps }]
    case noticesConstants.CLOSE_NOTICE:
      const updatedNotices = state.filter((el) => {
        return el.noticeProps.id !== action.id
      })
      return updatedNotices
    default:
      return state
  }
}
