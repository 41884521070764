import CheckBox from 'components/filters/CheckBox'

export default function CollectionMediaListItem({ data, selectFunction, isSelected = false, addedClass = '' }) {
  const handleSelection = (e) => {
    selectFunction(data, e.checked)
  }

  const getMedia = () => {
    if (data.type === 'VIDEO') {
      return (
        <div className="visual">
          <video controls autoPlay muted loop>
            <source src={data.path} type="video/mp4" />
          </video>
        </div>
      )
    } else if (data.type === 'IMAGE') {
      return <div className="visual" style={{ backgroundImage: `url('${data.path}')` }} />
    }
  }

  return (
    <div
      className={`collection-medias-list__item ${addedClass !== '' ? addedClass : ''} ${isSelected ? 'is-active' : ''}`}
    >
      {getMedia()}
      <div className="overlay">
        <CheckBox name="actions" callback={handleSelection} isChecked={isSelected} />
      </div>
    </div>
  )
}
