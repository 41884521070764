import 'moment/locale/fr'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default function TemplatesListItem({ data }) {

  return (
    <Link
      to={`/templates/${data.id}/settings`}
      className="widget-list__item"
      data-name={data.name}
      data-status={data.status}
      data-last-update={data.last_update}
    >
      <span className="feeds-list__item__name">{data.name}</span>
      <span className="feeds-list__item__status">{data.type === 'full' ? "Full" : "Avec sidebar"}</span>
      <span className="feeds-list__item__last-update">{moment(data.last_update).locale('fr').format('L')}</span>
    </Link>
  )
}
