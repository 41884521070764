import React, { useState } from 'react'
import moment from 'moment'

import { ReactComponent as IconDelete } from "../../_svg/icon-delete.svg"
import { ReactComponent as IconInsta } from "../../_svg/icon-insta.svg"
import { ReactComponent as IconTiktok } from "../../_svg/icon-tiktok.svg"
import { ReactComponent as IconSend } from "../../_svg/icon-send.svg"
import { ReactComponent as IconDate } from "../../_svg/icon-date.svg"
import { ReactComponent as IconLike } from "../../_svg/icon-like.svg"
import { ReactComponent as IconComment } from "../../_svg/icon-comment.svg"
import { ReactComponent as IconDiapo } from "../../_svg/icon-diapo.svg"
import CheckBox from "../filters/CheckBox";
import { feedService } from '_services';
import { modalActions, noticesActions } from '_actions';
import { useDispatch, useSelector } from 'react-redux';
import { IconDownload } from '_svg';
import useSingleton from 'components/utils/useSingleton'

export default function MediasCardItem({ data, selectFunction, isSelected = false, rejectFn, askFn }) {
  const dispatch = useDispatch()

  const auth = useSelector((state) => state.authentication)
  const account = useSelector((state) => state.account)
  const [hasAsk, setHasAsk] = useState(false)

  useSingleton(() => { // will always be triggered once
    setHasAsk(data.hasAsk)
  })

  const getHashtags = () => {
    if (!data.hashtags) return
    return data.hashtags.map((el, i) => (el.startsWith('#') ? el : `#${el} `))
  }

  const handleSelection = (e) => {
    selectFunction(data, e.checked)
  }

  const handleReject = () => {
    rejectFn(data)
  }

  const handleAsk = () => {
    askFn(data)
  }

  const handleSelfAsk = () => {
    feedService.saveSelfAssets(data.feedId, [data.id]).then((result) => {
      dispatch(noticesActions.postSaved())
      dispatch(modalActions.downloadToCollection(result.assets))
    })
  }

  const getMedia = () => {
    if (data.mediaType === 'IMAGE') {
      return getImage(data.mediaUrl)
    } else if (data.mediaType === 'VIDEO') {
      return getVideo(data.mediaUrl, data.posterUrl)
    } else if (data.mediaType === 'SIDECAR') {
      const children = JSON.parse(data.children)

      if (!children[0]) {
        return getImage(null)
      }

      if (children[0].media_type === 'IMAGE') {
        return getImage(children[0].media_url, true)
      } else if (children[0].media_type === 'VIDEO') {
        return getVideo(children[0].media_url, children[0].poster_url, true)
      }
    }
  }

  const getImage = (media_url, isDiapo = false) => {
    return (
      <div className="thumb" style={{ backgroundImage: `url('https://instagram.colegram.workers.dev/?feed_id=${data.feedId}&image_url=${btoa(media_url)}')` }} >
        {isDiapo &&
          <IconDiapo className="icon-diapo" />
        }
        {(account.instagram_usernames ?? []).includes(data.authorUsername) || (account.instagram_hashtags ?? []).filter(value => data?.hashtags?.includes(value)).length > 0 ?
          <img src={auth.user && auth.user.img ? auth.user.img : '/assets/images/default-profile.jpg'} alt="" style={{ position: "absolute", width: 25, bottom: 10, left: 10, borderRadius: "5px" }} />
          : <></>}
        <div className="hover">
          {getStats()}
          {getCTAs()}
        </div>
        {getCheckBox()}
      </div>
    )
  }

  const getVideo = (media_url, poster_url, isDiapo = false) => {
    return (
      <div className="thumb">
        <video controls muted loop poster={`https://instagram.colegram.workers.dev/?feed_id=${data.feedId}&image_url=${btoa(poster_url)}`}>
          <source src={`https://instagram.colegram.workers.dev/?feed_id=${data.feedId}&video_url=${btoa(media_url)}`} type="video/mp4" />
        </video>
        {isDiapo &&
          <IconDiapo className="icon-diapo" />
        }
        {(account.instagram_usernames ?? []).includes(data.authorUsername) || (account.instagram_hashtags ?? []).filter(value => data?.hashtags?.includes(value)).length > 0 ?
          <img src={auth.user && auth.user.img ? auth.user.img : '/assets/images/default-profile.jpg'} alt="" style={{ position: "absolute", width: 25, bottom: 10, left: 10, borderRadius: "5px" }} />
          : <></>}
        <div className="hover">
          {getStats()}
          {getCTAs()}
        </div>
        {getCheckBox()}
      </div>
    )
  }

  const getCheckBox = () => {
    return <CheckBox name="actions" callback={handleSelection} isChecked={isSelected} />
  }

  const getStats = () => {
    return (
      <div className="media-stats">
        <div className="media-date">
          <IconDate className="icon icon--white" />
          <span className="media-stats-label">
            {moment(data.timestamp * 1000)
              .locale('fr')
              .format('L')}
          </span>
        </div>
        <div className="media-likes mt-2">
          <IconLike className="icon icon--white" />
          <span className="media-stats-label">{data.likeCount} likes</span>
        </div>
        <div className="media-comment mt-2">
          <IconComment className="icon icon--white" />
          <span className="media-stats-label">{data.commentsCount} commentaires</span>
        </div>
      </div>
    )
  }

  const getCTAs = () => {
    return (
      <div className="ctas">
        <div className="card-cta" onClick={(e) => { handleReject() }}><IconDelete className="icon icon--orange" /></div>
        <a className="card-cta" href={data.permalink} target='_blank' rel="noreferrer" >
          {data.mediaUrl.indexOf('tiktok') === -1 ?
            <IconInsta className="icon icon--orange" />
            :
            <IconTiktok className="icon icon--orange" />
          }
        </a>

        {
          (account.instagram_usernames ?? []).includes(data.authorUsername) || (account.instagram_hashtags ?? []).filter(value => data.hashtags.includes(value)).length > 0 ?
            <div className={`card-cta card-cta--orange ${hasAsk ? 'disabled' : ''}`} onClick={(e) => handleSelfAsk()}>
              <IconDownload className="icon icon--white" />
            </div>
            :
            <div className={`card-cta card-cta--orange ${hasAsk ? 'disabled' : ''}`} onClick={(e) => handleAsk()}>
              <IconSend className="icon icon--white" />
            </div>
        }
      </div >
    )
  }

  return (
    <div className="asset-card">
      {getMedia()}
      <div className="content mt-4">
        <div className="description">{data.caption}</div>
        {/* <div className="media-hashtags mt-4">
          <span>@{data.authorUsername}</span>
        </div>
        <div className="media-hashtags mt-2"><span>{getHashtags()}</span></div> */}
      </div>
    </div>
  )
}
