import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { noticesConstants } from '_constants/notices.constants'
import { noticesActions } from '_actions/notices.actions'

export default function DeletedFromLibrary({ ...noticeProps }) {
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch(noticesActions.closeNotice(noticeProps.id))
    }, noticesConstants.AUTOCLOSE_TIMER)
  }, [dispatch, noticeProps])

  const getMedia = () => {
    if (noticeProps.mediaItem.type === 'VIDEO') {
      return (
        <div className="notice__visual">
          <video>
            <source src={noticeProps.mediaItem.path} type="video/mp4" />
          </video>
        </div>
      )
    } else if (noticeProps.mediaItem.type === 'IMAGE') {
      return <img src={noticeProps.mediaItem.path} className="notice__visual" alt="notice thumbnail" />
    }
  }

  return (
    <div className="notice-rejected-from-feed">
      {getMedia()}
      <div className="content">
        <div className="notice__title">Suppression de contenu</div>
        <div className="notice__msg">Ce média a bien été retiré de votre bibliothèque.</div>
      </div>
    </div>
  )
}
