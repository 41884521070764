import { IconFeed } from '_svg'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { noticesActions } from '_actions/notices.actions'
import { noticesConstants } from '_constants/notices.constants'

export default function FeedDeleted({ ...noticeProps }) {
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch(noticesActions.closeNotice(noticeProps.id))
    }, noticesConstants.AUTOCLOSE_TIMER)
  })

  return (
    <div className="notice-feed-deleted">
      <div className="icon-wrapper">
        <div className="icon-circle">
          <IconFeed className="icon" />
        </div>
      </div>
      <div className="content">
        <div className="notice__title">Feed supprimé</div>
        <div
          className="notice__msg"
          dangerouslySetInnerHTML={{ __html: `Votre feed <strong>${noticeProps.name}</strong> a bien été supprimé.` }}
        ></div>
      </div>
    </div>
  )
}
