import { history } from '_helpers'
import { widgetsService } from '_services'
import { widgetsConstants } from '_constants'
import { alertActions, noticesActions, modalActions } from '.'

export const widgetsActions = {
  chooseCollection,
  createWidget,
  getAllWidgets,
  deleteWidget,
  updateWidget,
}

function getAllWidgets(options) {
  return (dispatch) => {
    // dispatch(request());
    widgetsService.getAll(options).then(
      (widgets) => {
        dispatch(success(widgets))
      },
      (error) => {
        // dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: widgetsConstants.GET_ALL } }
  function success(widgets) {
    return { type: widgetsConstants.GET_ALL, widgets }
  }
  // function failure(error) { return { type: widgetsConstants.LOGIN_FAILURE, error } }
}

function createWidget(formData) {
  // name, user_id, locale, hashtags, description
  return (dispatch) => {
    // dispatch(request());
    widgetsService.create(formData).then(
      (widget) => {
        dispatch(success(widget))
        history.push('/widgets')
        dispatch(noticesActions.widgetCreated(widget))
      },
      (error) => {
        let msg = error.message
        switch (msg) {
          default:
            break
          case 'widget quota exceeded':
            msg = widgetsConstants.ERROR_QUOTA_EXCEEDED
            break
        }
        dispatch(alertActions.error(msg.toString()))
      }
    )
  }

  // function request() { return { type: widgetsConstants.GET_ALL } }
  function success(data) {
    return { type: widgetsConstants.CREATE, data }
  }
}

function chooseCollection(collection) {
  return (dispatch) => {
    dispatch({ type: widgetsConstants.CHOOSE_COLLECTION, collection })
    dispatch(modalActions.closeModal())
  }
}

function deleteWidget(widget) {
  // name, user_id, locale, hashtags, description
  return (dispatch) => {
    // dispatch(request());
    widgetsService.deleteWidget(widget.id).then(
      (data) => {
        dispatch(success(widget))
        history.push('/widgets')
        dispatch(noticesActions.widgetDeleted(widget))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: widgetsConstants.GET_ALL } }
  function success(widget) {
    return { type: widgetsConstants.DELETE, widget }
  }
  function failure(error) {
    return { type: widgetsConstants.DELETE_FAILURE, error }
  }
}

function updateWidget(widget) {
  // name, user_id, locale, hashtags, description
  return (dispatch) => {
    // dispatch(request());
    widgetsService.updateWidget(widget).then(
      (data) => {
        dispatch(success(widget))
        history.push('/widgets')
        dispatch(noticesActions.widgetUpdated(widget))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: widgetsConstants.GET_ALL } }
  function success(widget) {
    return { type: widgetsConstants.UPDATE, widget }
  }
  function failure(error) {
    return { type: widgetsConstants.DELETE_FAILURE, error }
  }
}
