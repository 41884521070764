import { libraryService } from '_services'
import { libraryConstants } from '_constants'
import { alertActions, noticesActions, modalActions } from './'

export const libraryActions = {
  getAllAssets,
  deleteAssets,
  getAsset,
  updateAsset,
  updateDipsplayMode,
}

function getAllAssets(options) {
  return (dispatch) => {
    // dispatch(request());
    libraryService.getAllAssets(options).then(
      (assets) => {
        dispatch(success(assets))
      },
      (error) => {
        // dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: libraryConstants.GET_ALL } }
  function success(assets) {
    return { type: libraryConstants.GET_ALL_ASSETS, ...assets }
  }
  // function failure(error) { return { type: libraryConstants., error } }
}

function deleteAssets(assets) {
  return (dispatch) => {
    assets.forEach((asset) => {
      fetch(asset.path).then((res) => {
        return res.blob().then((blob) => {
          asset.path = URL.createObjectURL(blob)
        })
      })
    })

    libraryService.deleteAssets(assets).then(
      (data) => {
        dispatch(success(data))
        assets.forEach((asset, i) => {
          setTimeout(dispatch(noticesActions.deletedFromLibrary(asset)), i * 300)
        })
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(data) {
    return { type: libraryConstants.DELETE_ASSETS, data }
  }
}

function getAsset(asset) {
  return (dispatch) => {
    libraryService.getAsset(asset.id).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(data) {
    return { type: libraryConstants.GET_ASSET, data }
  }
}

function updateAsset(item) {
  return (dispatch) => {
    libraryService.updateAsset(item).then(
      (item) => {
        dispatch(success(item))
        dispatch(modalActions.closeModal())
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(item) {
    return { type: libraryConstants.UPDATE_ASSET, item }
  }
}

function updateDipsplayMode(mode) {
  return (dispatch) => {
    dispatch({ type: libraryConstants.UPDATE_DISPLAYMODE, displayMode: mode })
  }
}
