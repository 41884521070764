import 'moment/locale/fr'
import React, { useRef } from 'react'
import SimpleBar from 'simplebar-react'

function Tabs({ tabsLabel = 'tab', contents = [], selectFn, translation, format }) {
  const tabs = useRef([])
  tabs.current = []
  const tabContents = useRef([])
  tabContents.current = []

  const addToTabs = (el) => {
    if (el && !tabs.current.includes(el)) {
      tabs.current.push(el)
    }
  }

  const addToTabContents = (el) => {
    if (el && !tabContents.current.includes(el)) {
      tabContents.current.push(el)
    }
  }

  const clickTab = (e, index) => {
    selectFn(translation, index)

    tabs.current.forEach((el) => {
      el.classList.remove('is-active')
    })
    e.currentTarget.classList.add('is-active')

    tabContents.current.forEach((el) => {
      el.classList.remove('is-active')
    })
    tabContents.current[index].classList.add('is-active')
  }

  return (
    <div className="tabs-wrapper mt-6">
      <ul className="list-reset flex tabs">
        {contents.map((el, i) => {
          return (
            <li
              ref={addToTabs}
              key={i}
              className={`tabs-item ${i === 0 ? 'is-active' : ''}`}
              onClick={(e) => {
                clickTab(e, i)
              }}
            >
              <span>
                {tabsLabel} {i + 1}
              </span>
            </li>
          )
        })}
      </ul>
      <ul className="list-reset tabs-contents">
        {contents.map((el, i) => {
          return (
            <li ref={addToTabContents} key={i} className={`tabs-contents-item ${i === 0 ? 'is-active' : ''}`}>
              <SimpleBar autoHide={false} style={{ maxHeight: 88 }}>
                <div className="scrollable">
                  <span style={{ fontWeight: "bolder" }}>{"Message : \n"}</span>
                  {format(el.translations[translation])}
                  {el.commentTranslations && el.commentTranslations[translation] &&
                    <>
                      {"\n\n"}
                      <span style={{ fontWeight: "bolder" }}>{"Commentaire : \n"}</span>
                      {format(el.commentTranslations[translation])}
                    </>
                  }
                </div>
              </SimpleBar>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Tabs
