import React, { useEffect, useState } from 'react'
import Layout from 'components/layout/Layout'
import PageHeader from 'components/layout/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { landingsActions } from '_actions/landings.actions'
import SearchModule from 'components/filters/SearchModule'
import Button from 'components/buttons/Button'
import SortBar from 'components/filters/SortBar'
import LandingsListItem from 'components/_landings/LandingsListItem'
import Pagination from 'components/navigation/Pagination'
import { Skeleton } from 'antd'
import { LANDING_QUOTA, PLAN } from '_constants'
import { accountActions, userActions } from '_actions'

import { accountEmpty, userEmpty } from '_services/Utils'

function Landings() {
  const dispatch = useDispatch()
  const landingsRdux = useSelector((state) => state.landings)
  const user = useSelector((state) => state.authentication.user)
  const [landings, setLandings] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [requestOptions, setRequestOptions] = useState({
    q: '',
    page: 1,
    limit: 20,
    sort_by: 'last_update',
    sort_order: 'DESC',
  })
  const [pagination] = useState({
    current_page: 1,
    limit: 20,
    total_count: 0,
  })

  const account = useSelector((state) => state.account)
  const [canCreate, setCanCreate] = useState(true)

  useEffect(() => {
    if (accountEmpty(account)) dispatch(accountActions.getSelf())
    if (userEmpty(user)) dispatch(userActions.getSelf('/landings'))

    switch (account.plan) {
      case PLAN.LIGHT:
        setCanCreate(landings.length < LANDING_QUOTA.PLAN_LIGHT)
        break
      case PLAN.PRO:
        setCanCreate(landings.length < LANDING_QUOTA.PLAN_PRO)
        break

      case PLAN.ULTRA:
        setCanCreate(user?.isAdmin ? true : landings.length < LANDING_QUOTA.PLAN_ULTRA_CHILDREN)
        break

      default:
        setCanCreate(false)
        break
    }
  }, [user, account, landings, dispatch])

  useEffect(() => {
    // default sorting by last update:
    const landings = [...landingsRdux.items.sort(dynamicSort('-last_update'))]
    setLandings(landings)
    setIsLoading(false)
  }, [landingsRdux])

  useEffect(() => {
    // condition te prevent loading on first loading
    if (!landings) return
    loadLandings()
  }, [requestOptions])

  const loadLandings = () => {
    if (isLoading) return
    setIsLoading(true)
    dispatch(landingsActions.getAllLandings(requestOptions))
  }

  const getlandingsList = () => {
    if (isLoading) {
      return Array.apply(null, Array(requestOptions.limit)).map((el, i) => {
        return (
          <li className="mt-10 stagger-anim" key={i}>
            <Skeleton active paragraph={{ rows: 1 }} />
          </li>
        )
      })
    } else {
      if (landings.length > 0) {
        return landings.map((el, i) => {
          return (
            <li key={i} className="stagger-anim">
              <LandingsListItem data={el} />
            </li>
          )
        })
      } else {
        if (requestOptions.q !== '') {
          // search result
          return <li className="mt-6 gen-text stagger-anim">Votre recherche n'a donné aucun résultat.</li>
        } else {
          return (
            <li className="mt-6 gen-text stagger-anim">
              Vous n'avez encore créé aucune landing page.{' '}
              <Link to="/landing-pages/create" className="info">
                Créez-en une maintenant !
              </Link>
            </li>
          )
        }
      }
    }
  }

  const search = (str, operatorAnd) => {
    const temp = {
      q: encodeURIComponent(str),
      operatorAnd: operatorAnd,
      page: 1
    }
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const sortBy = (attr) => {
    setLandings([...landings.sort(dynamicSort(attr))])
  }

  const dynamicSort = (property) => {
    // https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
    var sortOrder = 1
    if (property[0] === '-') {
      sortOrder = -1
      property = property.substr(1)
    }
    // if(property === 'last_update' || property === 'date_creation') sortOrder = -1;
    if (property === 'status') sortOrder = -1
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
      return result * sortOrder
    }
  }

  const handlePagination = (index) => {
    setRequestOptions({ ...requestOptions, page: index })
  }

  return (
    <Layout className="page page-landings">
      <PageHeader data={[{ label: 'Landings' }]} />
      <div className="page__content">
        <div className="page__intro">
          Ajoutez une landing page pour intégrer vos contenus UGC sur vos canaux de distributions. Gérez une landing page existante en
          cliquant sur son nom.
          {!canCreate && (
            <>
              <br />
              <br />
              <div className="gen-text gen-text--red">Vous avez atteint votre quota de landing pages disponibles !</div>
            </>
          )}
        </div>
        <div className="sticky-wrapper">
          <div className="flex justify-between items-end">
            <SearchModule callback={search} placeholder="Rechercher..." />
            {
              <span className="assets-total-count">
                {landings && landings.length > 0
                  ? `${landings.length} landing page${landings.length > 1 ? 's' : ''}`
                  : `0 landing page`}
              </span>
            }
            <Button
              target="_self"
              btnStyle="plain"
              url="/landing-pages/create"
              disabled={!canCreate}
              color={canCreate ? 'red' : 'grey'}
            >
              Créer une landing page
            </Button>
          </div>
          <SortBar
            data={[
              { label: 'titre', sortingAttribute: 'title' },
              { label: 'statut', sortingAttribute: 'status' },
              { label: 'dernier update', sortingAttribute: '-last_update' },
            ]}
            sortFunction={sortBy}
          />
        </div>
        <ul className="landings-list list-reset">{getlandingsList()}</ul>

        {pagination && <Pagination data={pagination} requestFn={handlePagination} />}
      </div>
    </Layout>
  )
}

export default Landings
