import React from 'react'
import { useDispatch } from 'react-redux'
import { feedActions, modalActions } from '_actions'
import Button from 'components/buttons/Button'

export default function DeleteFromFeed({ ...modalProps }) {
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(modalActions.closeModal())
  }

  const deleteAsset = () => {
    if(modalProps.groupedAssets !== undefined) {
      Object.keys(modalProps.groupedAssets).forEach(function(key, index) {
        const assets = modalProps.groupedAssets[key]
        if(Object.keys(modalProps.groupedAssets).length === index + 1) {
          dispatch(feedActions.deleteAsset(assets, key, modalProps.callback))
        } else {
          dispatch(feedActions.deleteAsset(assets, key))
        }
      })
    } else {
      dispatch(feedActions.deleteAsset(modalProps.media, modalProps.feedID, modalProps.callback))
    }
    closeModal()
  }

  const getSentence = () => {
    if (modalProps.groupedAssets || modalProps.media.length > 1) {
      return 'Voulez-vous vraiment rejeter ces contenus du feed ?'
    } else {
      return 'Voulez-vous vraiment rejeter ce contenu du feed ?'
    }
  }

  return (
    <div className="modal-delete-from-feed">
      <div className="modal__title text-center">Rejeter du feed</div>
      <div className="modal__grip text-center">{getSentence()}</div>
      <div className="flex justify-between">
        <Button btnStyle="plain" onClickFn={deleteAsset}>
          Rejeter
        </Button>
        <Button btnStyle="border" color="grey-lighter" onClickFn={closeModal}>
          Annuler
        </Button>
      </div>
    </div>
  )
}
