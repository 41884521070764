import { IconLibrary } from '_svg'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { noticesActions } from '_actions/notices.actions'
import { noticesConstants } from '_constants/notices.constants'

export default function CollectionDeleted({ ...noticeProps }) {
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch(noticesActions.closeNotice(noticeProps.id))
    }, noticesConstants.AUTOCLOSE_TIMER)
  })

  return (
    <div className="notice-collection-created">
      <IconLibrary className="icon" />
      <div className="content">
        <div className="notice__title">Collection supprimée</div>
        <div
          className="notice__msg"
          dangerouslySetInnerHTML={{
            __html: `La collection <strong>${noticeProps.name}</strong> a bien été supprimée.`,
          }}
        ></div>
      </div>
    </div>
  )
}
