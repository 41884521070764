export const libraryConstants = {
  GET_ALL_ASSETS: 'LIBRARY_GET_ALL_ASSETS',
  GET_ASSET: 'LIBRARY_GET_ASSET',
  CREATE_ASSET: 'LIBRARY_CREATE_ASSET',
  CREATE_ASSET_FAILURE: 'LIBRARY_CREATE_ASSET_FAILURE',
  UPDATE_ASSET: 'LIBRARY_ASSET_UPDATE',
  UPDATE_ASSET_FAILURE: 'LIBRARY_ASSET_UPDATE_FAILURE',
  DELETE_ASSETS: 'LIBRARY_ASSET_DELETE',
  UPDATE_DISPLAYMODE: 'LIBRARY_UPDATE_DISPLAYMODE',
}
