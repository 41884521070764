import { Skeleton } from 'antd'
import { Link } from 'react-router-dom'
import Layout from 'components/layout/Layout'
import Button from 'components/buttons/Button'
import Sorting from 'components/filters/Sorting'
import React, { useEffect, useState } from 'react'
import CheckBox from 'components/filters/CheckBox'
import MediasListItem from 'components/_library/MediasListItem'
import MediasCardItem from 'components/_library/MediasCardItem'
import Filters from 'components/_library/Filters'
import PageHeader from 'components/layout/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import Pagination from 'components/navigation/Pagination'
import { IconJoin, IconDownload, IconDelete } from '_svg'
import ListDisplayer from 'components/buttons/ListDisplayer'
import { libraryActions, modalActions, noticesActions } from '_actions'

function Library() {
  const library = useSelector((state) => state.library)
  const [isLoading, setIsLoading] = useState(true)
  const [displayMode, setDisplayMode] = useState(library.displayMode)
  const [assets, setAssets] = useState([])
  const [hashtags, setHashtags] = useState([])
  // const [initialAssets, setinitialAssets] = useState([])
  const [selectedAssets, setSelectedAssets] = useState([])
  // const [firstReq, setFirstReq] = useState(true)

  const [pagination, setPagination] = useState({
    current_page: 1,
    limit: 30,
    total_count: 0,
  })
  // pagnation & filters
  const [requestOptions, setRequestOptions] = useState({
    q: '',
    page: 1,
    limit: 30,
    // media_type: 'IMAGE,VIDEO,SIDECAR',
    sort_by: 'created_at',
    sort_order: 'DESC',
  })

  const dispatch = useDispatch()

  useEffect(() => {
    /*   if(library && library.assets && library.assets.length <= 1 && firstReq) {
        loadAssets()
        // setFirstReq(false)
      } else { */
    if (library.pagination !== pagination) setPagination({ ...pagination, ...library.pagination })
    setAssets(library.assets)
    // setinitialAssets(library.assets)
    // }
    setIsLoading(false)
  }, [library])

  useEffect(() => {
    // condition te prevent loading on first loading
    // if(!assets || !feed.assets) return
    loadAssets()
  }, [requestOptions])

  const loadAssets = () => {
    setIsLoading(true)
    setSelectedAssets([])
    scrollToTop()
    dispatch(libraryActions.getAllAssets(requestOptions))
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleSearch = (str, operatorAnd) => {
    const temp = {
      q: encodeURIComponent(str),
      operatorAnd: operatorAnd,
      page: 1,
    }
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const filterByMediaType = (selection) => {
    const types = Object.entries(selection)
      .filter((el) => el[1])
      .map((el) => el[0])
    const temp = {
      page: 1,
      media_type: types.toString(),
    }
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const handlePagination = (index) => {
    const temp = {
      page: index,
    }
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const updatePaginationLimit = (n) => {
    const temp = {
      limit: n,
    }
    setPagination({ ...pagination, ...temp })
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const updateSorting = (sorting) => {
    const temp = {
      sort_by: sorting,
    }
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const selectAsset = (asset, isChecked) => {
    if (isChecked) {
      setSelectedAssets([...selectedAssets, asset])
    } else {
      var newSelectedAssets = selectedAssets.filter((a) => {
        return a.id !== asset.id
      })
      setSelectedAssets(newSelectedAssets)
    }
  }

  const selectAllAssets = (el) => {
    const selectedAssets = []
    if (el.checked) {
      assets.forEach((el) => {
        selectedAssets.push(el)
      })
    }
    setSelectedAssets(selectedAssets)
  }

  const groupedAddToCol = () => {
    dispatch(modalActions.addToCollection(selectedAssets))
    setSelectedAssets([])
  }

  const deleteAsset = (elts) => {
    dispatch(modalActions.deleteFromLibrary(elts))
    setSelectedAssets([])
  }

  const download = (elArray) => {
    if (elArray.length > 0) {
      dispatch(noticesActions.download(elArray))
    }
    /*  elArray.forEach((el, i) => {
       setTimeout(()=>{dispatch(noticesActions.download(el))}, i*300)
     }) */
  }

  const downloadCSV = (elArray) => {
    if (elArray.length > 0) {
      dispatch(noticesActions.downloadCSV(elArray))
    }
    /*  elArray.forEach((el, i) => {
       setTimeout(()=>{dispatch(noticesActions.download(el))}, i*300)
     }) */
  }

  const groupDownload = () => {
    download(selectedAssets)
    setSelectedAssets([])
  }

  const groupDownloadCSV = () => {
    downloadCSV(selectedAssets)
    setSelectedAssets([])
  }

  const getAssetsList = () => {
    if (isLoading) {
      return Array.apply(null, Array(requestOptions.limit)).map((el, i) => {
        return (
          <li className="mt-10 stagger-anim" key={i}>
            <Skeleton active avatar paragraph={{ rows: 3 }} />
          </li>
        )
      })
    } else {
      if (assets.length > 0) {
        return assets.map((el) => {
          // if (el.hashtags) {
          //   el.hashtags.map((item) => {
          //     if (!hashtags.includes(item)) setHashtags([...hashtags, item])
          //   })
          //   // if (!el.hashtags.some(item => hashtagsTemp.includes(item))) {
          //   //   hashtagsTemp = [...hashtagsTemp, ...el.hashtags]
          //   //   console.log(hashtagsTemp)
          //   //   // hashtagsTemp.push(el.hashtags)
          //   //   setHashtags(hashtagsTemp)
          //   // }
          // }
          if (displayMode === 'list')
            return (
              <li key={el.id} className="stagger-anim">
                <MediasListItem
                  data={el}
                  selectFunction={selectAsset}
                  isSelected={selectedAssets.includes(el)}
                  downloadFunction={download}
                  deleteFunction={deleteAsset}
                />
              </li>
            )
          else if (displayMode === 'grid')
            return (
              <li key={el.id} className="stagger-anim">
                <MediasCardItem
                  data={el}
                  selectFunction={selectAsset}
                  isSelected={selectedAssets.includes(el)}
                  downloadFunction={download}
                  deleteFunction={deleteAsset}
                />
              </li>
            )
        })
      } else {
        if (requestOptions.q !== '' || requestOptions.media_type !== '') {
          // search result
          return <li className="mt-6 gen-text stagger-anim">Votre recherche n'a donné aucun résultat.</li>
        } else {
          return (
            <li className="mt-6 gen-text stagger-anim">
              Vous n'avez encore obtenu aucune autorisations d'utilisation de contenu.{' '}
              <Link to="/feed/" className="info">
                Créez votre première demande !
              </Link>
            </li>
          )
        }
      }
    }
  }

  return (
    <Layout className="page page-library">
      <PageHeader
        data={[
          { label: 'Bibliothèque' },
        ]} /*  tabs={[{label: 'Toute la bibliothèque', url:`/library`}, {label: 'Collections', url:`/collections`}]} */
      />
      <div className="sticky-wrapper sticky-wrapper-library">
        <div className={`${isLoading ? ' is-loading' : ''}`}>
          {assets && (
            <Filters
              searchFn={handleSearch}
              chbxFilterFn={filterByMediaType}
              hashtagFn={handleSearch}
              hashtags={hashtags}
            />
          )}
        </div>
        <div className="flex justify-between top-list">
          {pagination && (
            <span className="assets-total-count">{`${pagination.total_count} Média${pagination.total_count > 1 ? 's' : ''
              }`}</span>
          )}
        </div>
        <div className="flex justify-between top-list">
          <CheckBox
            name="action"
            label="Tout selectionner"
            isSelected={selectedAssets.length > 0}
            isChecked={selectedAssets.length > 0 && selectedAssets.length === assets.length}
            groupAction={true}
            callback={selectAllAssets}
          />
          <div className="flex flex-nowrap items-center">
            <Sorting
              data={{
                displayBy: [30, 60],
                sortBy: [
                  { label: 'Date du post', value: 'timestamp' },
                  { label: 'Date de levée de droits', value: 'created_at' },
                  { label: 'Nombre de commentaires', value: 'commentsCount' },
                  { label: 'Nombre de likes', value: 'likeCount' },
                ],
              }}
              defaultPagination={pagination.limit}
              paginFn={updatePaginationLimit}
              defaultSorting={requestOptions.sort_by}
              sortFn={updateSorting}
            />
            <ListDisplayer
              callback={(display) => {
                dispatch(libraryActions.updateDipsplayMode(display))
                setDisplayMode(display)
                scrollToTop()
              }}
              currentDisplay={displayMode}
            />
          </div>
        </div>
      </div>
      <div className="page__content pt-0-important">
        <ul className={`medias-list list-reset ${displayMode}`}>{getAssetsList()}</ul>
        {pagination && <Pagination data={pagination} requestFn={handlePagination} />}
      </div>
      <div className={`grouped-actions ${selectedAssets.length > 1 ? 'is-active' : ''}`}>
        <div className="grouped-actions__counter">
          Vous avez sélectionné{' '}
          <span className="text-gradient">
            {selectedAssets.length} Média{selectedAssets.length > 1 && 's'}
          </span>
        </div>
        <div className="grouped-actions__ctas">
          <Button btnStyle="plain" addedclass="button-icon" onClickFn={groupedAddToCol}>
            <IconJoin className="icon" />
            Associer à une collection
          </Button>
          <Button
            btnStyle="border"
            color="grey-lighter"
            type={null}
            addedclass="button-icon"
            onClickFn={() => {
              deleteAsset(selectedAssets)
            }}
          >
            <IconDelete className="icon" />
            Supprimer les contenus
          </Button>
          <Button btnStyle="border" color="grey-lighter" type={null} addedclass="button-icon" onClickFn={groupDownload}>
            <IconDownload className="icon" />
            Télécharger les contenus
          </Button>
          <Button
            btnStyle={'border'}
            color={'grey-lighter'}
            type={null}
            addedclass="button-icon"
            onClickFn={groupDownloadCSV}
          >
            <IconDownload className="icon" />
            CSV
          </Button>
          {/* <Button onClickFn={null}  btnStyle={'border'} color={'grey-lighter'} type={null} addedclass="button-icon"><IconTag className="icon"/>Ajouter un tag</Button> */}
        </div>
      </div>
    </Layout>
  )
}

export default Library
