import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { collectionsActions, modalActions, widgetsActions, landingsActions } from '_actions'
import CollectionListItem from 'components/_collections/CollectionListItem'
import useSingleton from 'components/utils/useSingleton'

export default function ChooseCollection({ ...modalProps }) {
  const collectionsRdx = useSelector((state) => state.collections)
  const [collections, setCollections] = useState([])
  const dispatch = useDispatch()

  useSingleton(() => {
    // will always be triggered once
    dispatch(collectionsActions.getCollections())
  })

  useEffect(() => {
    setCollections(collectionsRdx.items)
  }, [collectionsRdx])

  const selectCollection = (e, el) => {
    e.preventDefault()
    e.stopPropagation()
    const selectedCol = collectionsRdx.items.filter((c) => c.id === el.id)[0]
    if (modalProps.subjectType === "widget") {
      dispatch(widgetsActions.chooseCollection(selectedCol))
    } else {
      dispatch(landingsActions.chooseCollection(selectedCol))
    }
  }

  const getCollectionsList = () => {
    if (!collections) return
    return collections.map((el, i) => {
      return (
        <li key={el.id}>
          <CollectionListItem onClickFn={(e) => selectCollection(e, el)} data={el} color="light" />
        </li>
      )
    })
  }

  return (
    <div className="modal-add-to-collection">
      <div className="modal__title">Choisir une collection</div>
      {collections.length <= 0 ? (
        <div className="modal__grip">
          Vous devez d'abord{' '}
          <Link
            to={`/collections/`}
            onClick={() => {
              dispatch(modalActions.createCollection())
            }}
          >
            créer au moins une collection
          </Link>
        </div>
      ) : (
        <div className="modal__grip">Quelle collection souhaitez-vous associer à votre {modalProps.subjectType} ? </div>
      )}
      <ul className="list-reset">{getCollectionsList()}</ul>
    </div>
  )
}
