import { Select } from 'antd'
import { useParams } from 'react-router-dom'
import Layout from 'components/layout/Layout'
import Button from 'components/buttons/Button'
import Sidebar from 'components/_templates/Sidebar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { templatesActions, modalActions, userActions, accountActions } from '_actions'
import HeaderWithTabs from 'components/_templates/HeaderWithTabs'
import { accountEmpty, userEmpty } from '_services/Utils'

function TemplateSettings() {
  const dispatch = useDispatch()
  const { Option } = Select
  const urlParams = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const [template, setTemplate] = useState({
    id: null,
    name: null,
    domain: null,
    type: "full",
    logo: null,
    ctaWording: null,
    ctaBisWording: null,
    ctaRadius: null,
    ctaColor: null,
    titleColor: null,
    last_updated: '',
    created_at: '',
  })

  const [templateForHeader, setTemplateForHeader] = useState({
    name: null,
  })

  const account = useSelector((state) => state.account)
  const user = useSelector((state) => state.authentication.user)

  useEffect(() => {
    if (accountEmpty(account)) dispatch(accountActions.getSelf())
    if (userEmpty(user)) dispatch(userActions.getSelf(`/templates/${urlParams.id}/settings`))
  }, [user, account, dispatch, urlParams])

  const templatesRdux = useSelector((state) => state.templates.items)

  useEffect(() => {
    if (templatesRdux.length <= 0) {
      loadTemplates()
    } else {
      const myTemplate = templatesRdux.find((template) => template.id === urlParams.id)
      setTemplate({ ...template, ...myTemplate })
      setTemplateForHeader(myTemplate)
    }
    setIsLoading(false)
  }, [templatesRdux])

  const loadTemplates = () => {
    if (isLoading) return
    setIsLoading(true)
    dispatch(templatesActions.getAllTemplates())
  }

  const submitForm = (e) => {
    e.preventDefault()
    setIsLoading(true)
    // destructuring to keep only wanted parameters
    const { created_at, last_updated, ...formData } = template

    // convert array of objects to array of object's id
    dispatch(templatesActions.updateTemplate(formData))
  }

  const deleteTemplate = (e) => {
    dispatch(modalActions.deleteTemplate(template))
  }

  const getBase64 = (e) => {
    const temp = {}
    if (e.currentTarget.files.length > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(e.currentTarget.files[0])

      reader.onload = () => {
        temp.logo = reader.result
        setTemplate({ ...template, ...temp })
      }

      reader.onerror = (error) => {
        console.log('Error: ', error)
        return error
      }
    } else {
      temp.logo = null
      setTemplate({ ...template, ...temp })
    }
  }

  return (
    <Layout className="page page-template-settings">
      <HeaderWithTabs template={templateForHeader} />
      <div className="page__content">
        <div className="page__intro">Modifiez les réglages de votre template et enregistrez vos changements.</div>
        <div className="fluid-grid">
          <form action="" onSubmit={submitForm}>
            <div className="col-12 md-col-8">
              <div className="text-24">Général</div>
              <label htmlFor="name" className="label">
                Nom de votre template
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="text-input"
                placeholder="Nom*"
                defaultValue={template.name}
                required
                onChange={(e) => {
                  const temp = {}
                  temp.name = e.currentTarget.value
                  setTemplate({ ...template, ...temp })
                }}
              />

              <label htmlFor="type" className="label">
                Domaine de template
              </label>
              <Select
                name="domain"
                value={template.domain}
                onChange={(value) => {
                  const temp = {}
                  temp.domain = value
                  setTemplate({ ...template, ...temp })
                }}
              >
                <Option value="homair.colegram.io">homair.colegram.io</Option>
                <Option value="marvilla.colegram.io">marvilla.colegram.io</Option>
              </Select>

              <label htmlFor="type" className="label">
                Type de template
              </label>
              <Select
                name="type"
                value={template.type}
                onChange={(value) => {
                  const temp = {}
                  temp.type = value
                  setTemplate({ ...template, ...temp })
                }}
              >
                <Option value="full">Full</Option>
                <Option value="sidebar">Avec sidebar</Option>
              </Select>

              <label htmlFor="ctaWording" className="label">
                Texte du bouton principal
              </label>
              <input
                type="text"
                name="ctaWording"
                id="ctaWording"
                className="text-input"
                placeholder="Texte du bouton*"
                defaultValue={template.ctaWording}
                onChange={(e) => {
                  const temp = {}
                  temp.ctaWording = e.currentTarget.value
                  setTemplate({ ...template, ...temp })
                }}
              />
              <label htmlFor="ctaBisWording" className="label">
                Texte du bouton produit
              </label>
              <input
                type="text"
                name="ctaBisWording"
                id="ctaBisWording"
                className="text-input"
                placeholder="Texte du bouton*"
                defaultValue={template.ctaBisWording}
                onChange={(e) => {
                  const temp = {}
                  temp.ctaBisWording = e.currentTarget.value
                  setTemplate({ ...template, ...temp })
                }}
              />

              <label htmlFor="ctaRadius" className="label">
                Radius des bouton (ex. 4)
              </label>
              <input
                type="text"
                name="ctaRadius"
                id="ctaRadius"
                className="text-input"
                placeholder="Radius en pixel*"
                defaultValue={template.ctaRadius}
                onChange={(e) => {
                  const temp = {}
                  temp.ctaRadius = e.currentTarget.value
                  setTemplate({ ...template, ...temp })
                }}
              />

              <label htmlFor="ctaColor" className="label">
                Couleur du CTA (ex. #000)
              </label>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <input
                  type="text"
                  name="ctaColor"
                  id="ctaColor"
                  className="text-input"
                  defaultValue={template.ctaColor}
                  placeholder="Code hexa*"
                  onChange={(e) => {
                    const temp = {}
                    temp.ctaColor = e.currentTarget.value
                    setTemplate({ ...template, ...temp })
                  }}
                />
                <div style={{ width: "2rem", height: "2rem", marginLeft: "10px", borderRadius: "5px", backgroundColor: template.ctaColor }}></div>
              </div>

              <label htmlFor="titleColor" className="label">
                Couleur des titres & liens (ex. #000)
              </label>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <input
                  type="text"
                  name="titleColor"
                  id="titleColor"
                  className="text-input"
                  defaultValue={template.titleColor}
                  placeholder="Code hexa*"
                  onChange={(e) => {
                    const temp = {}
                    temp.titleColor = e.currentTarget.value
                    setTemplate({ ...template, ...temp })
                  }}
                />

                <div style={{ width: "2rem", height: "2rem", marginLeft: "10px", borderRadius: "5px", backgroundColor: template.titleColor }}></div>
              </div>

              <label htmlFor="logo" className="label">
                Logo de la page
              </label>
              <input
                type="file"
                name="logo"
                id="logo"
                className="file-input"
                placeholder="Logo de la page*"
                onChange={getBase64}
              />

            </div>
            <div className="col-12 md-col-8">&nbsp;</div>
            <div className="col-12 md-col-8 flex justify-start items-start">
              <Button btnStyle="border" color="grey" addedclass="mr-4" onClickFn={deleteTemplate}>
                {'Supprimer le template'}
              </Button>
              <Button
                type="submit"
                btnStyle="plain"
                loading={isLoading}
                disabled={isLoading}
              >
                Modifier le template
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default TemplateSettings
