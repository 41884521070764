import React from 'react'

function Maintenance() {
  return (
    <div className="page-dashboard">
      <p
        style={{
          textAlign: 'center',
          marginTop: '100px',
        }}
      >
        Site en maintenance
      </p>
    </div>
  )
}

export default Maintenance
