import axiosApi from './api'

export const libraryService = {
  getAllAssets,
  getAsset,
  deleteAssets,
  updateAsset,
  singleDownload,
  massDownload,
  massDownloadCSV,
}

function getParams(options) {
  if (!options) return ''
  let params = ''
  for (const [key, value] of Object.entries(options)) {
    params += `${key}=${value}&`
  }
  return params.slice(0, -1)
}

async function getAllAssets(options) {
  const params = getParams(options)
  const res = await axiosApi.get(`${process.env.REACT_APP_API_URL}/api/asset?${params}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function getAsset(id) {
  const res = await axiosApi.get(`${process.env.REACT_APP_API_URL}/api/asset/${id}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function deleteAssets(assets) {
  const body = { assets: assets.map((a) => a.id) }
  const res = await axiosApi.delete(`${process.env.REACT_APP_API_URL}/api/asset`, { data: JSON.stringify(body) })
  if (res.status === 200) {
    return assets
  } else {
    console.log(res.status, res.statusText)
  }
}

async function updateAsset(item) {
  const res = await axiosApi.put(`${process.env.REACT_APP_API_URL}/api/asset`, JSON.stringify(item))
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function massDownload(ids) {
  const res = await axiosApi.get(`${process.env.REACT_APP_API_URL}/api/asset/download?id=${ids}`, {
    responseType: 'blob',
  })
  if (res.status === 200) {
    return res
  } else {
    console.log(res.status, res.statusText)
  }
}

async function massDownloadCSV(ids) {
  const res = await axiosApi.get(`${process.env.REACT_APP_API_URL}/api/asset/download-csv?id=${ids}`, {
    responseType: 'blob',
  })
  if (res.status === 200) {
    return res
  } else {
    console.log(res.status, res.statusText)
  }
}

async function singleDownload(id) {
  const res = await axiosApi.get(`${process.env.REACT_APP_API_URL}/api/asset/single-download?id=${id}`, {
    responseType: 'blob',
  })
  if (res.status === 200) {
    return res
  } else {
    console.log(res.status, res.statusText)
  }
}
