import { Link, NavLink } from 'react-router-dom'
import { Logo, IconLibrary, IconFeeds, IconWidgets, IconFile, IconHome, IconTemplates, IconLandings } from '_svg'
import { useSelector } from 'react-redux'

export default function MainNav() {
  const account = useSelector((state) => state.account)

  return (
    <nav className="main-nav">
      <Link to="/" className="logo">
        <Logo />
      </Link>
      <ul className="list-reset">
        <li className="">
          <NavLink activeClassName="is-active" to="/" className="main-nav__item" exact>
            <IconHome className="main-nav__icon" />
            <span>Accueil</span>
          </NavLink>
        </li>
        <li className="mt-6">
          <NavLink activeClassName="is-active" to="/feed" className="main-nav__item">
            <IconFeeds className="main-nav__icon" />
            <span>Feed</span>
          </NavLink>
        </li>
        <li className="mt-6">
          <NavLink activeClassName="is-active" to="/messages" className="main-nav__item">
            <IconFeeds className="main-nav__icon" />
            <span>Messages de demande de droits</span>
          </NavLink>
        </li>
        <li className="mt-6">
          <NavLink activeClassName="is-active" to="/library" className="main-nav__item">
            <IconLibrary className="main-nav__icon" />
            <span>Bibliothèque</span>
          </NavLink>
        </li>
        <li className="mt-6">
          <NavLink activeClassName="is-active" to="/collections" className="main-nav__item">
            <IconFile className="main-nav__icon" />
            <span>Collections</span>
          </NavLink>
        </li>
        <li className="mt-6">
          <NavLink activeClassName="is-active" to="/widgets" className="main-nav__item">
            <IconWidgets className="main-nav__icon icon icon--grey-light" />
            <span>Widgets</span>
          </NavLink>
        </li>
        {["360f5f57-785d-49bb-bbdd-e1540ac334a2", "7175a8e1-614c-471c-aaeb-b617a81a35cf", "ef238824-2361-47da-88f2-47b2f4029cae"].includes(account.id) &&
          <>
            <li className="mt-6">
              <NavLink activeClassName="is-active" to="/landing-pages" className="main-nav__item">
                <IconLandings className="main-nav__icon icon icon--grey-light" />
                <span>Landing pages</span>
              </NavLink>
            </li>
            <li className="mt-6">
              <NavLink activeClassName="is-active" to="/templates" className="main-nav__item">
                <IconTemplates className="main-nav__icon icon icon--grey-light" />
                <span>Templates</span>
              </NavLink>
            </li>
          </>}
      </ul>
    </nav>
  )
}
