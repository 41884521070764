import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { noticesConstants } from '_constants/notices.constants'
import { noticesActions } from '_actions/notices.actions'

export default function DeletedFromCollection({ ...noticeProps }) {
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch(noticesActions.closeNotice(noticeProps.id))
    }, noticesConstants.AUTOCLOSE_TIMER)
  }, [dispatch, noticeProps])

  const getMedia = () => {
    if (noticeProps.mediaItem.type === 'VIDEO') {
      return (
        <div className="notice__visual">
          <video controls autoPlay muted loop>
            <source src={noticeProps.mediaItem.path} type="video/mp4" />
          </video>
        </div>
      )
    } else if (noticeProps.mediaItem.type === 'IMAGE') {
      return <img src={noticeProps.mediaItem.path} className="notice__visual" alt="notice thumbnail" />
    }
  }

  return (
    <div className="notice-added-to-collection">
      {getMedia()}
      <div className="content">
        <div className="notice__title">Retrait d'une collection</div>
        <div
          className="notice__msg"
          dangerouslySetInnerHTML={{
            __html: `Le contenu a bien été supprimé de la collection <strong>${noticeProps.collection.name}</strong>.`,
          }}
        ></div>
      </div>
    </div>
  )
}
