import { Select } from 'antd'
import { IconPlus } from '_svg'
import Layout from 'components/layout/Layout'
import Button from 'components/buttons/Button'
import React, { useEffect, useState } from 'react'
import PageHeader from 'components/layout/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import { widgetsActions, modalActions } from '_actions'
import ThemeButton from 'components/buttons/ThemeButton'
import CollectionListItem from 'components/_collections/CollectionListItem'

function WidgetCreation() {
  const dispatch = useDispatch()
  const { Option } = Select
  const alert = useSelector((state) => state.alert)
  const [isLoading, setIsLoading] = useState(false)
  const [widget, setWidget] = useState({
    name: null,
    limit: 10,
    status: '0',
    theme: null,
    collections: [],
    sort_by: 'random',
    font: null,
    font_url: null,
    color1: null,
    color2: null,
  })

  const widgetCreationCollection = useSelector((state) => state.widgets.create.collection)

  useEffect(() => {
    const temp = {}
    if (!widgetCreationCollection.id) return
    temp.collections = [widgetCreationCollection.id]
    setWidget({ ...widget, ...temp })
  }, [widgetCreationCollection])

  useEffect(() => {
    if (alert.message !== '') setIsLoading(false)
  }, [alert])

  const submitForm = (e) => {
    e.preventDefault()
    setIsLoading(true)
    dispatch(widgetsActions.createWidget(widget))
  }

  const chooseCollection = (e) => {
    dispatch(modalActions.chooseCollection("widget"))
  }

  const changeTheme = (value) => {
    setWidget((widget) => {
      return { ...widget, ...{ theme: value } }
    })
  }

  const getCollection = () => {
    if (widgetCreationCollection.id) return <CollectionListItem data={widgetCreationCollection} />
  }

  return (
    <Layout className="page page-widget-creation">
      <PageHeader data={[{ label: 'Widgets', url: '/widgets' }, { label: 'Créer un widget' }]} />
      <div className="page__content">
        <div className="page__intro">
          Configurez votre widget en remplissant les champs ci dessous et suivez les instructions d'implémentation sur
          votre canal de distribution.
        </div>
        <div className="fluid-grid">
          <form action="" onSubmit={submitForm}>
            <div className="row mt-20">
              <div className="col-12 md-col-6">
                <div className="text-24">Général</div>
                <label htmlFor="name" className="label">
                  Nom de votre widget
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="text-input"
                  placeholder="Nom*"
                  required
                  onChange={(e) => {
                    const temp = {}
                    temp.name = e.currentTarget.value
                    setWidget({ ...widget, ...temp })
                  }}
                />

                <label htmlFor="limit" className="label">
                  Nombre de medias maximum
                </label>
                <Select
                  name="limit"
                  defaultValue="10"
                  onChange={(value) => {
                    const temp = {}
                    temp.limit = value
                    setWidget({ ...widget, ...temp })
                  }}
                >
                  <Option value="10">10</Option>
                  <Option value="20">20</Option>
                  <Option value="50">50</Option>
                </Select>

                <label htmlFor="theme" className="label">
                  Thème du widget
                </label>
                <div className="flex">
                  <ThemeButton
                    value="slider"
                    label="Slider"
                    onChangeTheme={changeTheme}
                    isDefaultChecked={widget.theme === 'slider'}
                  />
                  <ThemeButton
                    value="wall"
                    label="Mosaïque"
                    onChangeTheme={changeTheme}
                    isDefaultChecked={widget.theme === 'wall'}
                  />
                </div>

                <label htmlFor="width" className="label">
                  Largeur du widget (ex. 80%, 600px)
                </label>
                <input
                  type="text"
                  name="width"
                  id="width"
                  className="text-input"
                  placeholder="Largeur*"
                  onChange={(e) => {
                    const temp = {}
                    temp.width = e.currentTarget.value
                    setWidget({ ...widget, ...temp })
                  }}
                />

                <label htmlFor="height" className="label">
                  Hauteur du widget (ex. 50%, 300px)
                </label>
                <input
                  type="text"
                  name="height"
                  id="height"
                  className="text-input"
                  placeholder="Hauteur*"
                  onChange={(e) => {
                    const temp = {}
                    temp.height = e.currentTarget.value
                    setWidget({ ...widget, ...temp })
                  }}
                />

              <label htmlFor="font" className="label">
                  Font Google (nom)
                </label>
                <input
                  type="text"
                  name="font"
                  id="font"
                  className="text-input"
                  defaultValue={widget.font}
                  placeholder="Police*"
                  onChange={(e) => {
                    const temp = {}
                    temp.font = e.currentTarget.value
                    setWidget({ ...widget, ...temp })
                  }}
                />

              <label htmlFor="font_url" className="label">
                  Url font Google
                </label>
                <input
                  type="text"
                  name="font_url"
                  id="font_url"
                  className="text-input"
                  defaultValue={widget.font_url}
                  placeholder="Police*"
                  onChange={(e) => {
                    const temp = {}
                    temp.font_url = e.currentTarget.value
                    setWidget({ ...widget, ...temp })
                  }}
                />
                <label htmlFor="color1" className="label">
                  Couleur Primaire
                </label>
<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <input
                  type="text"
                  name="color1"
                  id="color1"
                  className="text-input"
                  defaultValue={widget.color1}
                  placeholder="Code hexa*"
                  onChange={(e) => {
                    const temp = {}
                    temp.color1 = e.currentTarget.value
                    setWidget({ ...widget, ...temp })
                  }}
                />
                <div style={{ width: "2rem", height: "2rem", marginLeft: "10px", borderRadius: "5px", backgroundColor: widget.color1 }}></div>
                </div>
              <label htmlFor="color2" className="label">
                  Couleur Secondaire
                </label>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <input
                  type="text"
                  name="color2"
                  id="color2"
                  className="text-input"
                  defaultValue={widget.color2}
                  placeholder="Code hexa*"
                  onChange={(e) => {
                    const temp = {}
                    temp.color2 = e.currentTarget.value
                    setWidget({ ...widget, ...temp })
                  }}
                />

                <div style={{ width: "2rem", height: "2rem", marginLeft: "10px", borderRadius: "5px", backgroundColor: widget.color2 }}></div>
                </div>

                <div className="text-24 mt-10">Source du widget</div>

                <label htmlFor="sorting" className="label">
                  Trier les medias par
                </label>
                <Select
                  name="sortBy"
                  defaultValue={widget.sort_by}
                  onChange={(value) => {
                    const temp = {}
                    temp.sort_by = value
                    setWidget({ ...widget, ...temp })
                  }}
                >
                  <Option value="random">Ordre aléatoire</Option>
                  <Option value="created_at">Date de création</Option>
                  {/* <Option value="timestamp">Date du post</Option> */}
                  <Option value="commentsCount">Nombre de commentaires</Option>
                  <Option value="likeCount">Nombre de likes</Option>
                </Select>
                <label htmlFor="locale" className="label">
                  Remontée
                </label>
                <Select name="source" defaultValue="by_collection">
                  <Option value="by_collection">Par collection</Option>
                </Select>
                <div className="selection-wrapper mt-6">
                  <div className="selection-wrapper-content">{getCollection()}</div>
                  <div className="selection-cta mt-6" onClick={chooseCollection}>
                    <div className="picto">
                      <IconPlus className="icon" />
                    </div>
                    {widget.collections.length > 0 ? 'changer de collection' : 'Lier à une collection'}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-10">
              <div className="col-12 md-col-6">
                &nbsp;
                {alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
              </div>
              <div className="col-12 md-col-6 flex flex-col justify-end items-end">
                <Button
                  type="submit"
                  btnStyle="plain"
                  loading={isLoading}
                  color={isLoading ? 'grey' : 'red'}
                  disabled={widget.collections.length <= 0 || isLoading}
                >
                  Créer le widget
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default WidgetCreation
