import React from 'react'
import { useDispatch } from 'react-redux'
import CheckBox from 'components/filters/CheckBox'
import { modalActions } from '_actions/modals.actions'
import { IconEditColor, IconDelete, IconJoin, IconDownload } from '_svg'

export default function MediasCardItem({ data, selectFunction, isSelected = false, downloadFunction, deleteFunction }) {
  const dispatch = useDispatch()

  const handleSelection = (e) => {
    selectFunction(data, e.checked)
  }

  const handleDetails = (e) => {
    dispatch(modalActions.showMediaDetails([data]))
  }

  const handleDelete = (e) => {
    deleteFunction([data])
    // dispatch(modalActions.deleteFromLibrary([data]))
  }

  const handleAddToCol = (e) => {
    dispatch(modalActions.addToCollection([data]))
  }

  const handleDownload = (e) => {
    downloadFunction([data])
  }

  const handleLinkProduct = (e) => {
    console.log(data)
    dispatch(modalActions.handleLinkProduct([data]))
  }

  const getMedia = () => {
    if (data.type === 'VIDEO') {
      return getVideo(data.path)
    } else if (data.type === 'IMAGE') {
      return getImage(data.path)
    }
  }

  const getImage = (media_url) => {
    return (
      <div className="thumb" style={{ backgroundImage: `url('${media_url}')` }}>
        <div className="hover">{getCTAs()}</div>
        {getCheckBox()}
      </div>
    )
  }

  const getVideo = (media_url) => {
    return (
      <div className="thumb">
        <video controls muted loop>
          <source src={media_url} type="video/mp4" />
        </video>
        <div className="hover">{getCTAs()}</div>
        {getCheckBox()}
      </div>
    )
  }

  const getCheckBox = () => {
    return <CheckBox name="actions" callback={handleSelection} isChecked={isSelected} />
  }

  const getCTAs = () => {
    return (
      <div className="ctas ctas__library">
        <div
          className="card-cta"
          onClick={(e) => {
            handleLinkProduct()
          }}
        >
          <IconEditColor className="icon icon--white" />
        </div>
        <div
          className="card-cta"
          onClick={(e) => {
            handleDelete()
          }}
        >
          <IconDelete className="icon icon--orange" />
        </div>
        <a
          className="card-cta"
          href={data.path}
          target="_blank"
          onClick={handleDownload}
          download="colegram-content"
          rel="noreferrer"
        >
          <IconDownload className="icon icon--orange" />
        </a>
        <div
          className={`card-cta card-cta--orange`}
          onClick={(e) => {
            handleAddToCol()
          }}
        >
          <IconJoin className="icon icon--white" />
        </div>
      </div>
    )
  }

  return (
    <div className="asset-card">
      {getMedia()}
      <div className="content mt-4">
        <div className="description">{data.caption}</div>
        <div className="media-inner-ctas mt-4">
          <button
            onClick={(e) => {
              handleDetails(e)
            }}
          >
            voir le détail
          </button>
        </div>
      </div>
    </div>
  )
}
