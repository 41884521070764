import React from 'react'
import { Logo } from '_svg'
import { Link } from 'react-router-dom'

export default function HeaderShowcase() {
  return (
    <header className="header-showcase">
      <Link to="/">
        <Logo />
      </Link>
    </header>
  )
}
