import { history } from '_helpers'
import { alertActions, userActions } from '_actions'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PrivateRoute } from 'components/PrivateRoute'
import { Router, Route, Switch, Redirect } from 'react-router-dom'

import {
  Login,
  Feeds,
  Users,
  AskForm,
  Library,
  Widgets,
  Landings,
  Templates,
  Profile,
  Messages,
  NotFound,
  Dashboard,
  Collections,
  Maintenance,
  FeedContents,
  FeedSettings,
  FeedCreation,
  AccountConfig,
  AccountPlugins,
  WidgetSettings,
  WidgetCreation,
  LandingSettings,
  LandingCreation,
  TemplateSettings,
  TemplateCreation,
  ChangePassword,
  ForgottenPassword,
  CollectionsContents,
  FrontLanding
} from 'components/pages'
import { userEmpty } from '_services/Utils'

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    // clear alert on location change
    const unlisten = history.listen(() => dispatch(alertActions.clear()))

    return unlisten
  }, [dispatch])

  const user = useSelector((state) => state.authentication.user)

  useEffect(() => {
    if (user && userEmpty(user)) dispatch(userActions.getSelf(history.location.pathname))
  }, [dispatch, user])

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/account/profile" component={Profile} />
          <PrivateRoute exact path="/account/users" component={Users} />
          <PrivateRoute exact path="/account/config" component={AccountConfig} />
          <PrivateRoute exact path="/account/plugins" component={AccountPlugins} />
          <PrivateRoute exact path="/feed" component={Feeds} />
          <PrivateRoute exact path="/feed/create" component={FeedCreation} />
          <PrivateRoute exact path="/feed/:id/contents" component={FeedContents} />
          <PrivateRoute exact path="/feed/:id/settings" component={FeedSettings} />
          <PrivateRoute exact path="/messages" component={Messages} />
          <PrivateRoute exact path="/library" component={Library} />
          <PrivateRoute exact path="/collections" component={Collections} />
          <PrivateRoute exact path="/collections/:id" component={CollectionsContents} />
          <PrivateRoute exact path="/widgets" component={Widgets} />
          <PrivateRoute exact path="/widgets/create" component={WidgetCreation} />
          <PrivateRoute exact path="/widgets/:id/settings" component={WidgetSettings} />
          <PrivateRoute exact path="/landing-pages" component={Landings} />
          <PrivateRoute exact path="/landing-pages/create" component={LandingCreation} />
          <PrivateRoute exact path="/landing-pages/:id/settings" component={LandingSettings} />
          <PrivateRoute exact path="/templates" component={Templates} />
          <PrivateRoute exact path="/templates/create" component={TemplateCreation} />
          <PrivateRoute exact path="/templates/:id/settings" component={TemplateSettings} />
          <Route path="/login" component={Login} />
          <Route exact path="/maintenance" component={Maintenance} />
          <Route path="/password/:hash" component={ChangePassword} />
          <Route path="/password" component={ForgottenPassword} />
          <Route path="/form/:hash" component={AskForm} />
          <Route path="/landing/:slug" component={FrontLanding} />
          <Redirect from="*" to="/" />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  )
}

export default App
