import { useRef } from 'react'

export default function StatusRadio({ value, label, onChangeStatus, isDefaultChecked = false }) {
  const radio = useRef(null)

  const handleChange = (e) => {
    if (radio.current.checked) onChangeStatus(value.toString())
  }

  return (
    <div className="status-radio">
      <label className="custom-status">
        <span className="custom-status__label">
          <div className="status">
            <div className={`status-indic status-indic--${value}`}></div>
            {label}
          </div>
        </span>
        <input
          required
          ref={radio}
          type="radio"
          name="status"
          title={label}
          value={value}
          onChange={handleChange}
          checked={isDefaultChecked}
        />
        <span aria-hidden="true" className="custom-status__icon"></span>
        <div className="custom-status__bg"></div>
      </label>
    </div>
  )
}
