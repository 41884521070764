import React from 'react'
import { IconCrossSmall } from '_svg'
import { useDispatch } from 'react-redux'
import { noticesActions } from '_actions'

export default function Notice({ noticeId, children }) {
  const dispatch = useDispatch()
  const close = () => {
    dispatch(noticesActions.closeNotice(noticeId))
  }
  return (
    <div className="notice ">
      <div className="notice__inner">{children}</div>
      <button
        className="notice__close-button"
        onClick={() => {
          close()
        }}
      >
        <IconCrossSmall className="icon" />
      </button>
    </div>
  )
}
