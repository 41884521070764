import { IconDiapo } from '_svg'
import { useParams } from 'react-router-dom'
import Button from 'components/buttons/Button'
import { askService } from '_services/ask.service'
import { Swiper, SwiperSlide } from 'swiper/react'
import useSwiperRef from 'components/utils/useSwiperRef'
import RadiosModule from 'components/filters/RadiosModule'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import CheckBoxesModule from 'components/filters/CheckBoxesModule'
import React, { useEffect, useLayoutEffect, useState } from 'react'

import translations from './translations/AskFormTranslations.json'
import FileUpload from 'components/buttons/FileUpload'
import slugify from 'slugify'

SwiperCore.use([Navigation, Pagination])

const ASK_DECLINED = -1
const ASK_PENDING = 0
const ASK_GRANTED = 1

function AskForm() {
  const urlParams = useParams()
  const [config, setConfig] = useState(null)
  const [datas, setDatas] = useState(null)
  const [logo, setLogo] = useState(null)
  const [folder, setFolder] = useState(null)
  const [paginationEl, paginationRef] = useSwiperRef()
  const [isAgree, setIsAgree] = useState(true)
  const [allCheckboxesChecked, setAllCheckboxesChecked] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [isSubmitingFiles, setIsSubmitingFiles] = useState(false)
  const [filesUploaded, setFilesUploaded] = useState(false)
  const [fileUploadError, setFileUploadError] = useState(false)
  const [status, setStatus] = useState(ASK_PENDING)

  const [files, setFiles] = useState([])

  const trads = translations[datas?.lang] || translations['fr']

  const checkboxes = [
    { label: trads.checkbox1, value: 'item1' },
    { label: trads.checkbox2, value: 'item2' },
    { label: trads.checkbox3, value: 'item3' },
    { label: trads.checkbox4, value: 'item4' },
    {
      label: (
        <div className="text-label" style={{ color: config?.formTextColor ?? "#000" }}>
          {trads.checkbox5}
          <a className="link--underline" rel="noopener noreferrer" target="_blank" href="https://colegram.io/COLEGRAM-PrivacyPolicy-v02-07032023.pdf">
            {trads.privacyPolicyLabel}
          </a>
        </div>
      ),
      value: 'item5',
    },
    {
      label: (
        <div className="text-label" style={{ color: config?.formTextColor ?? "#000" }}>
          {trads.checkbox6}
          <a className="link--underline" rel="noopener noreferrer" target="_blank" href={`/pdf/${folder}/licence-et-autorisation-droit-a-limage.pdf`}>
            {trads.licenceLabel}
          </a>
        </div>
      ),
      value: 'item6',
    },
  ]

  useLayoutEffect(() => {
    askService.verify(urlParams.hash).then((result) => {
      if (result.success) {
        setConfig(result.config)
        setDatas(result.data)
        setFolder(slugify(result.client, {
          lower: true,
          strict: true,
        }))
        setLogo(result.logo)
      } else {
        window.location = 'https://colegram.io'
      }
    })
  }, [urlParams])

  useEffect(() => {
    if (datas && datas.status) setStatus(parseInt(datas.status))
  }, [datas])

  const getMedia = () => {
    if (!datas) return null
    if (datas.mediasType.length > 1) {
      // CAROUSEL ALBUM
      const medias = datas.mediasUrl
      const getSwiperMedia = () => {
        return medias.map((el, i) => {
          if (datas.mediasType[i] === 'VIDEO') {
            return (
              <SwiperSlide key={i}>
                <div className="visual">
                  <video controls>
                    <source src={`https://instagram.colegram.workers.dev/?feed_id=${el.feedId}&video_url=${btoa(el)}`} type="video/mp4" />
                  </video>
                </div>
              </SwiperSlide>
            )
          } else {
            // Only one image
            return (
              <SwiperSlide key={i}>
                <div className="visual" style={{ backgroundImage: `url('https://instagram.colegram.workers.dev/?feed_id=${el.feedId}&image_url=${btoa(el)}')` }}></div>
              </SwiperSlide>
            )
          }
        })
      }
      return (
        <>
          <Swiper spaceBetween={0} slidesPerView={1} navigation pagination={{ el: paginationEl }} threshold={30}>
            <IconDiapo className="icon-diapo" />
            {getSwiperMedia()}
          </Swiper>
          <div ref={paginationRef} />
        </>
      )
    } else if (datas.mediasType[0] === 'VIDEO') {
      return (
        <div className="visual">
          <video controls autoPlay muted loop>
            <source src={`https://instagram.colegram.workers.dev/?feed_id=${datas.feedId}&video_url=${btoa(datas.mediasUrl[0])}`} type="video/mp4" />
          </video>
        </div>
      )
    } else if (datas.mediasType[0] === 'IMAGE') {
      try {
        window.atob(datas.mediasUrl[0].substr(23))
        return <div className="visual" style={{ backgroundImage: `url('${datas.mediasUrl[0]}')` }} />
      } catch (e) {
        return (
          <div
            className="visual"
            style={{
              backgroundImage: `url('https://instagram.colegram.workers.dev/?feed_id=${datas.feedId}&image_url=${btoa(datas.mediasUrl[0])}')`,
            }}
          />
        )
      }
    }
  }

  const handleCheckoxesChange = (checkboxesValues) => {
    const allChecked = Object.values(checkboxesValues).every((el) => el === true)
    setAllCheckboxesChecked(allChecked)
  }
  const handleCheck = (value) => {
    setIsAgree(value === 'true')
  }

  const changeMyMind = (isOk) => {
    setIsAgree(isOk)
    submitForm(isOk)
  }

  const submitForm = (changedValue = null) => {
    setIsSubmiting(true)
    const selectedStatus = changedValue !== null ? changedValue : isAgree

    askService.submitForm(urlParams.hash, { status: selectedStatus ? ASK_GRANTED : ASK_DECLINED }).then((result) => {
      if (result.success) {
        setStatus(selectedStatus ? ASK_GRANTED : ASK_DECLINED)
      }
      setIsSubmiting(false)
    })
  }

  const handleFilesSubmit = async () => {
    setIsSubmitingFiles(true)

    const res = await askService.uploadFiles(urlParams.hash, files)

    setIsSubmitingFiles(false)

    if (res.success) {
      setFiles([])
      setFilesUploaded(true)
    } else {
      setFileUploadError(true)
    }
  }

  const medias = getMedia()

  return (
    <div className="ask-form" style={{ backgroundColor: config?.formBGColor ?? '#FFF' }}>
      {datas && (
        <>
          {logo && <img src={logo} className="logo block mx-auto" alt="" />}

          <>
            <div className="block text-center mt-8">
              <span style={{ marginTop: "3rem", fontSize: "2rem", color: config?.formTitleColor ?? "#000" }}>
                {status === ASK_PENDING && trads.hello}
                {status === ASK_GRANTED && trads.thanks.title}
                {status === ASK_DECLINED && trads.tooBad.title}
              </span>
            </div>
            <div className="gen-text my-4" style={{ color: config?.formTextColor ?? "#000" }}>
              {status === ASK_PENDING && datas.message}
              {status === ASK_GRANTED && trads.thanks.text}
              {status === ASK_DECLINED && trads.tooBad.text}
            </div>
            {status === ASK_GRANTED && datas?.customVars?.discountCode !== null && datas?.customVars?.discountCode !== "" ?
              <div className="gen-text my-4" style={{ color: config?.formTextColor ?? "#000" }}>{trads.thanks.discountCode}<b>{datas.customVars.discountCode}</b></div>
              : <></>}
            {status === ASK_GRANTED && datas?.customVars?.legalsUrl !== null && datas?.customVars?.legalsUrl !== "" ?
              <div><a href={datas.customVars.legalsUrl} target={"_blank"} className="gen-text my-4" style={{ color: config?.formTextColor ?? "#000" }}>{"> "}{trads.thanks.legals}</a></div>
              : <></>}
          </>

          {status !== ASK_GRANTED && medias}

          {status === ASK_PENDING && (
            <>
              <div className="second-title mt-6" style={{ color: config?.formTitleColor ?? "#000" }}>{trads.ask.title}</div>
              <div className="gen-text my-4" style={{ color: config?.formTextColor ?? "#000" }}>{trads.ask.text}</div>
              <RadiosModule
                name="accept"
                callback={handleCheck}
                data={[
                  { label: trads.options.yes, value: true },
                  { label: trads.options.no, value: false },
                ]}
                textColor={config?.formTextColor ?? "#000"}
                noPurple={true}
              />
              {isAgree === true && (
                <div className="mt-6 gen-text">
                  <CheckBoxesModule
                    items={checkboxes}
                    title={trads.checkboxsHeading}
                    callback={handleCheckoxesChange}
                    customClass="custom-checkbox--light"
                    textColor={config?.formTextColor ?? "#000"}
                    noPurple={true}
                  />
                </div>
              )}
              <div className="flex justify-center">
                <Button
                  addedclass="mt-6"
                  loading={isSubmiting}
                  onClickFn={() => submitForm()}
                  disabled={isSubmiting || (!allCheckboxesChecked && isAgree)}
                  customStyle={{ backgroundColor: config?.formBtnColor ?? '#E110DB', color: config?.formBtnTextColor ?? "#fff" }}
                >
                  {trads.btnSend}
                </Button>
              </div>
            </>
          )}
          {status === ASK_DECLINED && (
            <>
              <div className="second-title mt-6" style={{ color: config?.formTitleColor ?? "#000" }}>{trads.disagree.title}</div>
              <div className="gen-text my-4" style={{ color: config?.formTextColor ?? "#000" }}>{trads.disagree.text}</div>

              <div className="mt-6 gen-text">
                <CheckBoxesModule
                  items={checkboxes}
                  title={trads.disagree.switchBtn}
                  callback={handleCheckoxesChange}
                  customClass="custom-checkbox--light"
                  textColor={config?.formTextColor ?? "#000"}
                  noPurple={true}
                />
              </div>

              <div className="flex justify-center">
                <Button
                  addedclass="mt-6"
                  loading={isSubmiting}
                  onClickFn={() => changeMyMind(true)}
                  disabled={isSubmiting || !allCheckboxesChecked}
                  customStyle={{ backgroundColor: config?.formBtnColor ?? '#E110DB', color: config?.formBtnTextColor ?? "#fff" }}
                >
                  {trads.disagree.switchBtn}
                </Button>
              </div>
            </>
          )}
          {status === ASK_GRANTED && (
            <>
              <div className="second-title mt-6 text-center" style={{ color: config?.formTitleColor ?? "#000" }}>{trads.upload.title}</div>
              <p className="gen-text my-4" style={{ color: config?.formTextColor ?? "#000" }}>{trads.upload.text}</p>

              {filesUploaded && (
                <p className="text-center text-gradient text-gradient--H w-full mt-2" style={{ color: config?.formTextColor ?? "#000" }}>{trads.upload.success}</p>
              )}
              {!filesUploaded && (
                <>
                  <FileUpload
                    files={files}
                    setFiles={setFiles}
                    label={trads.upload.choose}
                    subLabel={trads.upload.subLabel}
                    maxNumberOfFiles={datas.mediasType.length}
                    backgroundColor={config?.formBtnColor ?? '#E110DB'}
                    maxFileSizeErrorMessage={trads.upload.maxFileSizeErrorMessage}
                    maxLimitationErrorMessage={trads.upload.maxLimitationErrorMessage}
                  />

                  {fileUploadError && (
                    <p className="gen-text text-center my-4" style={{ color: '#ff4d4f' }}>
                      {trads.upload.error}
                    </p>
                  )}

                  <div className="flex justify-center">
                    <Button
                      addedclass="mt-6"
                      loading={isSubmitingFiles}
                      disabled={files.length === 0}
                      onClickFn={handleFilesSubmit}
                      customStyle={{ backgroundColor: config?.formBtnColor ?? '#E110DB', color: config?.formBtnTextColor ?? "#fff" }}
                    >
                      {trads.upload.submit}
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
          <div className="footer-nav flex justify-center">
            <a rel="noopener noreferrer" target="_blank" href="https://www.colegram.io/cgu" style={{ color: config?.formTextColor ?? "#000" }}>
              {trads.footer.legals}
            </a>
            <a rel="noopener noreferrer" target="_blank" href="https://www.colegram.io/contact" style={{ color: config?.formTextColor ?? "#000" }}>
              {trads.footer.contact}
            </a>
          </div>
        </>
      )}
    </div>
  )
}

export default AskForm
