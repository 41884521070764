import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { userActions, modalActions } from '_actions'
import Button from 'components/buttons/Button'

export default function ChangePassword({ ...modalProps }) {
  const [passwords, setPasswords] = useState({
    old_password: '',
    new_password: '',
    confirm_password: '',
  })
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { old_password, new_password, confirm_password } = passwords
  const dispatch = useDispatch()

  useEffect(() => {
    if (modalProps.error) setErrorMsg(modalProps.error) // Colegram@1
    setIsLoading(false)
  }, [modalProps])

  const submitForm = (e) => {
    e.preventDefault()
    setErrorMsg('')
    if (!new_password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/)) {
      /*
        Password must have at least one digit, one special char, one upper and lowercase. higher than 8 chars
        */
      setErrorMsg(
        'Votre nouveau mot de passe doit contenir au moins 8 caractères, un chiffre, une majuscule, une minuscule et un caractère spécial.'
      )
    } else if (new_password !== confirm_password) {
      setErrorMsg("La confirmation du nouveau mot de passe n'est pas correcte.")
    } else {
      setIsLoading(true)
      dispatch(
        userActions.changePassword({
          id: modalProps.userId,
          old_password: passwords.old_password,
          new_password: passwords.new_password,
        })
      )
    }
  }

  function handleChange(e) {
    const { name, value } = e.target
    setPasswords((inputs) => ({ ...inputs, [name]: value }))
    setErrorMsg('')
  }

  const closeModal = () => {
    dispatch(modalActions.closeModal())
  }

  return (
    <div className="modal-create-message">
      <div className="modal__title">Changer de mot de passe</div>

      <form className="flex flex-col items-start" onSubmit={submitForm}>
        <div className="label label--med">Ancien mot de passe</div>
        <input
          type="password"
          name="old_password"
          className="text-input"
          defaultValue={old_password}
          placeholder="Ancien mot de passe*"
          required
          onChange={handleChange}
        />
        <div className="label label--med">Nouveau mot de passe</div>
        <input
          type="password"
          name="new_password"
          className="text-input"
          defaultValue={new_password}
          placeholder="Nouveau mot de passe*"
          required
          onChange={handleChange}
        />
        <div className="label label--med">Confirmation du nouveau mot de passe</div>
        <input
          type="password"
          name="confirm_password"
          className="text-input"
          defaultValue={confirm_password}
          placeholder="Confirmation du nouveau mot de passe*"
          required
          onChange={handleChange}
        />
        <div className="flex center gen-text gen-text--red">{errorMsg}</div>
        <div className="flex justify-between">
          <Button
            type="submit"
            btnStyle="plain"
            addedclass="mr-4"
            loading={isLoading}
            color={isLoading ? 'grey' : 'red'}
          >
            Envoyer
          </Button>
          <Button btnStyle="border" color="grey-lighter" onClickFn={closeModal}>
            Annuler
          </Button>
        </div>
      </form>
    </div>
  )
}
