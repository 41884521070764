export default class Utils {
  static logout() {
    localStorage.removeItem('user')
    window.location.reload(true)
  }

  static userEmpty(user) {
    if (!user) return true
    const keysOfUser = Object.keys(user).filter(
      (value) => !['refresh_token', 'token', 'has_facebook_credentials'].includes(value)
    )

    return keysOfUser.length === 0
  }

  static accountEmpty(account) {
    return Object.keys(account).length === 0
  }
}

const userEmpty = Utils.userEmpty
const accountEmpty = Utils.accountEmpty

export { userEmpty, accountEmpty }
