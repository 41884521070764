import { messagesConstants } from '_constants'
const initialState = []

export function messages(state = initialState, action) {
  switch (action.type) {
    case messagesConstants.GET_ALL:
      return [...state, ...action.messages.slice(0, 3)]

    case messagesConstants.CREATE:
      return [...state, ...[action.message]]

    case messagesConstants.DELETE:
      return state.filter((message) => {
        // select concerned message
        if (message.id === action.id) return false
        return true
      })

    case messagesConstants.UPDATE:
      return state.map((el) => {
        if (el.id === action.message.id) return action.message
        else return el
      })
    default:
      return state
  }
}
