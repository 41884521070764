import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accountActions } from '_actions'
import Button from 'components/buttons/Button'
import Layout from 'components/layout/Layout'
import HeaderWithTabs from 'components/_account/HeaderWithTabs'
import HashtagsList from 'components/filters/HashtagsList'
import { accountEmpty } from '_services/Utils'
import { PLAN } from '_constants'

function AccountConfig() {
  const dispatch = useDispatch()

  const account = useSelector((state) => state.account)

  const [isLoading, setIsLoading] = useState(false)
  const [config, setConfig] = useState({})
  const [instagramUsernames, setInstagramUsernames] = useState([])
  const [instagramHashtags, setInstagramHashtags] = useState([])
  const [instagramBannedWords, setInstagramBannedWords] = useState([])

  useEffect(() => {
    if (accountEmpty(account)) dispatch(accountActions.getSelf())
    if (account.config) {
      setConfig(account.config)
    }
    if (account.instagram_usernames) {
      setInstagramUsernames(account.instagram_usernames)
    }
    if (account.instagram_hashtags) {
      setInstagramHashtags(account.instagram_hashtags)
    }
    if (account.instagram_bannedWords) {
      setInstagramBannedWords(account.instagram_bannedWords)
    }
  }, [account, dispatch])

  const submitForm = (e) => {
    e.preventDefault()
    dispatch(accountActions.updateConfig({ config, instagram_usernames: instagramUsernames, instagram_hashtags: instagramHashtags, instagram_bannedWords: instagramBannedWords }))
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }

  return (
    <Layout className="page page-account-profile">
      <HeaderWithTabs />
      <div className="page__content">
        <div className="fluid-grid">
          <form onSubmit={submitForm}>
            <div className="row">
              <div className="col-12 md-col-6">
                <div className="text-24 mt-10">Instagram</div>
                <HashtagsList title={"Vos noms d'utilisateur"} theme={'dark'} preselection={instagramUsernames} callback={usernames => {
                  setInstagramUsernames(usernames)
                }} />
                {PLAN.ULTRA === account.plan && (
                  <HashtagsList title={"Vos hashtags"} theme={'dark'} preselection={instagramHashtags} callback={hashtags => {
                    setInstagramHashtags(hashtags)
                  }} />
                )}
                <HashtagsList title={"Mots bannis par défaut"} theme={'dark'} preselection={instagramBannedWords} callback={words => {
                  setInstagramBannedWords(words)
                }} />
              </div >
              <div className="col-12 md-col-6">
                <div className="text-24 mt-10">Formulaire de cession de droits</div>
                <div className="mt-3">
                  <label htmlFor="lastname" className="label">
                    Couleur de fond
                  </label>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <input
                      type="text"
                      name="lastname"
                      className="text-input"
                      value={config.formBGColor}
                      placeholder="Couleur (Hex)"
                      required
                      onChange={(e) => {
                        setConfig({
                          ...config,
                          formBGColor: e.target.value,
                        })
                      }}
                    />
                    <div style={{ width: "2rem", height: "2rem", marginLeft: "10px", borderRadius: "5px", backgroundColor: config.formBGColor }}></div>
                  </div>
                </div>
                <div className="mt-3">
                  <label htmlFor="lastname" className="label">
                    Couleur des titres
                  </label>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <input
                      type="text"
                      name="lastname"
                      className="text-input"
                      value={config.formTitleColor}
                      placeholder="Couleur (Hex)"
                      required
                      onChange={(e) => {
                        setConfig({
                          ...config,
                          formTitleColor: e.target.value,
                        })
                      }}
                    />
                    <div style={{ width: "2rem", height: "2rem", marginLeft: "10px", borderRadius: "5px", backgroundColor: config.formTitleColor }}></div>
                  </div>
                </div>
                <div className="mt-3">
                  <label htmlFor="lastname" className="label">
                    Couleur du texte
                  </label>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <input
                      type="text"
                      name="lastname"
                      className="text-input"
                      value={config.formTextColor}
                      placeholder="Couleur (Hex)"
                      required
                      onChange={(e) => {
                        setConfig({
                          ...config,
                          formTextColor: e.target.value,
                        })
                      }}
                    />
                    <div style={{ width: "2rem", height: "2rem", marginLeft: "10px", borderRadius: "5px", backgroundColor: config.formTextColor }}></div>
                  </div>
                </div>
                <div className="mt-3">
                  <label htmlFor="lastname" className="label">
                    Couleur des boutons
                  </label>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <input
                      type="text"
                      name="lastname"
                      className="text-input"
                      value={config.formBtnColor}
                      placeholder="Couleur (Hex)"
                      required
                      onChange={(e) => {
                        setConfig({
                          ...config,
                          formBtnColor: e.target.value,
                        })
                      }}
                    />
                    <div style={{ width: "2rem", height: "2rem", marginLeft: "10px", borderRadius: "5px", backgroundColor: config.formBtnColor }}></div>
                  </div>
                </div>
                <div className="mt-3">
                  <label htmlFor="lastname" className="label">
                    Couleur du texte des boutons
                  </label>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <input
                      type="text"
                      name="lastname"
                      className="text-input"
                      value={config.formBtnTextColor}
                      placeholder="Couleur (Hex)"
                      required
                      onChange={(e) => {
                        setConfig({
                          ...config,
                          formBtnTextColor: e.target.value,
                        })
                      }}
                    />
                    <div style={{ width: "2rem", height: "2rem", marginLeft: "10px", borderRadius: "5px", backgroundColor: config.formBtnTextColor }}></div>
                  </div>
                </div>
              </div>
            </div >
            <div className="row mt-10">
              <div className="col-12 flex">
                <Button
                  type="submit"
                  btnStyle="plain"
                  loading={isLoading}
                  onClickFn={submitForm}
                  color={isLoading ? 'grey' : 'red'}
                >
                  Sauvegarder les modifications
                </Button>
              </div>
            </div>
          </form >
        </div >
      </div >
    </Layout >
  )
}

export default AccountConfig
