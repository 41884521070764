import PageHeader from 'components/layout/PageHeader'

export default function HeaderWithTabs({ template }) {
  return (
    <PageHeader
      data={[{ label: 'Templates', url: '/templates' }, { label: template ? template.name : '' }]}
      // tabs={[
      //   { label: 'Réglages', url: `/templates/${template ? `${template.id}/settings` : ''}` },
      //   // { label: 'Statistiques', url: `/templates/${template ? `${template.id}/statistics` : ''}` },
      // ]}
    />
  )
}
