import axiosApi from './api'

export const userService = {
  login,
  initFB,
  logout,
  getSelf,
  update,
  create,
  deleteU,
  updateProfilePicture,
  changePassword,
  resetPassword,
  forgotPassword,
}

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  }

  return fetch(`${process.env.REACT_APP_API_URL}/api/login_check`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      //  user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user))
      return user
    })
}

async function initFB(FBtoken) {
  const body = JSON.stringify({ token: FBtoken })
  const res = await axiosApi.post(`${process.env.REACT_APP_API_URL}/api/init_facebook`, body)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user')
}

async function getSelf() {
  const res = await axiosApi.get(`${process.env.REACT_APP_API_URL}/api/user/me`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function update(user) {
  const res = await axiosApi.put(`${process.env.REACT_APP_API_URL}/api/user`, JSON.stringify(user))
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function deleteU(id) {
  const res = await axiosApi.delete(`${process.env.REACT_APP_API_URL}/api/user/${id}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function create(user) {
  const res = await axiosApi.post(`${process.env.REACT_APP_API_URL}/api/user`, JSON.stringify(user))
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function updateProfilePicture(base64image, extension) {
  const res = await axiosApi.request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/api/user/profile-picture`,
    data: {
      img: base64image,
      extension: extension,
    },
  })
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout()
        //refreshToken(null)
        window.location.reload(true)
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}

async function changePassword(datas) {
  const body = datas
  const res = await axiosApi.put(`${process.env.REACT_APP_API_URL}/api/user/password`, body)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

function resetPassword(email) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  }

  return fetch(`${process.env.REACT_APP_API_URL}/password/forget`, requestOptions)
    .then(handleResponse)
    .then((result) => {
      return result
    })
}

function forgotPassword({ hash, password, confirmPassword }) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      hash: hash,
      password: password,
      confirm_password: confirmPassword,
    }),
  }

  return fetch(`${process.env.REACT_APP_API_URL}/recovery/password`, requestOptions)
    .then(handleResponse)
    .then((result) => {
      return result
    })
}
