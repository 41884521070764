import { userConstants } from '_constants'

let user = JSON.parse(localStorage.getItem('user'))
const initialState = user ? { loggedIn: true, user } : {}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      }

    case userConstants.LOGIN_SUCCESS:
      return {
        loggingIn: false,
        loggedIn: true,
        user: action.user,
      }

    case userConstants.LOGIN_FAILURE:
      return {}

    case userConstants.USER_GET_SELF:
      return {
        ...state,
        user: {
          ...(state.user ?? {}),
          ...action.user,
        },
      }

    case userConstants.UPDATE:
      const update = { ...state.user, ...action.user }
      const clone = { ...state }
      clone.user = update
      return { ...state, ...clone }

    case userConstants.UPDATE_PROFILE_PICTURE:
      return {
        ...state,
        user: {
          ...state.user,
          img: action.result.img,
        },
      }

    case userConstants.SAVE_FB_TOKEN:
      const temp = { ...state }
      temp.user.has_facebook_credentials = action.result.success
      return {
        ...state,
        ...temp,
      }

    default:
      return state
  }
}
