import React from 'react'
import { useDispatch } from 'react-redux'
import 'moment/locale/fr'
import { libraryActions, modalActions } from '_actions'
import Button from 'components/buttons/Button'

export default function DeleteFromLibrary({ ...modalProps }) {
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(modalActions.closeModal())
  }

  const deleteAsset = () => {
    dispatch(libraryActions.deleteAssets(modalProps.assets))

    closeModal()
  }

  const getSentence = () => {
    if (modalProps.assets.length > 1) {
      return 'Voulez-vous vraiment supprimer ces contenus de votre bibliothèque ?'
    } else {
      return 'Voulez-vous vraiment supprimer ce contenu de votre bibliothèque ?'
    }
  }

  return (
    <div className="modal-delete-from-feed">
      <div className="modal__title text-center">Supprimer de votre bibliothèque</div>
      <div className="modal__grip text-center">{getSentence()}</div>
      <div className="flex justify-between">
        <Button btnStyle="plain" onClickFn={deleteAsset}>
          Supprimer
        </Button>
        <Button btnStyle="border" color="grey-lighter" onClickFn={closeModal}>
          Annuler
        </Button>
      </div>
    </div>
  )
}
