import { IconPlus } from '_svg'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useSingleton from 'components/utils/useSingleton'
import { collectionsActions, modalActions } from '_actions'
import CollectionListItem from 'components/_collections/CollectionListItem'
import Button from 'components/buttons/Button'

export default function DownloadToCollection({ ...modalProps }) {
  const collectionsRdx = useSelector((state) => state.collections)
  const [collections, setCollections] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    setCollections(collectionsRdx.items)
  }, [collectionsRdx])

  useSingleton(() => {
    // will always be triggered once
    dispatch(collectionsActions.getCollections())
  })

  /* useEffect(() => {
        if(collections && collections.length <= 1 && firstReq) {
          dispatch(collectionsActions.getCollections())
          setFirstReq(false)
        } 
      }, [collections]) */

  const selectCollection = (e, el) => {
    e.preventDefault()
    e.stopPropagation()
    /* if(!el.assets_ids.includes(modalProps.media.id)) {
            const selectedCol = collectionsRdx.items.filter(c => c.id === el.id)[0]
            updateCollection(selectedCol)
        } */

    const selectedCol = collectionsRdx.items.filter((c) => c.id === el.id)[0]
    updateCollection(selectedCol)
  }

  /* const updateCollection = (collection) => {
        collection.assets_ids.push(modalProps.media.id)
        const {assets_count, previews, success, ...rest} = collection;

        dispatch(collectionsActions.updateCollection(rest, modalProps.media))
    } */

  const updateCollection = (collection) => {
    modalProps.media.forEach((el) => {
      collection.assets_ids.push(el.id)
    })
    collection.assets_ids = [...new Set(collection.assets_ids)]
    const { assets_count, previews, success, ...rest } = collection

    /* if( modalProps.media.length <= 1) {
            dispatch(collectionsActions.updateCollection(rest, modalProps.media[0]))
        } else {
            dispatch(collectionsActions.updateCollection(rest))
        } */

    /*  modalProps.media.map(el => {
            dispatch(collectionsActions.updateCollection(rest, el))
        }) */
    dispatch(collectionsActions.updateCollection(rest, modalProps.media))
  }

  const createCollection = () => {
    dispatch(modalActions.createCollection(true, modalProps.media))
  }

  const getCollectionsList = () => {
    if (!collections) return
    return collections.map((el, i) => {
      return (
        <li key={el.id}>
          <CollectionListItem
            data={el}
            color="light"
            addedClass={el.assets_ids.includes(modalProps.media.id) ? 'is-disabled' : ''}
            onClickFn={(e) => selectCollection(e, el)}
          />
        </li>
      )
    })
  }

  return (
    <div className="modal-add-to-collection">
      <div className="modal__title">Média téléchargé ! Ajoutez le à une collection.</div>
      <div className="modal__grip">Choisissez une collection ou passez cette étape.</div>
      <ul className="list-reset">
        <li>
          <div className="collections-list__item_create" onClick={createCollection}>
            <div className="dotted-border">
              <div className="picto-wrapper">
                <div className="picto">
                  <div className="picto__content">
                    <IconPlus className="icon" />
                  </div>
                </div>
                <div className="text-gradient text-gradient--H">Créer</div>
              </div>
            </div>
          </div>
        </li>
      {getCollectionsList()}
      </ul>
      <div className="flex justify-end mt-5">
        <Button btnStyle="plain" onClickFn={() => {
          dispatch(modalActions.closeModal())
        }}>
          Passer cette étape
        </Button>
      </div>
    </div>
  )
}
