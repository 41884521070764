import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IconShare, IconDelete, IconDownload } from '_svg'
import { accountActions, modalActions, noticesActions, userActions } from '_actions'
import { useEffect } from 'react'
import { accountEmpty, userEmpty } from '_services/Utils'
import { PLAN } from '_constants'

export default function CollectionListItem({ data, color = 'dark', onClickFn = null, addedClass = '' }) {
  const dispatch = useDispatch()

  const account = useSelector((state) => state.account)
  const user = useSelector((state) => state.authentication.user)

  useEffect(() => {
    if (accountEmpty(account)) dispatch(accountActions.getSelf())
    if (userEmpty(user)) dispatch(userActions.getSelf())
  }, [dispatch, account, user])

  const deleteCollection = (e) => {
    e.preventDefault()
    dispatch(modalActions.deleteCollection(data))
  }

  const handleClick = (e) => {
    if (e.currentTarget.classList.contains('is-disabled')) e.preventDefault()
    if (onClickFn && !e.currentTarget.classList.contains('is-disabled')) {
      e.currentTarget.classList.add('is-loading')
      onClickFn(e)
    }
  }

  const download = (e) => {
    e.preventDefault()
    const elArray = data.previews.map((el, i) => {
      return { id: data.assets_ids[i], path: el }
    })
    if (elArray.length > 0) {
      dispatch(noticesActions.download(elArray))
    }
  }

  const shareCollection = (e) => {
    e.preventDefault()
    console.log('clicked on sharing : ', data)
    dispatch(modalActions.shareCollection(data))
  }

  const getThumbs = () => {
    let thumbs = ['', '', '', '']
    if (data.previews.length > 0) thumbs = data.previews
    return thumbs.map((el, i) => {
      if (el !== '' && el.substring(el.length - 4) === '.mp4') {
        el = '/assets/images/video.gif'
      }
      return <div key={i} className="mosaic-thumb" style={{ backgroundImage: `url("${el}")` }} />
    })
  }
  return (
    <>
      {data ?
        <Link
          to={`/collections/${data.id}`}
          onClick={handleClick}
          className={`collections-list__item ${color === 'light' ? 'collections-list__item--light' : ''} ${addedClass !== '' ? addedClass : ''
            }`}
        >
          <div className="mosaic">
            {getThumbs()}
            <div className="overlay">
              {[PLAN.ULTRA, PLAN.PRO].includes(account?.plan) && user?.isAdmin && (
                <button className="bt-share" onClick={shareCollection}>
                  <IconShare className="icon" />
                </button>
              )}
              {data.previews.length > 0 && (
                <button className="bt-download" onClick={download}>
                  <IconDownload className="icon" />
                </button>
              )}
              <button className="bt-delete" onClick={deleteCollection}>
                <IconDelete className="icon" />
              </button>
            </div>
            <div className="lds-dual-ring"></div>
          </div>
          <div className="collections-list__item__title">{data.name}</div>
          <div className="collections-list__item__count">
            {parseInt(data.assets_count)} post{parseInt(data.assets_count) > 1 ? 's' : ''}
          </div>
        </Link>
        : <></>}
    </>
  )
}
