import { messagesService } from '_services'
import { messagesConstants } from '_constants'
import { modalActions, alertActions, noticesActions } from './'

export const messagesActions = {
  getAllMessages,
  createMessage,
  updateMessage,
  deleteMessage,
}

function getAllMessages() {
  return (dispatch) => {
    // dispatch(request());
    messagesService.getAllMessages().then(
      (messages) => {
        dispatch(success(messages.data))
      },
      (error) => {
        // dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(messages) {
    return { type: messagesConstants.GET_ALL, messages }
  }
  // function failure(error) { return { type: messagesConstants.LOGIN_FAILURE, error } }
}

function createMessage(translations, commentTranslations) {
  return (dispatch) => {
    // dispatch(request());
    messagesService.createMessage({ translations, commentTranslations }).then(
      (data) => {
        dispatch(success({ ...data, ...{ translations }, ...{ commentTranslations } }))
        dispatch(modalActions.closeModal())
        dispatch(noticesActions.messageCreated())
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(message) {
    return { type: messagesConstants.CREATE, message }
  }
  function failure(error) {
    return { type: messagesConstants.CREATE_FAILURE, error }
  }
}

function updateMessage(item) {
  return (dispatch) => {
    messagesService.updateMessage(item).then(
      (result) => {
        dispatch(success(item))
        dispatch(modalActions.closeModal())
        dispatch(noticesActions.messageUpdated())
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(message) {
    return { type: messagesConstants.UPDATE, message }
  }
}

function deleteMessage(id) {
  return (dispatch) => {
    // dispatch(request());
    messagesService.deleteMessage(id).then(
      (data) => {
        dispatch(success(id))
        dispatch(noticesActions.messageDeleted())
      },
      (error) => {
        // dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(id) {
    return { type: messagesConstants.DELETE, id }
  }
  // function failure(error) { return { type: messagesConstants.CREATEMessage_FAILURE, error } }
}
