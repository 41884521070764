import { history } from '_helpers'
import { landingsService } from '_services'
import { landingsConstants } from '_constants'
import { alertActions, noticesActions, modalActions } from '.'

export const landingsActions = {
  chooseCollection,
  createLanding,
  getAllLandings,
  deleteLanding,
  updateLanding,
}

function getAllLandings(options) {
  return (dispatch) => {
    // dispatch(request());
    landingsService.getAll(options).then(
      (landings) => {
        dispatch(success(landings))
      },
      (error) => {
        // dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: landingsConstants.GET_ALL } }
  function success(landings) {
    return { type: landingsConstants.GET_ALL, landings }
  }
  // function failure(error) { return { type: landingsConstants.LOGIN_FAILURE, error } }
}

function createLanding(formData) {
  // name, user_id, locale, hashtags, description
  return (dispatch) => {
    // dispatch(request());
    landingsService.create(formData).then(
      (landing) => {
        dispatch(success(landing))
        history.push('/landing-pages')
        dispatch(noticesActions.landingCreated(landing))
      },
      (error) => {
        let msg = error.message
        switch (msg) {
          default:
            break
          case 'landing quota exceeded':
            msg = landingsConstants.ERROR_QUOTA_EXCEEDED
            break
        }
        dispatch(alertActions.error(msg.toString()))
      }
    )
  }

  // function request() { return { type: landingsConstants.GET_ALL } }
  function success(data) {
    return { type: landingsConstants.CREATE, data }
  }
}

function chooseCollection(collection) {
  return (dispatch) => {
    dispatch({ type: landingsConstants.CHOOSE_COLLECTION, collection })
    dispatch(modalActions.closeModal())
  }
}

function deleteLanding(landing) {
  // name, user_id, locale, hashtags, description
  return (dispatch) => {
    // dispatch(request());
    landingsService.deleteLanding(landing.id).then(
      (data) => {
        dispatch(success(landing))
        history.push('/landing-pages')
        dispatch(noticesActions.landingDeleted(landing))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: landingsConstants.GET_ALL } }
  function success(landing) {
    return { type: landingsConstants.DELETE, landing }
  }
  function failure(error) {
    return { type: landingsConstants.DELETE_FAILURE, error }
  }
}

function updateLanding(landing) {
  // name, user_id, locale, hashtags, description
  return (dispatch) => {
    // dispatch(request());
    landingsService.updateLanding(landing).then(
      (data) => {
        dispatch(success(landing))
        history.push('/landing-pages')
        dispatch(noticesActions.landingUpdated(landing))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: landingsConstants.GET_ALL } }
  function success(landing) {
    return { type: landingsConstants.UPDATE, landing }
  }
  function failure(error) {
    return { type: landingsConstants.DELETE_FAILURE, error }
  }
}
