import { accountConstants } from '_constants'
const initialState = {}

export function account(state = initialState, action) {
  switch (action.type) {
    case accountConstants.GET_SELF_ACCOUNT:
      return { ...state, ...action.result }
    case accountConstants.UPDATE_CONFIG:
      return { ...state, ...action.result }

    default:
      return state
  }
}
