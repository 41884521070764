import React from 'react'
import { useDispatch } from 'react-redux'
import { feedActions, modalActions } from '_actions'
import Button from 'components/buttons/Button'

export default function DeleteFeeds({ ...modalProps }) {
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(modalActions.closeModal())
  }

  const deleteFeeds = () => {
    dispatch(feedActions.deleteFeeds(modalProps.feeds))
    dispatch(modalActions.closeModal())
    setTimeout(() => {
      modalProps.loadFeeds()
    }, 500)
  }

  return (
    <div className="modal-delete-message">
      <div className="modal__title text-center">Suppression de feeds</div>
      <div
        className="modal__grip text-center"
        dangerouslySetInnerHTML={{
          __html: `Voulez-vous vraiment supprimer ces feeds ?`,
        }}
      />
      <div className="flex justify-between">
        <Button btnStyle="plain" onClickFn={deleteFeeds}>
          Supprimer
        </Button>
        <Button btnStyle="border" color="grey-lighter" onClickFn={closeModal}>
          Annuler
        </Button>
      </div>
    </div>
  )
}
