import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { noticesConstants } from '_constants/notices.constants'
import { noticesActions } from '_actions/notices.actions'
import { libraryService } from '_services'
import useSingleton from 'components/utils/useSingleton'

export default function DownloadCSV({ ...noticeProps }) {
  const dispatch = useDispatch()
  const [percentCompleted, setPercentCompleted] = useState(0)
  const myElement = useRef(null)
  let timer = 0

  useSingleton(() => {
    async function downloadMedia() {
      let res, imgURL

      setPercentCompleted(100)
      const assetsIds = noticeProps.asset.map((el) => el.id).join()
      res = await libraryService.massDownloadCSV(assetsIds)
      imgURL = 'assets.csv'

      const url = URL.createObjectURL(res.data)
      var link = document.createElement('a')
      link.href = url

      const ext = imgURL.substring(imgURL.length - 4)
      link.download = `download${ext}`
      document.body.appendChild(link)

      document.body.removeChild(link)
      clearTimeout(timer)
      timer = setTimeout(() => {
        if (myElement.current) myElement.current.classList.add('downloaded')

        link.click()
        setTimeout(() => {
          dispatch(noticesActions.closeNotice(noticeProps.id))
        }, noticesConstants.AUTOCLOSE_TIMER * 1.5)
      }, 1000)

      return res
      // axios({
      //     method: "get",
      //     imgURL,
      //     responseType: "blob",
      //     // headers: { Authorization: token },
      //     onDownloadProgress: (evt) => {
      //     // console.log("progressEvent===",evt )
      //     //Handling of native progress events
      //     this.setState({ progress: parseInt((evt.loaded / evt.total) * 100) });

      //     }

      //     })
    }
    downloadMedia()
  })

  const getMedia = () => {
    if (noticeProps.asset[0].type === 'VIDEO') {
      return (
        <div className="notice__visual">
          <video controls autoPlay muted loop>
            <source src={noticeProps.asset[0].path} type="video/mp4" />
          </video>
        </div>
      )
    } else if (noticeProps.asset[0].type === 'IMAGE') {
      return <img src={noticeProps.asset[0].path} className="notice__visual" alt="notice thumbnail" />
    }
  }

  return (
    <div className="notice-download" ref={myElement}>
      {getMedia()}
      <div className="content">
        <div className="notice__title">Téléchargement</div>
        <div className="download-bar">
          <div className="download-bar__track"></div>
          <div className="download-bar__progress" style={{ width: `${percentCompleted}%` }}></div>
        </div>
        <div className="notice__msg">Ce média a bien été téléchargé !</div>
      </div>
    </div>
  )
}
