import { Select } from 'antd'
import { useRef } from 'react'

export default function SelectModule({ title, items = [], icon = null, callback }) {
  const { Option } = Select

  const handleChange = (value) => {
    callback(value)
  }

  const dropdown = useRef(null)

  return (
    <div className={`select-module${icon ? ' with-icon' : ''}`}>
      <label className='label'>{title}</label>
      {/* <select name="" id="" onChange={(e) => { handleChange(e) }}>
                {getItems()}
            </select> */}
      <Select getPopupContainer={() => dropdown.current} name="" defaultValue={items[0].value} required onChange={handleChange}>
        {items.map((el, i) => {
          return (
            <Option value={el.value} key={i}>
              {el.label}
            </Option>
          )
        })}
      </Select>
      <div ref={dropdown}>
      </div>
      {icon}
    </div >
  )
}
