import { ReactComponent as IconInsta } from "../../_svg/icon-insta.svg"
import { ReactComponent as IconTiktok } from "../../_svg/icon-tiktok.svg"
import { ReactComponent as IconComment } from "../../_svg/icon-comment.svg"
import { ReactComponent as IconLike } from "../../_svg/icon-like-full.svg"

const Infos = ({ addClass  = null, user, link, platform, likes, comments, date, isModal = false }) => {
  date = new Date(date)

  const formatNumber = (number) => {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + 'M'
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'K'
    } else {
      return number.toString()
    }
  }
  
  return (
    <div className={`landing-infos ${addClass ?? '' }`}>
      { isModal
        ? <a href={link} target="_blank" className="flex items-center">
            <Inner
              platform={platform}
              user={user}
              date={date}
            />
          </a>
        : <Inner
            platform={platform}
            user={user}
            date={date}
          />
      }
      <div className="flex items-center landing-infos__right">
        <IconLike className="landing-infos__like" />
        <p>{ formatNumber(likes) }</p>
        <IconComment className="landing-infos__comment" />
        <p>{ formatNumber(comments) }</p>
      </div>
    </div>
  )
}

const Inner = ({ platform, user, date}) => {
  return (
    <>
      { platform === 'insta'
        ? <IconInsta className="landing-infos__picto" />
        : <IconTiktok className="landing-infos__picto" />
      }
      <div>
        <p>{ user }</p>
        <p className="landing-infos__date">{ date.toLocaleDateString('fr') }</p>
      </div>
    </>
  )
}

export default Infos