import React, { useEffect, useState } from 'react'
import Layout from 'components/layout/Layout'
import PageHeader from 'components/layout/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { templatesActions } from '_actions/templates.actions'
import SearchModule from 'components/filters/SearchModule'
import Button from 'components/buttons/Button'
import SortBar from 'components/filters/SortBar'
import TemplatesListItem from 'components/_templates/TemplatesListItem'
import Pagination from 'components/navigation/Pagination'
import { Skeleton } from 'antd'
import { TEMPLATE_QUOTA, PLAN } from '_constants'
import { accountActions, userActions } from '_actions'

import { accountEmpty, userEmpty } from '_services/Utils'

function Templates() {
  const dispatch = useDispatch()
  const templatesRdux = useSelector((state) => state.templates)
  const user = useSelector((state) => state.authentication.user)
  const [templates, setTemplates] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [requestOptions, setRequestOptions] = useState({
    q: '',
    page: 1,
    limit: 20,
    sort_by: 'last_update',
    sort_order: 'DESC',
  })
  const [pagination] = useState({
    current_page: 1,
    limit: 20,
    total_count: 0,
  })

  const account = useSelector((state) => state.account)
  const [canCreate, setCanCreate] = useState(true)

  useEffect(() => {
    if (accountEmpty(account)) dispatch(accountActions.getSelf())
    if (userEmpty(user)) dispatch(userActions.getSelf('/templates'))

    switch (account.plan) {
      case PLAN.LIGHT:
        setCanCreate(templates.length < TEMPLATE_QUOTA.PLAN_LIGHT)
        break
      case PLAN.PRO:
        setCanCreate(templates.length < TEMPLATE_QUOTA.PLAN_PRO)
        break

      case PLAN.ULTRA:
        setCanCreate(user?.isAdmin ? true : templates.length < TEMPLATE_QUOTA.PLAN_ULTRA_CHILDREN)
        break

      default:
        setCanCreate(false)
        break
    }
  }, [user, account, templates, dispatch])

  useEffect(() => {
    // default sorting by last update:
    const templates = [...templatesRdux.items.sort(dynamicSort('-last_update'))]
    setTemplates(templates)
    setIsLoading(false)
  }, [templatesRdux])

  useEffect(() => {
    // condition te prevent loading on first loading
    if (!templates) return
    loadTemplates()
  }, [requestOptions])

  const loadTemplates = () => {
    if (isLoading) return
    setIsLoading(true)
    dispatch(templatesActions.getAllTemplates(requestOptions))
  }

  const gettemplatesList = () => {
    if (isLoading) {
      return Array.apply(null, Array(requestOptions.limit)).map((el, i) => {
        return (
          <li className="mt-10 stagger-anim" key={i}>
            <Skeleton active paragraph={{ rows: 1 }} />
          </li>
        )
      })
    } else {
      if (templates.length > 0) {
        return templates.map((el, i) => {
          return (
            <li key={i} className="stagger-anim">
              <TemplatesListItem data={el} />
            </li>
          )
        })
      } else {
        if (requestOptions.q !== '') {
          // search result
          return <li className="mt-6 gen-text stagger-anim">Votre recherche n'a donné aucun résultat.</li>
        } else {
          return (
            <li className="mt-6 gen-text stagger-anim">
              Vous n'avez encore créé aucun template.{' '}
              <Link to="/templates/create" className="info">
                Créez-en un maintenant !
              </Link>
            </li>
          )
        }
      }
    }
  }

  const search = (str, operatorAnd) => {
    const temp = {
      q: encodeURIComponent(str),
      operatorAnd: operatorAnd,
      page: 1
    }
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const sortBy = (attr) => {
    setTemplates([...templates.sort(dynamicSort(attr))])
  }

  const dynamicSort = (property) => {
    // https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
    var sortOrder = 1
    if (property[0] === '-') {
      sortOrder = -1
      property = property.substr(1)
    }
    // if(property === 'last_update' || property === 'date_creation') sortOrder = -1;
    if (property === 'status') sortOrder = -1
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
      return result * sortOrder
    }
  }

  const handlePagination = (index) => {
    setRequestOptions({ ...requestOptions, page: index })
  }

  return (
    <Layout className="page page-templates">
      <PageHeader data={[{ label: 'Templates' }]} />
      <div className="page__content">
        <div className="page__intro">
          Ajoutez un template pour intégrer vos contenus UGC sur vos canaux de distributions. Gérez un template existant en
          cliquant sur son nom.
          {!canCreate && (
            <>
              <br />
              <br />
              <div className="gen-text gen-text--red">Vous avez atteint votre quota de templates disponibles !</div>
            </>
          )}
        </div>
        <div className="sticky-wrapper">
          <div className="flex justify-between items-end">
            <SearchModule callback={search} placeholder="Rechercher..." />
            {
              <span className="assets-total-count">
                {templates && templates.length > 0
                  ? `${templates.length} Template${templates.length > 1 ? 's' : ''}`
                  : `0 Template`}
              </span>
            }
            <Button
              target="_self"
              btnStyle="plain"
              url="/templates/create"
              disabled={!canCreate}
              color={canCreate ? 'red' : 'grey'}
            >
              Créer un template
            </Button>
          </div>
          <SortBar
            data={[
              { label: 'nom', sortingAttribute: 'name' },
              { label: 'type', sortingAttribute: 'type' },
              { label: 'dernier update', sortingAttribute: '-last_update' },
            ]}
            sortFunction={sortBy}
          />
        </div>
        <ul className="templates-list list-reset">{gettemplatesList()}</ul>

        {pagination && <Pagination data={pagination} requestFn={handlePagination} />}
      </div>
    </Layout>
  )
}

export default Templates
