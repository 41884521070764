export const widgetsConstants = {
  GET_ALL: 'WIDGETS_GET_ALL',
  CREATE: 'WIDGET_CREATE',
  CHOOSE_COLLECTION: 'WIDGET_CHOOSE_COLLECTION',
  CREATE_FAILURE: 'WIDGET_CREATE_FAILURE',
  UPDATE: 'WIDGET_UPDATE',
  DELETE: 'WIDGET_DELETE',
  ERROR_QUOTA_EXCEEDED:
    'Vous avez atteint le nombre de widgets autorisé. Veuillez en supprimer un pour en créer un nouveau',
}
