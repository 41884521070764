import { Select } from 'antd'
import Button from 'components/buttons/Button'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { accountActions, collectionsActions, userActions } from '_actions'

import { accountEmpty, userEmpty } from '_services/Utils'
import { useCallback } from 'react'

export default function ShareCollection({ collection }) {
  console.log(collection)
  const dispatch = useDispatch()
  const account = useSelector((state) => state.account)
  const user = useSelector((state) => state.authentication.user)

  useEffect(() => {
    if (user && userEmpty(user)) dispatch(userActions.getSelf('/collections'))
    if (accountEmpty(account)) dispatch(accountActions.getSelf())
  }, [account, user, dispatch])

  const [isLoading, setIsLoading] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState(null)

  const submitForm = (e) => {
    e.preventDefault()
    if (isLoading) return
    setIsLoading(true)

    setIsLoading(false)

    const selectedUser = getUserList().find(({ id }) => id === selectedUserId)

    if (!selectedUser) return

    dispatch(
      collectionsActions.shareCollection({
        collection,
        user: selectedUser,
      })
    )
  }

  const getUserList = useCallback(() => {
    if (accountEmpty(account)) return []

    return account?.user_list?.filter((u) => u.id !== user.id)
  }, [user, account])

  return (
    <div className="modal-share-collection">
      <div className="modal__title">Partage d'une collection </div>
      <div className="modal__grip ant-white">
        <div className="label label--med">Utilisateur</div>
        <Select
          name="user"
          value={selectedUserId ?? 'Sélectionnez un utilisateur'}
          style={{ color: '#4b4b4b' }}
          onChange={setSelectedUserId}
        >
          {getUserList().map((user, i) => (
            <Select.Option key={`user-${i}`} value={user.id}>
              {user.full_name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <form className="flex flex-col items-start" onSubmit={submitForm}>
        <Button
          btnStyle="plain"
          type="submit"
          addedclass="self-end mt-6"
          loading={isLoading}
          disabled={selectedUserId === null}
        >
          Partager
        </Button>
      </form>
    </div>
  )
}
