import React from 'react'
import { useDispatch } from 'react-redux'
import { modalActions, userActions } from '_actions'
import Button from 'components/buttons/Button'

export default function DeleteUser({ ...modalProps }) {
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(modalActions.closeModal())
  }

  const deleteUser = () => {
    dispatch(userActions.deleteU(modalProps.user.id))
  }

  return (
    <div className="modal-delete-message">
      <div className="modal__title text-center">Suppression d'un utilisateur</div>
      <div
        className="modal__grip text-center"
        dangerouslySetInnerHTML={{
          __html: `Voulez-vous vraiment supprimer <strong>${modalProps.user.first_name} ${modalProps.user.last_name}</strong> ?`,
        }}
      />
      <div className="flex justify-between">
        <Button btnStyle="plain" onClickFn={deleteUser}>
          Supprimer
        </Button>
        <Button btnStyle="border" color="grey-lighter" onClickFn={closeModal}>
          Annuler
        </Button>
      </div>
    </div>
  )
}
