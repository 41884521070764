import { history } from '_helpers'
import { templatesService } from '_services'
import { templatesConstants } from '_constants'
import { alertActions, noticesActions, modalActions } from '.'

export const templatesActions = {
  createTemplate,
  getAllTemplates,
  deleteTemplate,
  updateTemplate,
}

function getAllTemplates(options) {
  return (dispatch) => {
    // dispatch(request());
    templatesService.getAll(options).then(
      (templates) => {
        dispatch(success(templates))
      },
      (error) => {
        // dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: templatesConstants.GET_ALL } }
  function success(templates) {
    return { type: templatesConstants.GET_ALL, templates }
  }
  // function failure(error) { return { type: templatesConstants.LOGIN_FAILURE, error } }
}

function createTemplate(formData) {
  // name, user_id, locale, hashtags, description
  return (dispatch) => {
    // dispatch(request());
    templatesService.create(formData).then(
      (template) => {
        dispatch(success(template))
        history.push('/templates')
        dispatch(noticesActions.templateCreated(template))
      },
      (error) => {
        let msg = error.message
        switch (msg) {
          default:
            break
          case 'template quota exceeded':
            msg = templatesConstants.ERROR_QUOTA_EXCEEDED
            break
        }
        dispatch(alertActions.error(msg.toString()))
      }
    )
  }

  // function request() { return { type: templatesConstants.GET_ALL } }
  function success(data) {
    return { type: templatesConstants.CREATE, data }
  }
}

function deleteTemplate(template) {
  // name, user_id, locale, hashtags, description
  return (dispatch) => {
    // dispatch(request());
    templatesService.deleteTemplate(template.id).then(
      (data) => {
        dispatch(success(template))
        history.push('/templates')
        dispatch(noticesActions.templateDeleted(template))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: templatesConstants.GET_ALL } }
  function success(template) {
    return { type: templatesConstants.DELETE, template }
  }
  function failure(error) {
    return { type: templatesConstants.DELETE_FAILURE, error }
  }
}

function updateTemplate(template) {
  // name, user_id, locale, hashtags, description
  return (dispatch) => {
    // dispatch(request());
    templatesService.updateTemplate(template).then(
      (data) => {
        dispatch(success(template))
        history.push('/templates')
        dispatch(noticesActions.templateUpdated(template))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: templatesConstants.GET_ALL } }
  function success(template) {
    return { type: templatesConstants.UPDATE, template }
  }
  function failure(error) {
    return { type: templatesConstants.DELETE_FAILURE, error }
  }
}
