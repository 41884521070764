const Product = ({ isSingle, product }) => {
  return (
    <div class={`landing-product ${isSingle ? 'landing-product--single' : 'landing-product--multiple' }`}>
      <div className={!isSingle ? 'landing-product__left' : ''}>
        <div className="landing-product__pic">
          <img src={product.img} alt="" />
        </div>
      </div>
      <div>
        <p className="landing-product__title">{product.title}</p>
        { product.desc &&
          <p>{product.desc}</p>
        }
        <a href={product.link} className="landing-button landing-button--small" target="_blank">Je découvre</a>
      </div>
    </div>
  )
}

export default Product