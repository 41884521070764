import PageHeader from 'components/layout/PageHeader'

export default function HeaderWithTabs({ feed }) {
  return (
    <PageHeader
      data={[
        { label: 'Feed', url: '/feed' },
        { label: feed ? feed.name : '', feed: feed ? feed : '' },
      ]}
      tabs={[
        { label: 'contenus', url: `/feed/${feed ? feed.id : ''}/contents` },
        { label: 'Réglages', url: `/feed/${feed ? feed.id : ''}/settings` },
      ]}
    />
  )
}
