import { askConstants } from '_constants'
const initialState = []

export function ask(state = initialState, action) {
  switch (action.type) {
    case askConstants.GET_ALL:
      return [...state, ...action.ask]
    case askConstants.GET:
      return {
        ...state,
        ask: action.ask,
      }
    case askConstants.CREATE:
      return [...state, ...[action.ask]]
    case askConstants.DELETE:
      return state.filter((ask) => {
        if (ask.id === action.ask.id) return false
        return true
      })
    case askConstants.UPDATE:
      return [...state, ...[action.ask]]
    default:
      return state
  }
}
