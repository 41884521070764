export const askConstants = {
  GET_ALL: 'ASK_GET_ALL',
  GET: 'ASK_GT',
  CREATE: 'ASK_CREATE',
  CREATE_FAILURE: 'ASK_CREATE_FAILURE',
  DELETE: 'ASK_DELETE',
  UPDATE: 'ASK_UPDATE',
  VERIFY_HASH: 'ASK_VERIFY_HASH',
  SUBMIT_FORM: 'ASK_SUBMIT_FORM',
}
