export const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  USER_GET_SELF: 'USER_GET_SELF',
  GET_SELF_SUCCESS: 'USER_GET_SELF_OK',
  GET_SELF_FAILURE: 'USER_GET_SELF_KO',

  UPDATE: 'USER_UPDATE',
  UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
  SAVE_FB_TOKEN: 'USER_SAVE_FB_TOKEN',
}
