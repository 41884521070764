import classNames from 'classnames'
import { Link } from 'react-router-dom'

export default function Button(props) {
  const {
    url,
    children,
    btnStyle,
    color = 'red',
    target = '_self',
    type = 'button',
    addedclass = '',
    customStyle = null,
    onClickFn = null,
    loading = false,
    disabled = false,
  } = props

  const classes = classNames(`button button--${btnStyle}`, `button--${btnStyle}--${color}`, addedclass, {
    'is-loading': loading,
    'is-disabled': disabled && !addedclass.includes('is-disabled'),
  })

  if (url !== null && url !== undefined) {
    if (target === '_self') {
      return (
        <Link to={url} className={classes}>
          {children}
        </Link>
      )
    } else {
      return (
        <a href={url} target={target} className={classes} onClick={onClickFn}>
          {children}
        </a>
      )
    }
  } else {
    return (
      <button
        type={type}
        style={customStyle}
        onClick={onClickFn}
        className={classes}
        disabled={disabled || classes.includes('is-disabled')}
      >
        {loading ? <span className="button__spinner"></span> : children}
      </button>
    )
  }
}
