import 'moment/locale/fr'
import moment from 'moment'
import { Link } from 'react-router-dom'
import CheckBox from 'components/filters/CheckBox'

export default function FeedsListItem({ data, selectFunction, isSelected = false }) {
  const getStatus = () => {
    let text = ''
    switch (parseInt(data.status)) {
      default:
      case 0:
        text = 'Planifié'
        break
      case 1:
        // text = `Actif <span>jusqu'au ${moment(data.end_at).locale('fr').format('L')}</span>`
        text = `Actif`
        break
      case -1:
        text = `Inactif <span>depuis le ${moment(data.end_at).locale('fr').format('L')}</span>`
        break
    }
    return (
      <div className="status">
        <div className={`status-indic status-indic--${data.status}`}></div>
        <span dangerouslySetInnerHTML={{ __html: text }}></span>
      </div>
    )
  }

  const getCount = () => {
    if (parseInt(data.assets_count) > 0) {
      return data.assets_count
    } else if (data.is_failed) {
      return <p style={{ color: 'red', margin: 0 }}>Échec</p>
    } else {
      return <img src="assets/images/mini-loader.gif" alt="processing" />
    }
  }

  const handleSelection = (e) => {
    selectFunction(data, e.checked)
  }

  return (
    <div className="feeds-list__item">
      <CheckBox name="actions" customClass="select-checkbox" callback={handleSelection} isChecked={isSelected} />
      <Link className="feeds-list__item-link" to={`/feed/${data.id}/contents`}>
        <span className="feeds-list__item__name">{data.name}</span>
      </Link>
      <Link className="feeds-list__item-link" to={`/feed/${data.id}/contents`}>
        <span className="feeds-list__item__date-start">{moment(data.start_at).locale('fr').format('L')}</span>
      </Link>
      <Link className="feeds-list__item-link" to={`/feed/${data.id}/contents`}>
        <span className="feeds-list__item__medias">{getCount()}</span>
      </Link>
      <Link className="feeds-list__item-link" to={`/feed/${data.id}/contents`}>
        <span className="feeds-list__item__source">
          <img src={`assets/images/${data.source}-logo.webp`} alt="processing" />
        </span>
      </Link>
      <Link className="feeds-list__item-link" to={`/feed/${data.id}/contents`}>
        <span className="feeds-list__item__status">{getStatus()}</span>
      </Link>
      <Link className="feeds-list__item-link" to={`/feed/${data.id}/contents`}>
        <span className="feeds-list__item__created-by">{data.created_by}</span>
      </Link>
      <Link className="feeds-list__item-link" to={`/feed/${data.id}/contents`}>
        <span className="feeds-list__item__created_at">{moment(data.date_creation).locale('fr').format('L')}</span>
      </Link>
      <Link className="feeds-list__item-link" to={`/feed/${data.id}/contents`}>
        <span className="feeds-list__item__last-update">{moment(data.last_update).locale('fr').format('L')}</span>
      </Link>
    </div>
  )
}
