import ProductSidebar from "./ProductSidebar"

const Sidebar = ({templateInfos}) => {
  return (
    <aside className="landing-sidebar">
      <h2 className="landing-sidebar__title">{templateInfos.sidebarTitle}</h2>

      <ul>
        { templateInfos.sidebarItems.map((el, i) => {
          return (
            <li className="landing-sidebar__item" key={i}>
              <ProductSidebar product={el} />
            </li>
          )
        }) }
      </ul>
    </aside>
  )
}

export default Sidebar