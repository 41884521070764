import React, { useState } from 'react'

export default function Sorting({ data, defaultPagination = 20, paginFn, defaultSorting = 'commentsCount', sortFn }) {
  const [pagination, setPagination] = useState(defaultPagination)
  const [sortby, setSortby] = useState(defaultSorting)

  const getPagination = () => {
    return data.displayBy.map((el, i) => {
      return (
        <li
          onClick={(e) => {
            handleClick(e)
          }}
          className={`sorting-pagination__item${el === pagination ? ' is-active' : ''}`}
          key={i}
        >
          {el}
        </li>
      )
    })
  }
  const getSorting = () => {
    return data.sortBy.map((el, i) => {
      return (
        <option key={i} value={el.value}>
          {el.label}
        </option>
      )
    })
  }

  const handleClick = (e) => {
    const val = parseInt(e.target.innerText)
    setPagination(val)
    paginFn(val)
  }

  const handleChange = (e) => {
    setSortby(e.target.value)
    sortFn(e.target.value)
  }

  return (
    <div className="sorting flex">
      <ul className="sorting-pagination list-reset flex flex-nowrap items-center">
        <li className="sorting-label">Voir</li>
        {getPagination()}
      </ul>
      <div className="sorting-by flex items-center">
        <span className="sorting-label">Trier par</span>
        <select
          name=""
          id=""
          value={sortby}
          onChange={(e) => {
            handleChange(e)
          }}
        >
          {getSorting()}
        </select>
      </div>
    </div>
  )
}
