import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accountActions, modalActions } from '_actions'
import Button from 'components/buttons/Button'
import Layout from 'components/layout/Layout'
import HeaderWithTabs from 'components/_account/HeaderWithTabs'
import UserListItem from 'components/_account/UserListItem'
import { PLAN, USER_QUOTA } from '_constants'
import { accountEmpty } from '_services/Utils'

function Users() {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.authentication.user)
  const account = useSelector((state) => state.account)

  const [canCreate, setCanCreate] = useState(true)

  useEffect(() => {
    switch (account.plan) {
      case PLAN.LIGHT:
        setCanCreate(account?.user_list.length < USER_QUOTA.PLAN_LIGHT)
        break

      case PLAN.PRO:
        setCanCreate(account?.user_list.length < USER_QUOTA.PLAN_PRO)
        break

      case PLAN.ULTRA:
        setCanCreate(true)
        break

      default:
        setCanCreate(false)
        break
    }
  }, [account])

  useEffect(() => {
    if (accountEmpty(account)) dispatch(accountActions.getSelf())
  }, [account, dispatch])

  const getUserList = () => {
    return account?.user_list?.map((el, i) => {
      return (
        <li key={i}>
          <UserListItem data={el} />
        </li>
      )
    })
  }

  return (
    <Layout className="page page-account-profile">
      <HeaderWithTabs />
      <div className="page__content">
        {!canCreate && (
          <div className="gen-text gen-text--red">Vous avez atteint votre quota d'utilisateurs disponibles !</div>
        )}

        <div className="fluid-grid">
          <div className="sticky-wrapper">
            <div className="flex justify-between items-end">
              <div className="page__title">Comptes utilisateurs</div>
              {user.permissions?.includes('user_create') ? (
                <Button
                  btnStyle="plain"
                  disabled={!canCreate}
                  color={canCreate ? 'red' : 'grey'}
                  onClickFn={() => dispatch(modalActions.createUser())}
                >
                  Créer un utilisateur
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
          <ul className="profile-list list-reset">{getUserList()}</ul>
        </div>
      </div>
    </Layout>
  )
}

export default Users
