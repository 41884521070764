import { Pagination } from 'antd'

export default function Paginate({ data, requestFn }) {
  const goToPage = (n) => {
    // setCurrentPage(n)
    requestFn(n)
  }

  return (
    <Pagination
      className="pagination mt-10 text-center"
      total={data.total_count}
      pageSize={data.limit}
      current={data.current_page}
      // showLessItems={true}
      showSizeChanger={false}
      hideOnSinglePage={true}
      onChange={goToPage}
    />
  )
}
