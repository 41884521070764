import { modalsConstants } from '_constants'
const initialState = { modalType: null, modalProps: {} }

export function modal(state = initialState, action) {
  switch (action.type) {
    case modalsConstants.OPEN_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      }
    case modalsConstants.CLOSE_MODAL:
      return initialState
    case modalsConstants.UPDATE_MODAL:
      const copy = { ...state }
      copy.modalProps.error = action.error
      return { ...state, ...copy }
    default:
      return state
  }
}
