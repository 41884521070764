import React from 'react'
import { useDispatch } from 'react-redux'
import { widgetsActions, modalActions } from '_actions'
import Button from 'components/buttons/Button'

export default function DeleteWidget({ ...modalProps }) {
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(modalActions.closeModal())
  }

  const deleteWidget = () => {
    dispatch(widgetsActions.deleteWidget(modalProps.widget))
    dispatch(modalActions.closeModal())
  }

  return (
    <div className="modal-delete-message">
      <div className="modal__title text-center">Suppression de widget</div>
      <div
        className="modal__grip text-center"
        dangerouslySetInnerHTML={{
          __html: `Voulez-vous vraiment supprimer <strong>${modalProps.widget.name}</strong> ?`,
        }}
      />
      <div className="flex justify-between">
        <Button btnStyle="plain" onClickFn={deleteWidget}>
          Supprimer
        </Button>
        <Button btnStyle="border" color="grey-lighter" onClickFn={closeModal}>
          Annuler
        </Button>
      </div>
    </div>
  )
}
