import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from 'components/layout/Layout'
import Button from 'components/buttons/Button'
import { accountActions } from '_actions'
import HeaderWithTabs from 'components/_account/HeaderWithTabs'

import { accountEmpty } from '_services/Utils'
import { IconCheck, IconCopy, IconWordpress } from '_svg'

function AccountPlugins() {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.authentication.user)
  const account = useSelector((state) => state.account)

  useEffect(() => {
    if (accountEmpty(account)) dispatch(accountActions.getSelf())
  }, [dispatch, account, user])

  const [copied, setCopied] = useState({})

  const handleCopy = async (value, slug) => {
    setCopied((prev) => ({ ...prev, [slug]: true }))

    await navigator.clipboard.writeText(value)

    setTimeout(() => {
      setCopied((prev) => ({ ...prev, [slug]: false }))
    }, 2000)
  }

  useEffect(() => {
    if (!account?.plugins) return
    const copied = Object.fromEntries(account.plugins.map((el) => [el.slug, false]))
    setCopied(copied)
  }, [account])

  const getPluginIcon = (slug) => {
    if (slug === 'wordpress') {
      return (
        <span className="mr-2">
          <IconWordpress width={30} />
        </span>
      )
    }

    return null
  }

  return (
    <Layout className="page page-account-plugins">
      <HeaderWithTabs />
      <div className="page__content">
        <div className="fluid-grid">
          <div className="row">
            <div className="col-6">
              <div className="page__title">Plugins</div>
              <div className="mt-3">
                {account.plugins &&
                  account.plugins.map((plugin, i) => {
                    return (
                      <div key={`plugin-${i}`}>
                        <div className="text-24 mt-10 flex">
                          {getPluginIcon(plugin.slug)}
                          {plugin.name}
                        </div>
                        <span className="label label--med mt-2">
                          Version : <strong>{plugin.version}</strong>
                        </span>
                        <label htmlFor="pluginName" className="label">
                          Clé d'activation
                        </label>
                        <div className="flex input-copy-paste">
                          <input
                            readOnly
                            type="text"
                            id="pluginName"
                            className="text-input"
                            defaultValue={plugin.key}
                          />
                          <Button btnStyle="plain" onClickFn={() => handleCopy(plugin.key, plugin.slug)}>
                            {copied[plugin.slug] ? <IconCheck width={26} /> : <IconCopy width={26} />}
                          </Button>
                        </div>
                        <a href={plugin.url} target="_blank" rel="noreferrer" className="mt-5 button button--border">
                          Télécharger
                        </a>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AccountPlugins
