import { Select } from 'antd'
import Button from 'components/buttons/Button'
import { useDispatch } from 'react-redux'
import React, { useState, useRef } from 'react'
import { messagesActions } from '_actions'
import useSingleton from 'components/utils/useSingleton'
import { IconCheck } from '_svg'

export default function CreateMessage({ ...modalProps }) {
  const { Option } = Select
  const [textValue, setTextValue] = useState('')
  const [commentTextValue, setCommentTextValue] = useState('')
  const [translations, setTranslations] = useState({
    fr: modalProps.msg?.translations?.fr ?? '',
    en: modalProps.msg?.translations?.en ?? '',
    de: modalProps.msg?.translations?.de ?? '',
    es: modalProps.msg?.translations?.es ?? '',
    it: modalProps.msg?.translations?.it ?? '',
    pt: modalProps.msg?.translations?.pt ?? '',
  })
  const [commentTranslations, setCommentTranslations] = useState({
    fr: modalProps.msg?.commentTranslations?.fr ?? '',
    en: modalProps.msg?.commentTranslations?.en ?? '',
    de: modalProps.msg?.commentTranslations?.de ?? '',
    es: modalProps.msg?.commentTranslations?.es ?? '',
    it: modalProps.msg?.commentTranslations?.it ?? '',
    pt: modalProps.msg?.commentTranslations?.pt ?? '',
  })

  const [activeTs, setActiveTs] = useState('fr')
  const dispatch = useDispatch()

  const txtArea = useRef()
  const commentTxtArea = useRef()

  useSingleton(() => {
    // will always be triggered once
    if (modalProps.msg) setTextValue(modalProps.msg.translations.fr)
    if (modalProps.msg) setCommentTextValue(modalProps.msg.commentTranslations?.fr ?? "")
  })

  const submitForm = (e) => {
    e.preventDefault()
    if (modalProps.msg) {
      dispatch(messagesActions.updateMessage({ id: modalProps.msg.id, translations: translations, commentTranslations: commentTranslations }))
    } else {
      dispatch(messagesActions.createMessage(translations, commentTranslations))
    }
  }

  const handleTextChange = (e, context) => {
    if (context === "translations") {
      setTextValue(e.target.value)
      setTranslations({
        ...translations,
        [activeTs]: e.target.value,
      })
    } else if (context === "commentTranslations") {
      setCommentTextValue(e.target.value)
      setCommentTranslations({
        ...commentTranslations,
        [activeTs]: e.target.value,
      })
    }
  }


  const keydownHandler = (e, currentTarget) => {
    console.log(currentTarget)
    if (e.keyCode === 8) {
      // delete
      const caret = getCaretPosition(currentTarget)
      const text = currentTarget.value
      let prevChar = caret - 1
      let begin = prevChar
      if (text.charAt(prevChar) === ']') {
        while (begin >= -1) {
          if (text.charAt(begin) === '[') break
          else begin--
        }
        if (begin > -1) {
          setSelection(begin, prevChar, currentTarget)
        }
      }
    } else if (e.keyCode === 46) {
      // suppr
      const caret = getCaretPosition(currentTarget)
      const text = currentTarget.value
      let nextChar = caret
      let end = nextChar
      if (text.charAt(nextChar) === '[') {
        while (end < text.length) {
          if (text.charAt(end) === ']') break
          else end++
        }
        if (end < text.length) {
          setSelection(nextChar, end, currentTarget)
        }
      }
    } else if (e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
      handleTextareaClick(e, currentTarget)
    }
  }

  const handleTextareaClick = (e, currentTarget) => {
    const caret = getCaretPosition(currentTarget)
    const text = currentTarget.value
    let begin = caret - 1
    while (begin >= 0) {
      if (text.charAt(begin) === ']') return
      else if (text.charAt(begin) === '[') break
      else begin--
    }

    if (begin >= 0) {
      var end = caret
      while (end < text.length) {
        if (text.charAt(end) === ']') break
        else end++
      }
      if (end < text.length) setSelection(begin, end, currentTarget)
    }
  }

  const getCaretPosition = (textarea) => {
    if (textarea.selectionStart) {
      return textarea.selectionStart
    } else {
      // TODO: Handle IE ugliness on your own :)
      return 0
    }
  }

  const setSelection = (begin, end, textarea) => {
    if ('selectionStart' in textarea) {
      textarea.selectionStart = begin
      textarea.selectionEnd = end + 1
    } else if (document.selection) {
      // TODO: Handle IE ugliness on your own :)
      return
    }
  }

  return (
    <div className="modal-create-message">
      <div className="modal__title">Création message template</div>
      <div className="modal__grip ant-white">
        <div className="label label--med">Langues</div>
        {/* <Select
          style={{ width: 120, color: '#4b4b4b' }}
          name="lang"
          value={activeTs}
          onChange={(value) => {
            setActiveTs(value)
            setTextValue(translations[value])
          }}
        >
          <Option value={'fr'}>FR</Option>
          <Option value={'en'}>EN</Option>
          <Option value={'de'}>DE</Option>
          <Option value={'it'}>IT</Option>
          <Option value={'pt'}>PT</Option>
          <Option value={'es'}>ES</Option>
        </Select> */}
        {Object.keys(translations).map(lang =>
          <>
            <Button customStyle={{ marginRight: "8px" }} btnStyle={activeTs === lang ? "plain" : "border"} onClickFn={() => {
              setActiveTs(lang)
              setTextValue(translations[lang])
              setCommentTextValue(commentTranslations[lang])
            }}>{lang} {(translations[lang] !== "" && commentTranslations[lang] !== "") ? "✅" : <></>}</Button>
          </>
        )}
      </div>
      <form className="flex flex-col items-start" onSubmit={submitForm}>
        <div className="label label--med">Votre message (messagerie privée Instagram)</div>
        <textarea
          autoFocus
          ref={txtArea}
          value={textValue}
          onKeyDown={e => {
            keydownHandler(e, txtArea.current)
          }}
          onChange={e => {
            handleTextChange(e, "translations")
          }}
          onClick={e => {
            handleTextareaClick(e, txtArea.current)
          }}
        ></textarea>
        <div className="label label--med">Commentaire associé</div>
        <textarea
          autoFocus
          ref={commentTxtArea}
          value={commentTextValue}
          onKeyDown={e => {
            keydownHandler(e, commentTxtArea.current)
          }}
          onChange={e => {
            handleTextChange(e, "commentTranslations")
          }}
          onClick={e => {
            handleTextareaClick(e, commentTxtArea.current)
          }}
          style={{height: "100px"}}
        ></textarea>
        {Object.values(translations).filter(ts => ts !== "" && ts !== null).length > 0 && (
          <Button btnStyle="plain" type="submit" addedclass={'self-end mt-6'}>
            {modalProps.msg ? 'Modifier' : 'Sauvegarder'}
          </Button>
        )}
      </form>
    </div>
  )
}
