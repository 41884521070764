import ModalRoot from 'components/modals/ModalRoot'
import MainNav from 'components/navigation/MainNav'
import NoticeRoot from 'components/notices/NoticeRoot'
import MobileBlocker from 'components/layout/MobileBlocker'
import { useEffect } from 'react'

export default function Layout({ className = '', children }) {
  useEffect(() => {
    const domain = window.location.hostname

    if (!['localhost','app.colegram.io', 'app-dev.colegram.io'].includes(domain)) {
      window.location = window.location.href.replace(window.location.hostname, 'app.colegram.io')
    }
  }, [])

  return (
    <main className={`layout flex ${className}`}>
      <MainNav />
      <div className="layout__content">{children}</div>
      <ModalRoot />
      <NoticeRoot />
      <MobileBlocker />
    </main>
  )
}
