// import { history } from '_helpers';

import { collectionsConstants } from '_constants'
import { collectionsService } from '_services'
import { alertActions, modalActions, noticesActions, libraryActions } from '.'

export const collectionsActions = {
  getAssets,
  updateAsset,
  deleteAssets,
  getCollections,
  shareCollection,
  createCollection,
  deleteCollection,
  updateCollection,
}

function getCollections() {
  return (dispatch) => {
    // dispatch(request());
    collectionsService.getCollections().then(
      (collections) => {
        dispatch(success(collections))
      },
      (error) => {
        // dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: collectionsConstants.GET_ALL } }
  function success(collections) {
    return { type: collectionsConstants.GET_COLLECTIONS, ...collections }
  }
  // function failure(error) { return { type: collectionsConstants.LOGIN_FAILURE, error } }
}

function getAssets(collectionId, options) {
  return (dispatch) => {
    // dispatch(request());
    collectionsService.getAssets(collectionId, options).then(
      (assets) => {
        dispatch(success({ ...assets, ...collectionId }))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: collectionsConstants.GET_ALL } }
  function success(data) {
    return { type: collectionsConstants.GET_ASSETS, data }
  }
  function failure(error) {
    return { type: collectionsConstants.CREATE_FAILURE, error }
  }
}

function createCollection(collectionItem) {
  return (dispatch) => {
    collectionsService.createCollection(collectionItem).then(
      (data) => {
        const item = { ...collectionItem, ...data }
        dispatch(success(item))
        dispatch(noticesActions.collectionCreated(item))
        if (collectionItem.mediaIDtoGoBack) {
          dispatch(modalActions.addToCollection(collectionItem.mediaIDtoGoBack))
        } else {
          dispatch(modalActions.closeModal())
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(item) {
    return { type: collectionsConstants.CREATE_COLLECTION, item }
  }
}

function deleteCollection(collectionItem) {
  return (dispatch) => {
    collectionsService.deleteCollection(collectionItem.id).then(
      (data) => {
        const collection = { ...collectionItem, ...data }
        dispatch(success(collection))
        dispatch(noticesActions.collectionDeleted(collection))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(collection) {
    return { type: collectionsConstants.DELETE_COLLECTION, collection }
  }
}

function updateCollection(collectionItem, mediaItem = null, deletedAssets = null) {
  return (dispatch) => {
    collectionsService.updateCollection(collectionItem).then(
      (collection) => {
        dispatch(success(collection))
        if (deletedAssets) {
          deletedAssets.forEach((el, i) => {
            setTimeout(() => {
              dispatch(noticesActions.deletedFromCollection(collectionItem, el))
            }, i * 200)
          })
        } else if (mediaItem) {
          mediaItem.forEach((el, i) => {
            setTimeout(() => {
              dispatch(noticesActions.addedToCollection(collectionItem, el))
              dispatch(libraryActions.getAsset(el))
            }, i * 200)
          })
        }
        // dispatch(libraryActions.getAllAssets())
        dispatch(getAssets(collection.id))
        dispatch(modalActions.closeModal())
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(collection) {
    return { type: collectionsConstants.UPDATE_COLLECTION, collection }
  }
}

function deleteAssets(mediaId) {
  return (dispatch) => {
    collectionsService.deleteAssets(mediaId).then(
      (mediaID) => {
        dispatch(success(mediaID))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(mediaID) {
    return { type: collectionsConstants.DELETE_ASSETS, mediaID }
  }
}

function updateAsset(item) {
  return (dispatch) => {
    collectionsService.updateCollection(item).then(
      (item) => {
        dispatch(success(item))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(item) {
    return { type: collectionsConstants.UPDATE_ASSET, item }
  }
}

function shareCollection(data) {
  return (dispatch) => {
    collectionsService.shareCollection(data).then(
      (item) => {
        console.log('data   data data data', data, item)
        dispatch(success())
        dispatch(
          noticesActions.collectionShared({
            userName: data.user.full_name,
            collectionName: data.collection.name,
          })
        )
        dispatch(modalActions.closeModal())
      },
      (error) => dispatch(alertActions.error(error.toString()))
    )
  }

  function success() {
    return { type: collectionsConstants.SHARE_COLLECTION }
  }
}
