import { IconArrowUp } from '_svg'
import React, { useRef } from 'react'

export default function SortBar({ data, sortFunction }) {
  const sortingtItems = useRef([])
  sortingtItems.current = []

  const addToRefs = (el) => {
    if (el && !sortingtItems.current.includes(el)) {
      sortingtItems.current.push(el)
    }
  }

  const getItems = () => {
    return data.map((el, i) => {
      return (
        <li
          className={`sort-bar__item`}
          {...(el.sortingAttribute && { 'data-sort-by': el.sortingAttribute })}
          {...(el.sortingAttribute && { ref: addToRefs })}
          {...(el.sortingAttribute && { onClick: handleClick })}
          key={i}
        >
          <span className="sort-bar__item__label">{el.label}</span>
          {el.sortingAttribute && <IconArrowUp className="icon" />}
        </li>
      )
    })
  }

  const handleClick = (e) => {
    sortingtItems.current.forEach((el, i) => {
      el.classList.remove('is-sorting')
    })
    e.currentTarget.classList.add('is-sorting')
    sortFunction(e.currentTarget.getAttribute('data-sort-by'))
  }

  return <ul className="sort-bar list-reset flex justify-between">{getItems()}</ul>
}
