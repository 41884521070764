import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { feedActions } from '_actions'
import Button from 'components/buttons/Button'

export default function AddFeedMediaManually({ feedID, callback }) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [instagramUrl, setInstagramUrl] = useState(null)
  const [error, setError] = useState(null)

  const submitForm = (e) => {
    e.preventDefault()

    // Check if the URL is valid
    if (instagramUrl.match(/https:\/\/www.instagram.com\/p\/[a-zA-Z0-9]+/g) == null) {
      setError("URL invalide, veuillez vérifier que l'URL est bien de la forme https://www.instagram.com/p/xxxxxx")
      return
    }

    setIsLoading(true)
    dispatch(feedActions.manuallyAddMedia(feedID, instagramUrl, handleResponse))
  }

  const handleResponse = ({ data, error }) => {
    setIsLoading(false)
    if (error) {
      setError(error)
    } else {
      callback()
    }
  }

  return (
    <div className="modal-manual-add-feed-asset">
      <div className="modal__title">Ajouter manuellement</div>
      <form className="flex flex-col items-start" onSubmit={submitForm}>
        <div className="label label--med">URL Instagram*</div>
        <input
          required
          type="url"
          name="instagram_url"
          className="text-input"
          value={instagramUrl ?? ''}
          onChange={(e) => setInstagramUrl(e.target.value)}
          placeholder="https://www.instagram.com/p/xxxxxxxxxx"
        />

        {error !== null && <div className="alert alert-danger w-full mt-3">{error}</div>}

        <Button
          type="submit"
          btnStyle="plain"
          loading={isLoading}
          onClickFn={submitForm}
          addedclass="self-end mt-6"
          disabled={instagramUrl === null || instagramUrl.length === 0}
        >
          Ajouter
        </Button>
      </form>
    </div>
  )
}
