import { userActions } from '_actions'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { LoginLeft, LoginRight } from '_svg'
import Button from 'components/buttons/Button'
import { useSelector, useDispatch } from 'react-redux'
import NoticeRoot from 'components/notices/NoticeRoot'
import HeaderShowcase from 'components/layout/HeaderShowcase'

// import { alertActions } from '_actions';

function ForgottenPassword() {
  // const user = useSelector(state => state.user)
  const alert = useSelector((state) => state.alert)

  const [inputs, setInputs] = useState({
    email: '',
  })
  const { email } = inputs
  const loggingIn = useSelector((state) => state.authentication.loggingIn)
  const dispatch = useDispatch()

  function handleChange(e) {
    const { name, value } = e.target
    setInputs((inputs) => ({ ...inputs, [name]: value }))
  }

  const submitForm = (e) => {
    e.preventDefault()
    if (email) {
      // get return url from location state or default to home page
      // const { from } = location.state || { from: { pathname: "/" } };
      dispatch(userActions.resetPassword(email))
    }
  }

  return (
    <div className="page-login">
      <HeaderShowcase />
      <div className="fluid-grid login-grid">
        <div className="row justify-center items-center">
          <div className="col-4">
            <LoginLeft className="shape-left" />
          </div>
          <form name="login-form col-4" onSubmit={submitForm}>
            <div className="">
              <Link to="/" className="sup-title sup-title--M leading-0 sup-title--white flex items-center">
                <span className="gen-text gen-text--XL gen-text--pink leading-0 mr-4">‹</span> Retour à la connexion
              </Link>
            </div>
            <div className="title mt-10">Mode passe oublié ?</div>
            <div className="mt-6 gen-text">
              Nous vous envoyons par email un lien pour réinitialiser votre mot de passe.
            </div>
            <div className="form-group mt-6 mx-auto">
              <input
                type="email"
                name="email"
                className="text-input"
                placeholder="Votre email"
                value={email}
                onChange={handleChange}
                required
              />
            </div>

            <Button loading={loggingIn} type="submit" btnStyle="plain" disabled={loggingIn} addedclass="mt-8 mx-auto">
              Réinitialiser votre mot de passe
            </Button>

            {alert.message && <div className={`alert ${alert.type} mt-6`}>{alert.message}</div>}
          </form>
          <div className="col-4">
            <LoginRight className="shape-right" />
          </div>
        </div>
      </div>
      <NoticeRoot />
    </div>
  )
}

export default ForgottenPassword
