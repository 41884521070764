import { history } from '_helpers'
import { feedConstants } from '_constants'
import { feedService } from '_services'
import { alertActions, modalActions, noticesActions } from '_actions'

export const feedActions = {
  getAllFeeds,
  createFeed,
  deleteFeed,
  deleteFeeds,
  updateFeed,
  getAllAssets,
  getAssets,
  deleteAsset,
  updateAsset,
  updateDipsplayMode,
  manuallyAddMedia,
}

function getAllFeeds(options) {
  return (dispatch) => {
    // dispatch(request());
    feedService.getAll(options).then(
      (feeds) => {
        dispatch(success(feeds))
      },
      (error) => {
        // dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(feeds) {
    return { type: feedConstants.GET_ALL, feeds }
  }
}

function manuallyAddMedia(feedId, url, callback) {
  return (dispatch) => {
    feedService.manuallyAddMedia(feedId, url).then(
      (data) => {
        dispatch(modalActions.closeModal())
        callback({ data })
      },
      (error) => callback({ error: error.message })
    )
  }
}

function createFeed(formData) {
  // name, user_id, locale, hashtags, description
  return (dispatch) => {
    console.log(formData)
    // dispatch(request());
    if (!formData.type) {
      dispatch(alertActions.error("Renseignez un type de feed."))
    } else if (formData.type === "location" && !formData.location_type) {
      dispatch(alertActions.error("Renseignez un type de localisation."))
    } else if (formData.hashtags.length === 0) {
      dispatch(alertActions.error("Renseignez une valeur de recherche."))
    } else {
      feedService.create(formData).then(
        (feed) => {
          dispatch(success(feed))
          history.push('/feed')
          dispatch(noticesActions.feedCreated(feed))
        },
        (error) => {
          // dispatch(failure(error.toString()));
          let msg = error.message
          switch (msg) {
            default:
              break
            case 'locale cannot be blank':
              msg = feedConstants.ERROR_LOCALE_REQUIRED
              break
            case 'feed quota exceeded':
              msg = feedConstants.ERROR_QUOTA_EXCEEDED
              break
            case 'hashtag quota exceeded':
              msg = feedConstants.ERROR_HASHTAGS_QUOTA_EXCEEDED
              break
            case 'error with hashtag(s)':
              let ht = error.infos.join(', ')
              msg = `${feedConstants.ERROR_HASHTAGS_INVALID} : ${ht}`
              break
          }
          dispatch(alertActions.error(msg.toString()))
        }
      )
    }
  }

  // function request() { return { type: feedConstants.GET_ALL } }
  function success(feed) {
    return { type: feedConstants.CREATE, feed }
  }
  // function failure(error) { return { type: feedConstants.CREATE_FAILURE, error } }
}

function updateFeed(feed) {
  // name, user_id, locale, hashtags, description
  return dispatch => {
    // dispatch(request());
    feedService.updateFeed(feed)
      .then(
        data => {
          dispatch(success(feed));
          history.push('/feed');
          dispatch(noticesActions.feedUpdated(feed))
        },
        error => {
          let msg = error.message
          switch (msg) {
            default: break;
            case 'feed quota exceeded': msg = feedConstants.ERROR_QUOTA_EXCEEDED; break;
            case 'hashtag quota exceeded': msg = feedConstants.ERROR_HASHTAGS_QUOTA_EXCEEDED; break;
            case 'error with hashtag(s)': let ht = error.infos.join(', '); msg = `${feedConstants.ERROR_HASHTAGS_INVALID} : ${ht}`; break;
          }
          dispatch(alertActions.error(msg.toString()));
          /* dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString())); */
        }
      );
  };

  // function request() { return { type: feedConstants.GET_ALL } }
  function success(feed) { return { type: feedConstants.UPDATE, feed } }
  // function failure(error) { return { type: feedConstants.DELETE_FAILURE, error } }
}


function deleteFeed(feed) {
  // name, user_id, locale, hashtags, description
  return (dispatch) => {
    // dispatch(request());
    feedService.deleteFeed(feed.id).then(
      (data) => {
        dispatch(success({ ...data, ...feed }))
        history.push('/feed')
        dispatch(noticesActions.feedDeleted(feed))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: feedConstants.GET_ALL } }
  function success(feed) {
    return { type: feedConstants.DELETE, feed }
  }
  function failure(error) {
    return { type: feedConstants.DELETE_FAILURE, error }
  }
}

function deleteFeeds(feeds) {
  // name, user_id, locale, hashtags, description
  return (dispatch) => {
    // dispatch(request());
    feedService.deleteFeeds(feeds).then(
      (data) => {
        dispatch(success({ ...data, ...feeds }))
        history.push('/feed')
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: feedConstants.GET_ALL } }
  function success(feed) {
    return { type: feedConstants.DELETE, feed }
  }
  function failure(error) {
    return { type: feedConstants.DELETE_FAILURE, error }
  }
}

function getAllAssets(options) {
  return (dispatch) => {
    // dispatch(request());
    feedService.getAllAssets(options).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: feedConstants.GET_ALL } }
  function success(data) {
    return { type: feedConstants.GET_ALL_ASSETS, data }
  }
  function failure(error) {
    return { type: feedConstants.CREATE_FAILURE, error }
  }
}

function getAssets(feedId, options) {
  return (dispatch) => {
    // dispatch(request());
    feedService.getAssets(feedId, options).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: feedConstants.GET_ALL } }
  function success(data) {
    return { type: feedConstants.GET_ASSETS, data }
  }
  function failure(error) {
    return { type: feedConstants.CREATE_FAILURE, error }
  }
}

function deleteAsset(assets, feedId, callback) {
  return (dispatch) => {
    // dispatch(request());
    feedService.deleteAsset(assets, feedId).then(
      (data) => {
        if (callback) {
          callback()
        }
        dispatch(success(data))
        assets.forEach((asset, i) => {
          setTimeout(dispatch(noticesActions.assetRejected(asset)), i * 700)
        })
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: feedConstants.GET_ALL } }
  function success(data) {
    return { type: feedConstants.DELETE_ASSET, data }
  }
  function failure(error) {
    return { type: feedConstants.CREATE_FAILURE, error }
  }
}

function updateAsset(data) {
  return (dispatch) => {
    dispatch(success(data))
  }

  // function request() { return { type: feedConstants.GET_ALL } }
  function success(data) {
    return { type: feedConstants.UPDATE_ASSET, data }
  }
  // function failure(error) { return { type: feedConstants.DELETE_FAILURE, error } }
}

function updateDipsplayMode(mode) {
  return (dispatch) => {
    dispatch({ type: feedConstants.UPDATE_DISPLAYMODE, displayMode: mode })
  }
}
