import PageHeader from 'components/layout/PageHeader'

export default function HeaderWithTabs({ widget }) {
  return (
    <PageHeader
      data={[{ label: 'Widgets', url: '/widgets' }, { label: widget ? widget.name : '' }]}
      tabs={[
        { label: 'Réglages', url: `/widgets/${widget ? `${widget.id}/settings` : ''}` },
        // { label: 'Statistiques', url: `/widgets/${widget ? `${widget.id}/statistics` : ''}` },
      ]}
    />
  )
}
