import React, { useEffect, useState } from 'react'
import Layout from 'components/layout/Layout'
import PageHeader from 'components/layout/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import { collectionsActions, modalActions } from '_actions'
import Button from 'components/buttons/Button'
import CollectionListItem from 'components/_collections/CollectionListItem'
import Pagination from 'components/navigation/Pagination'
import useSingleton from 'components/utils/useSingleton'

function Collections() {
  const collectionsRdx = useSelector((state) => state.collections)
  // const collections = useSelector(state => state.collections.items);
  const [isLoading, setIsLoading] = useState(false)
  const [collections, setCollections] = useState([])
  // const [selectedItems, setSelectedItems] = useState([])

  const [pagination] = useState({ current_page: 1, limit: 20, total_count: 0 })

  // pagnation & filters
  const [requestOptions, setRequestOptions] = useState({
    q: '',
    page: 1,
    limit: 20,
    // media_type: 'IMAGE,VIDEO,SIDECAR',
    sort_by: 'timestamp',
    sort_order: 'DESC',
  })

  const dispatch = useDispatch()

  useEffect(() => {
    //
    setCollections(collectionsRdx.items)
    setIsLoading(false)
  }, [collectionsRdx])

  const loadCollections = () => {
    if (isLoading) return
    setIsLoading(true)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    dispatch(collectionsActions.getCollections())
  }

  useSingleton(() => {
    // will always be triggered once
    loadCollections()
  })

  const handleCreateCollection = () => {
    dispatch(modalActions.createCollection())
  }

  const handlePagination = (index) => {
    const temp = {
      page: index,
    }
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const getCollectionsList = () => {
    if (!collections) return
    if (collections.length > 0) {
      return collections.map((el, i) => {
        return (
          <li key={el.id} className="stagger-anim">
            <CollectionListItem data={el} />
          </li>
        )
      })
    } else {
      return (
        <li className="mt-6 gen-text stagger-anim">
          Vous n'avez encore créé aucune collection.{' '}
          <span onClick={handleCreateCollection} className="info">
            Créez-en une maintenant&nbsp;!
          </span>
        </li>
      )
    }
  }

  return (
    <Layout className="page page-collections">
      <PageHeader
        data={[
          { label: 'Collections' },
        ]} /*  tabs={[{label: 'Toute la bibliothèque', url:`/collections`}, {label: 'Collections', url:`/collections`}]} */
      />
      <div className="page__content">
        <div className="sticky-wrapper">
          <div className="flex justify-between items-end">
            <div className="page__title">Toutes les Collections</div>
            <Button btnStyle="plain" onClickFn={handleCreateCollection}>
              Créer une collection
            </Button>
          </div>
        </div>
        <ul className={`collections-list flex flex-wrap medias-list list-reset ${isLoading ? 'is-loading' : ''}`}>
          {getCollectionsList()}
        </ul>
        {pagination && <Pagination data={pagination} requestFn={handlePagination} />}
      </div>
    </Layout>
  )
}

export default Collections
