import { IconPlus } from '_svg'

export default function HashtagListGroup({ cpt, setExpanded }) {
  return (
    <div className="hashtag-list__item">
      <div className="hashtag-list__item-label">{`et ${cpt} autres`}</div>
      <div
        className="bt-remove"
        onClick={() => {
          setExpanded(true)
        }}
      >
        <IconPlus
          style={{
            height: 10
          }}
          className="icon" />
      </div>
    </div>
  )
}
