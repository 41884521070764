import { ThemeSliderActive, ThemeWall, ThemeSlider, ThemeWallActive } from '_svg'

export default function ThemeButton({ value, label, onChangeTheme, isDisabled, isDefaultChecked = false }) {
  const handleChange = (e) => {
    if (e.currentTarget.className.substring(0, 8) === 'inactive' && !isDisabled) onChangeTheme(value.toString())
  }

  return (
    <div
      className={isDefaultChecked ? 'active theme-button text-center' : 'inactive theme-button text-center'}
      onClick={handleChange}
    >
      {value === 'wall' && !isDefaultChecked && <ThemeWall />}
      {value === 'wall' && isDefaultChecked && <ThemeWallActive />}
      {value === 'slider' && !isDefaultChecked && <ThemeSlider />}
      {value === 'slider' && isDefaultChecked && <ThemeSliderActive />}
      <div className="text-center">
        <strong>
          <label className={isDisabled ? 'gen-text gen-text--XS gen-text--grey' : 'gen-text gen-text--XS'}>
            {label}
            {isDisabled && (
              <>
                <br /> (Prochainement)
              </>
            )}
          </label>
        </strong>
      </div>
    </div>
  )
}
