import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { collectionsActions, modalActions } from '_actions'
import Button from 'components/buttons/Button'

export default function DeleteFromCollection({ ...modalProps }) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const closeModal = () => {
    dispatch(modalActions.closeModal())
  }

  const deleteFromCollection = () => {
    setIsLoading(true)
    const assets_ids = modalProps.collection.assets_ids.filter((assetId) => {
      return !modalProps.assets.map(asset => asset.id).includes(assetId)
    })

    const name = modalProps.collection.name
    const id = modalProps.collection.id

    dispatch(collectionsActions.updateCollection({ name, id, assets_ids }, null, modalProps.assets))
    // dispatch(modalActions.closeModal())
  }

  return (
    <div className="modal-delete-message">
      <div className="modal__title text-center">Suppression de média{modalProps.assets.length > 1 ? 's' : ''}</div>
      <div
        className="modal__grip text-center"
        dangerouslySetInnerHTML={{
          __html: `Voulez-vous vraiment retirer ce${modalProps.assets.length > 1 ? 's' : ''} media${
            modalProps.assets.length > 1 ? 's' : ''
          } de la collection <strong>${modalProps.collection.name}</strong> ?<br/>Ce${
            modalProps.assets.length > 1 ? 's' : ''
          } contenu${modalProps.assets.length > 1 ? 's' : ''} rester${
            modalProps.assets.length > 1 ? 'ont' : 'a'
          } disponible${modalProps.assets.length > 1 ? 's' : ''} dans votre bibliothèque.`,
        }}
      />
      <div className="flex justify-between">
        <Button
          btnStyle="plain"
          loading={isLoading}
          disabled={isLoading}
          onClickFn={deleteFromCollection}
          color={isLoading ? 'grey-lighter' : 'red'}
        >
          Supprimer
        </Button>
        <Button btnStyle="border" color="grey-lighter" onClickFn={closeModal}>
          Annuler
        </Button>
      </div>
    </div>
  )
}
