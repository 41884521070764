import { IconSearch } from '_svg'
import React, { useEffect, useState } from 'react'
import HashtagsList from './HashtagsList'
import CheckBoxesModule from './CheckBoxesModule'

export default function SearchModule({ callback, placeholder = '' }) {
  const [terms, setTerms] = useState([])
  const [operatorAnd, setOperatorAnd] = useState(false)

  useEffect(() => {
    callback(terms.join(","), operatorAnd)
  }, [terms, operatorAnd])

  return (
    <>
      <HashtagsList title={"Rechercher"} theme={'dark'} preselection={[]} hashtagsCount={-1} limit={-1} callback={hashtags => {
        setTerms(hashtags)
      }} />
      <CheckBoxesModule
        items={[
          { label: 'Inclure tous les termes', value: 'operatorAnd' },
        ]}
        callback={value => setOperatorAnd(value.operatorAnd)}
      />
    </>
  )
}
