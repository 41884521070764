import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { libraryActions } from '_actions'
import Button from 'components/buttons/Button'

export default function AuthorName({ ...modalProps }) {
  const [assetData, setAssetData] = useState({
    ...modalProps.asset[0],
    authorName: modalProps.asset[0].authorName ?? '@',
    authorUrl: modalProps.asset[0].authorUrl ?? '',
  })
  const dispatch = useDispatch()

  const submitForm = (e) => {
    e.preventDefault()
    dispatch(libraryActions.updateAsset(assetData))
  }

  const handleAuthorNameChange = (e) => {
    setAssetData({ ...assetData, authorName: e.target.value })
  }

  const handleAuthorUrlChange = (e) => {
    setAssetData({ ...assetData, authorUrl: e.target.value })
  }

  return (
    <div className="modal-create-message">
      <div className="modal__title">Mentionner l'auteur</div>
      <form className="flex flex-col items-start" onSubmit={submitForm}>
        <div className="label label--med">Nom de l'auteur</div>
        <input
          type="text"
          className="input-text-grey"
          value={assetData.authorName}
          placeholder="Nom d'utilisateur"
          onChange={handleAuthorNameChange}
        />
        <div className="label label--med">Lien vers la page Instagram</div>
        <input
          type="text"
          className="input-text-grey"
          value={assetData.authorUrl}
          placeholder="Lien Instagram"
          onChange={handleAuthorUrlChange}
        />
        {assetData.authorName.length > 1 && assetData.authorUrl.length > 1 && (
          <Button btnStyle="plain" type="submit" addedclass="self-end mt-6">
            {modalProps.msg ? 'Modifier' : 'Sauvegarder'}
          </Button>
        )}
      </form>
    </div>
  )
}
