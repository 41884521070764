import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

function PrivateRoute({ component: Component, roles, ...rest }) {
  const user = localStorage.getItem('user')

  useEffect(() => {
    // if (userInfos?.id && userInfos.id !== "fd3c9c3d-35b8-40e1-8bd0-0b563d4ec99d") {
    //     history.push("/maintenance")
    // }
  }, [user])

  return (
    <Route
      {...rest}
      render={(props) => {
        // console.log('test route : ',localStorage.getItem('user'),props.location.pathname , JSON.parse(user).has_facebook_credentials )
        if (!user) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          // } else if (!JSON.parse(localStorage.getItem('user')).has_facebook_credentials && props.location.pathname !== '/welcome') {
          // } else if (!JSON.parse(user).has_facebook_credentials && props.location.pathname !== '/welcome') {
          //   // not logged with facebookConnect
          //   return <Redirect to={{ pathname: '/welcome', state: { from: props.location } }} />
        } else {
          // logged in so return component
          return <Component {...props} />
        }
      }}
    />
  )
}

export { PrivateRoute }
