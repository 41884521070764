import 'moment/locale/fr'
import moment from 'moment'
import { useState } from 'react'
import { IconCopy, IconCheck } from '_svg'
import Button from 'components/buttons/Button'

export default function Sidebar({ data }) {
  const [copyEmbeddedConfirmed, setCopyEmbeddedConfirmed] = useState(false)
  const [copyJSONConfirmed, setCopyJSONConfirmed] = useState(false)

  const copyJSON = async (e) => {
    setCopyJSONConfirmed(true)
    await navigator.clipboard.writeText(`${process.env.REACT_APP_API_URL}/widget/${data.hash}.json`)
    setTimeout(() => {
      setCopyJSONConfirmed(false)
    }, 2000)
  }

  const copyEmbedded = async (e) => {
    setCopyEmbeddedConfirmed(true)
    await navigator.clipboard.writeText(`${process.env.REACT_APP_API_URL}/widget/${data.hash}.js`)
    setTimeout(() => {
      setCopyEmbeddedConfirmed(false)
    }, 2000)
  }

  return (
    <>
      <div className="sidebar__title">{data.name}</div>
      <div className="block label label--med">Date de création</div>
      <span className="block gen-text">{moment(data.created_at).locale('fr').format('L')}</span>
      <div className="block label label--med">Nombre de médias</div>
      <span className="block gen-text">{data.limit}</span>
      <div className="block label label--med">URL du widget</div>
      <div className="flex input-copy-paste">
        <input
          type="text"
          name="widgetEmbedded"
          id="widgetEmbedded"
          className="text-input"
          defaultValue={data.hash && `${process.env.REACT_APP_API_URL}/widget/${data.hash}.js`}
          readOnly
        />
        <Button btnStyle="plain" onClickFn={copyEmbedded}>
          {copyEmbeddedConfirmed ? <IconCheck /> : <IconCopy />}
        </Button>
      </div>
      <span color="#000">
        Le widget ne comprends pas de background et s'adaptera à votre site web et son affichage responsive
      </span>

      <div className="block label label--med">URL du flux JSON</div>
      <div className="flex input-copy-paste">
        <input
          type="text"
          name="widgetJSON"
          id="widgetJSON"
          className="text-input"
          defaultValue={data.hash && `${process.env.REACT_APP_API_URL}/widget/${data.hash}.json`}
          readOnly
        />
        <Button btnStyle="plain" onClickFn={copyJSON}>
          {copyJSONConfirmed ? <IconCheck /> : <IconCopy />}
        </Button>
      </div>
    </>
  )
}
