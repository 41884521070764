import { Smartphone, IconComment } from '_svg'
import React, { useEffect, useState } from 'react'
import { useScrollBlock } from '_helpers/block-scroll'

export default function MobileBlocker() {
  const [windowSize, setWindowSize] = useState(getWindowSize())
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (windowSize.innerWidth < 1024) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [windowSize])

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  return (
    windowSize.innerWidth < 1024 && (
      <div className="mobile-blocker fluid-grid">
        <div className="row">
          <div className="col-12 flex flex-col items-center">
            <div className="visual">
              <Smartphone className="smartphone" />
              <div className="gradient">
                <div className="picto">
                  <IconComment className="icon icon--white" />!
                </div>
              </div>
            </div>

            <div className="text-gradient text-gradient--H mx-auto mt-20">
              Pour une meilleure expérience de notre service, veuillez vous connecter sur un ordinateur.
            </div>
          </div>
        </div>
      </div>
    )
  )
}
