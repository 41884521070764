import { Select } from 'antd'
import { IconPlus } from '_svg'
import Layout from 'components/layout/Layout'
import Button from 'components/buttons/Button'
import React, { useEffect, useState } from 'react'
import PageHeader from 'components/layout/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import { landingsActions, modalActions, templatesActions } from '_actions'
import ThemeButton from 'components/buttons/ThemeButton'
import CollectionListItem from 'components/_collections/CollectionListItem'
import SidebarItem from 'components/_landings/SidebarItem'

function LandingCreation() {
  const dispatch = useDispatch()
  const { Option } = Select
  const templatesRdux = useSelector((state) => state.templates)
  const alert = useSelector((state) => state.alert)
  const [isLoading, setIsLoading] = useState(false)
  const [hasSidebarConfig, setHasSidebarConfig] = useState(false)

  const [landing, setLanding] = useState({
    title: null,
    subtitle: null,
    slug: null,
    ctaLink: null,
    limit: 10,
    status: '0',
    collections: [],
    sort_by: 'random'
  })

  const landingCreationCollection = useSelector((state) => state.landings.create.collection)

  useEffect(() => {
    const temp = {}
    if (!landingCreationCollection.id) return
    temp.collections = [landingCreationCollection.id]
    setLanding({ ...landing, ...temp })
  }, [landingCreationCollection])

  useEffect(() => {
    if (alert.message !== '') setIsLoading(false)
  }, [alert])

  useEffect(() => {
    dispatch(templatesActions.getAllTemplates({
      q: '',
      page: 1,
      limit: 20,
      sort_by: 'last_update',
      sort_order: 'DESC',
    }))
  }, [])

  const submitForm = (e) => {
    e.preventDefault()
    setIsLoading(true)
    dispatch(landingsActions.createLanding(landing))
  }

  const chooseCollection = (e) => {
    dispatch(modalActions.chooseCollection("landing"))
  }

  const getCollection = () => {
    if (landingCreationCollection.id) return <CollectionListItem data={landingCreationCollection} />
  }

  const getTemplateInfos = (value) => {
    const myTemplate = templatesRdux.items.find((el) => el.id === value)

    setHasSidebarConfig(myTemplate.type === 'sidebar')
  }

  const addSidebarItem = () => {
    const temp = {}
    temp.sidebarItems = landing.sidebarItems

    temp.sidebarItems.push({
      'title': null,
      'desc': null,
      'stars': null,
      'tag': null,
      'link': null,
      'pic': null
    })

    setLanding({ ...landing, ...temp })
  }

  return (
    <Layout className="page page-landing-creation">
      <PageHeader data={[{ label: 'Landings', url: '/landing-pages' }, { label: 'Créer une landing page' }]} />
      <div className="page__content">
        <div className="page__intro">
          Configurez votre landing en remplissant les champs ci dessous et suivez les instructions d'implémentation sur
          votre canal de distribution.
        </div>
        <div className="fluid-grid">
          <form action="" onSubmit={submitForm}>
            <div className="row mt-20">
              <div className="col-12 md-col-6">
                <p className="text-24">Général</p>
                <label htmlFor="title" className="label">
                  Titre de votre landing
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  className="text-input"
                  placeholder="Titre*"
                  required
                  onChange={(e) => {
                    const temp = {}
                    temp.title = e.currentTarget.value
                    setLanding({ ...landing, ...temp })
                  }}
                />
                <label htmlFor="slug" className="label">
                  Slug de votre landing (pour l'url)
                </label>
                <input
                  type="text"
                  name="slug"
                  id="slug"
                  className="text-input"
                  placeholder="Slug*"
                  required
                  onChange={(e) => {
                    const temp = {}
                    temp.slug = e.currentTarget.value
                    setLanding({ ...landing, ...temp })
                  }}
                />
                <label htmlFor="template" className="label">
                  Template
                </label>
                <Select
                  name="template"
                  onChange={(value) => {
                    const temp = {}
                    temp.template = value
                    temp.sidebarTitle = null
                    temp.sidebarItems = []
                    setLanding({ ...landing, ...temp })

                    getTemplateInfos(value)
                  }}
                >
                  {templatesRdux && templatesRdux.items.map((t, key) => <Option key={key} value={t.id}>{t.name}</Option>)}
                </Select>
                <label htmlFor="subtitle" className="label">
                  Sous-titre de votre landing
                </label>
                <input
                  type="text"
                  name="subtitle"
                  id="subtitle"
                  className="text-input"
                  placeholder="Sous-titre*"
                  required
                  onChange={(e) => {
                    const temp = {}
                    temp.subtitle = e.currentTarget.value
                    setLanding({ ...landing, ...temp })
                  }}
                />
                <label htmlFor="ctaLink" className="label">
                  Lien du bouton
                </label>
                <input
                  type="text"
                  name="ctaLink"
                  id="ctaLink"
                  className="text-input"
                  placeholder="Lien*"
                  required
                  onChange={(e) => {
                    const temp = {}
                    temp.ctaLink = e.currentTarget.value
                    setLanding({ ...landing, ...temp })
                  }}
                />

                <label htmlFor="limit" className="label">
                  Nombre de medias maximum
                </label>
                <Select
                  name="limit"
                  defaultValue="10"
                  onChange={(value) => {
                    const temp = {}
                    temp.limit = value
                    setLanding({ ...landing, ...temp })
                  }}
                >
                  <Option value="10">10</Option>
                  <Option value="20">20</Option>
                  <Option value="50">50</Option>
                </Select>

                { hasSidebarConfig && 
                  <>
                    <p className="text-24 mt-10">Configuration sidebar</p>
                    <label htmlFor="sidebarTitle" className="label">
                      Titre de la sidebar
                    </label>
                    <input
                      type="text"
                      name="sidebarTitle"
                      id="sidebarTitle"
                      defaultValue={landing.sidebarTitle}
                      className="text-input"
                      required
                      onChange={(e) => {
                        const temp = {}
                        temp.sidebarTitle = e.currentTarget.value
                        setLanding({ ...landing, ...temp })
                      }}
                    />
                    
                    <p className='label'>Eléments de la sidebar</p>
                    
                    {landing.sidebarItems.length > 0 &&
                      <ul className='collection-wrap'>
                        {landing.sidebarItems.map((item, i) => {
                          return (
                            <SidebarItem key={i} item={item} i={i} setLanding={setLanding} landing={landing} open={item.title === null ? true : false} /> 
                          )
                        })}
                      </ul>
                    }

                    <button type="button" className='button-more' onClick={() => addSidebarItem() }><span><IconPlus className="icon" /></span>Ajouter un élément</button>
                  </>
                }

                <p className="text-24 mt-10">Source de la landing page</p>

                <label htmlFor="sorting" className="label">
                  Trier les medias par
                </label>
                <Select
                  name="sortBy"
                  defaultValue={landing.sort_by}
                  onChange={(value) => {
                    const temp = {}
                    temp.sort_by = value
                    setLanding({ ...landing, ...temp })
                  }}
                >
                  <Option value="random">Ordre aléatoire</Option>
                  <Option value="created_at">Date de création</Option>
                  {/* <Option value="timestamp">Date du post</Option> */}
                  <Option value="commentsCount">Nombre de commentaires</Option>
                  <Option value="likeCount">Nombre de likes</Option>
                </Select>
                <label htmlFor="locale" className="label">
                  Remontée
                </label>
                <Select name="source" defaultValue="by_collection">
                  <Option value="by_collection">Par collection</Option>
                </Select>
                <div className="selection-wrapper mt-6">
                  <div className="selection-wrapper-content">{getCollection()}</div>
                  <div className="selection-cta mt-6" onClick={chooseCollection}>
                    <div className="picto">
                      <IconPlus className="icon" />
                    </div>
                    {landing.collections.length > 0 ? 'changer de collection' : 'Lier à une collection'}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-10">
              <div className="col-12 md-col-6">
                &nbsp;
                {alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
              </div>
              <div className="col-12 md-col-6 flex flex-col justify-end items-end">
                <Button
                  type="submit"
                  btnStyle="plain"
                  loading={isLoading}
                  color={isLoading ? 'grey' : 'red'}
                  disabled={landing.collections.length <= 0 || isLoading}
                >
                  Créer la landing page
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default LandingCreation
