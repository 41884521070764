import axios from 'axios'
import axiosApi from './api'

export const landingsService = {
  getAll,
  create,
  deleteLanding,
  updateLanding,
  getSingleBySlug
}

function getParams(options) {
  if (!options) return ''
  let params = ''
  for (const [key, value] of Object.entries(options)) {
    params += `${key}=${value}&`
  }
  return params.slice(0, -1)
}

async function getAll(options) {
  const params = getParams(options)
  const res = await axiosApi.get(`${process.env.REACT_APP_API_URL}/api/landing?${params}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function getSingleBySlug(slug) {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/landings/${slug}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function create(datas) {
  const res = await axiosApi.post(`${process.env.REACT_APP_API_URL}/api/landing`, JSON.stringify(datas))
  if (res.status === 200) {
    const defaultProps = {
      id: res.data.id,
      landing: res.data.landing,
      /* assets_count: 0,
            memory: 0 */
    }
    return { ...datas, ...defaultProps }
  } else {
    console.log(res.status, res.statusText)
  }
}

async function deleteLanding(landingsId) {
  const res = await axiosApi.delete(`${process.env.REACT_APP_API_URL}/api/landing/${landingsId}`)
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}

async function updateLanding(landing) {
  const res = await axiosApi.put(`${process.env.REACT_APP_API_URL}/api/landing`, JSON.stringify(landing))
  if (res.status === 200) {
    return res.data
  } else {
    console.log(res.status, res.statusText)
  }
}
