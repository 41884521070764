import { ReactComponent as IconStar } from "../../_svg/icon-star.svg"

const ProductSidebar = ({ product }) => {
  return (
    <div>
      { product.pic &&
        <div className="landing-sidebar__pic">
          <img src={product.pic} alt="" />
        </div>
      }
      <p className="landing-product-sidebar__title">
        {product.title}
        {product.stars &&
          <span className="landing-product-sidebar__stars">
            { Array(parseInt(product.stars)).fill().map((el, i) => {
              return (
                <IconStar key={i} />
              )
            }) }
          </span>
        }
      </p>
      { product.desc && <p>{product.desc}</p> }
      {product.tag && <p className="landing-product-sidebar__tag">{product.tag}</p>}
      {product.link && <a href={product.link} className="landing-button landing-button--medium w-full" target="_blank">Je découvre</a>}
    </div>
  )
}

export default ProductSidebar