import { templatesConstants } from '_constants'
const initialState = { items: [], pagination: {}, create: { collection: {} } }

export function templates(state = initialState, action) {
  switch (action.type) {
    case templatesConstants.GET_ALL:
      return { ...state, items: action.templates.data, pagination: action.templates.pagination }

    case templatesConstants.CREATE:
      const items = [state.items]
      items.push(action.data)
      return { ...state, items, create: { collection: {} } }

    case templatesConstants.CHOOSE_COLLECTION:
      return { ...state, create: { collection: action.collection } }

    case templatesConstants.UPDATE:
      return {
        ...state,
        items: state.items.map((template) => {
          if (template.id === action.template.id) {
            // return updated item
            return action.template
          }
          // Leave every other item unchanged
          return template
        }),
      }

    case templatesConstants.DELETE:
      return {
        ...state,
        items: state.items.filter((template) => {
          if (template.id === action.template.id) return false
          return true
        }),
      }
    default:
      return state
  }
}
