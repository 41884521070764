import { Select } from 'antd'
import Layout from 'components/layout/Layout'
import Button from 'components/buttons/Button'
import React, { useEffect, useState } from 'react'
import PageHeader from 'components/layout/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import { templatesActions } from '_actions'

function TemplateCreation() {
  const dispatch = useDispatch()
  const { Option } = Select
  const alert = useSelector((state) => state.alert)
  const [isLoading, setIsLoading] = useState(false)
  const [template, setTemplate] = useState({
    name: null,
    domain: null,
    type: "full",
    logo: "/salut",
    ctaWording: null,
    ctaBisWording: null,
    ctaRadius: null,
    ctaColor: null,
    titleColor: null
  })

  useEffect(() => {
    if (alert.message !== '') setIsLoading(false)
  }, [alert])

  const submitForm = (e) => {
    e.preventDefault()
    setIsLoading(true)
    dispatch(templatesActions.createTemplate(template))
  }

  const getBase64 = (e) => {
    const temp = {}
    if (e.currentTarget.files.length > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(e.currentTarget.files[0])

      reader.onload = () => {
        temp.logo = reader.result
        setTemplate({ ...template, ...temp })
      }

      reader.onerror = (error) => {
        console.log('Error: ', error)
        return error
      }
    } else {
      temp.logo = null
      setTemplate({ ...template, ...temp })
    }
  }

  return (
    <Layout className="page page-template-creation">
      <PageHeader data={[{ label: 'Templates', url: '/templates' }, { label: 'Créer un template' }]} />
      <div className="page__content">
        <div className="page__intro">
          Configurez votre template en remplissant les champs ci dessous et suivez les instructions d'implémentation sur
          votre canal de distribution.
        </div>
        <div className="fluid-grid">
          <form action="" onSubmit={submitForm}>
            <div className="row mt-20">
              <div className="col-12 md-col-6">
                <div className="text-24">Général</div>
                <label htmlFor="name" className="label">
                  Nom de votre template
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="text-input"
                  placeholder="Nom*"
                  required
                  onChange={(e) => {
                    const temp = {}
                    temp.name = e.currentTarget.value
                    setTemplate({ ...template, ...temp })
                  }}
                />

                <label htmlFor="domain" className="label">
                  Domaine de template
                </label>
                <Select
                  name="domain"
                  onChange={(value) => {
                    const temp = {}
                    temp.domain = value
                    setTemplate({ ...template, ...temp })
                  }}
                >
                  <Option value="homair.colegram.io">homair.colegram.io</Option>
                  <Option value="marvilla.colegram.io">marvilla.colegram.io</Option>
                </Select>

                <label htmlFor="type" className="label">
                  Type de template
                </label>
                <Select
                  name="type"
                  onChange={(value) => {
                    const temp = {}
                    temp.type = value
                    setTemplate({ ...template, ...temp })
                  }}
                >
                  <Option value="full">Full</Option>
                  <Option value="sidebar">Avec sidebar</Option>
                </Select>

                <label htmlFor="ctaWording" className="label">
                  Texte du bouton principal
                </label>
                <input
                  type="text"
                  name="ctaWording"
                  id="ctaWording"
                  className="text-input"
                  placeholder="Texte du bouton*"
                  onChange={(e) => {
                    const temp = {}
                    temp.ctaWording = e.currentTarget.value
                    setTemplate({ ...template, ...temp })
                  }}
                />
                <label htmlFor="ctaBisWording" className="label">
                  Texte du bouton produit
                </label>
                <input
                  type="text"
                  name="ctaBisWording"
                  id="ctaBisWording"
                  className="text-input"
                  placeholder="Texte du bouton*"
                  onChange={(e) => {
                    const temp = {}
                    temp.ctaBisWording = e.currentTarget.value
                    setTemplate({ ...template, ...temp })
                  }}
                />

                <label htmlFor="ctaRadius" className="label">
                  Radius des bouton (ex. 4)
                </label>
                <input
                  type="text"
                  name="ctaRadius"
                  id="ctaRadius"
                  className="text-input"
                  placeholder="Radius en pixel*"
                  onChange={(e) => {
                    const temp = {}
                    temp.ctaRadius = e.currentTarget.value
                    setTemplate({ ...template, ...temp })
                  }}
                />

                <label htmlFor="ctaColor" className="label">
                  Couleur du CTA (ex. #000)
                </label>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <input
                    type="text"
                    name="ctaColor"
                    id="ctaColor"
                    className="text-input"
                    defaultValue={template.ctaColor}
                    placeholder="Code hexa*"
                    onChange={(e) => {
                      const temp = {}
                      temp.ctaColor = e.currentTarget.value
                      setTemplate({ ...template, ...temp })
                    }}
                  />
                  <div style={{ width: "2rem", height: "2rem", marginLeft: "10px", borderRadius: "5px", backgroundColor: template.ctaColor }}></div>
                </div>

                <label htmlFor="titleColor" className="label">
                  Couleur des titres & liens (ex. #000)
                </label>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <input
                    type="text"
                    name="titleColor"
                    id="titleColor"
                    className="text-input"
                    defaultValue={template.titleColor}
                    placeholder="Code hexa*"
                    onChange={(e) => {
                      const temp = {}
                      temp.titleColor = e.currentTarget.value
                      setTemplate({ ...template, ...temp })
                    }}
                  />

                  <div style={{ width: "2rem", height: "2rem", marginLeft: "10px", borderRadius: "5px", backgroundColor: template.titleColor }}></div>
                </div>

                <label htmlFor="logo" className="label">
                  Logo de la page
                </label>
                <input
                  type="file"
                  name="logo"
                  id="logo"
                  className="file-input"
                  placeholder="Logo de la page*"
                  onChange={getBase64}
                />

              </div>
            </div>

            <div className="row mt-10">
              <div className="col-12 md-col-6">
                &nbsp;
                {alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
              </div>
              <div className="col-12 md-col-6 flex flex-col justify-end items-end">
                <Button
                  type="submit"
                  btnStyle="plain"
                  loading={isLoading}
                  color={isLoading ? 'grey' : 'red'}
                >
                  Créer le template
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default TemplateCreation
