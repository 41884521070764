import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { noticesConstants } from '_constants/notices.constants'
import { noticesActions } from '_actions/notices.actions'

export default function RejectedFromFeed({ ...noticeProps }) {
  const dispatch = useDispatch()

  const getImageUrl = (data) => {
    try {
      window.atob(data.substr(23))
      return data
    } catch (e) {
      return `https://instagram.colegram.workers.dev/?image_url=${btoa(data)}`
    }
  }

  useEffect(() => {
    setTimeout(() => {
      dispatch(noticesActions.closeNotice(noticeProps.id))
    }, noticesConstants.AUTOCLOSE_TIMER)
  }, [dispatch, noticeProps])

  return (
    <div className="notice-rejected-from-feed">
      <img src={getImageUrl(noticeProps.url)} className="notice__visual" alt="notice thumbnail" />
      <div className="content">
        <div className="notice__title">Rejeté du feed</div>
        <div className="notice__msg">Ce média a bien été rejeté du feed.</div>
      </div>
    </div>
  )
}
