import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { useDispatch, useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'

import { accountActions, feedActions, modalActions, noticesActions } from '../../_actions/';

// import { ReactComponent as IconInsta} from "../../_svg/icon-insta.svg"
// import { ReactComponent as IconSend } from "../../_svg/icon-send.svg"

import { IconDownload } from '_svg';
import { feedService } from '_services';

import Button from '../buttons/Button'
import MediasListItem from '../_feeds/MediasListItem'
import MediasCardItem from '../_feeds/MediasCardItem'
import CheckBox from '../filters/CheckBox'

import { ReactComponent as IconDelete } from '../../_svg/icon-delete.svg'
import ListDisplayer from '../buttons/ListDisplayer'
import Filters from '../_feeds/Filters'
import HeaderWithTabs from '../_feeds/HeaderWithTabs'
import Pagination from '../navigation/Pagination'
import Sorting from '../filters/Sorting'
import { Skeleton } from 'antd'

function FeedContents() {
  const account = useSelector((state) => state.account)
  const feedsRdux = useSelector(state => state.feeds)
  const [isLoading, setIsLoading] = useState(true)
  const [feed, setFeed] = useState(null)
  const [assets, setAssets] = useState([])
  const [selectedAssets, setSelectedAssets] = useState([])
  const [hasFiltered, setHasFiltered] = useState(false)
  const [displayMode, setDisplayMode] = useState(feedsRdux.displayMode)
  const [pagination, setPagination] = useState({
    current_page: 1,
    limit: 30,
    total_count: 0,
  })
  // pagnation & filters
  const [requestOptions, setRequestOptions] = useState({
    q: '',
    page: 1,
    limit: 30,
    // media_type: 'IMAGE,VIDEO,CAROUSEL_ALBUM',
    sort_by: 'timestamp',
    sort_order: 'DESC',
    has_ask: 'both',
  })

  const urlParams = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
    if (Object.keys(account).length === 0) {
      dispatch(accountActions.getSelf())
    }
  }, [account])

  useEffect(() => {
    if (feedsRdux.items.length <= 0) {
      loadFeeds()
    } else {
      // if(feed) return
      const myFeed = feedsRdux.items.find((feed) => feed.id === urlParams.id)
      setFeed({ ...feed, ...myFeed })
      setSelectedAssets([])
    }
    setIsLoading(false)
  }, [feedsRdux])

  useEffect(() => {
    if (!feed) return
    if (!feed.assets) {
      loadAssets()
    } else {
      if (feed.pagination !== pagination) setPagination(feed.pagination)
      // filter assets to exlude those whose ids are placed into excluded_assets array
      const feedAssets = feed.assets /* .filter(asset => {
        // Remove asset retrun false
        // keep asset return true
        return !feed.excluded_assets.includes(asset.id)
      }) */

      // add hashtag feed for each asset (for Ask modal)
      feedAssets.forEach((el) => {
        el.hashtags = feed.hashtags
      })
      // then store them in state to display
      setAssets(feedAssets)
    }
  }, [feed])

  useEffect(() => {
    // condition te prevent loading on first loading
    if (!feed || !feed.assets) return
    loadAssets()
    setHasFiltered(true)
  }, [requestOptions])

  const handleAddManuallyClick = () => {
    dispatch(modalActions.addFeedMediaManually(feed.id, loadAssets))
  }

  const reloadAll = () => {
    loadFeeds()
    loadAssets()
  }

  const loadFeeds = () => {
    dispatch(feedActions.getAllFeeds())
  }

  const loadAssets = () => {
    setIsLoading(true)
    setSelectedAssets([])
    scrollToTop()
    const feedID = feed ? feed.id : urlParams.id
    dispatch(feedActions.getAssets(feedID, requestOptions))
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleSearch = (str, operatorAnd) => {
    const temp = {
      q: encodeURIComponent(str),
      operatorAnd: operatorAnd,
      page: 1,
    }
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const filterByMediaType = (selection) => {
    const types = Object.entries(selection)
      .filter((el) => el[1])
      .map((el) => el[0])
    const temp = {
      page: 1,
      media_type: types.toString(),
    }
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const filterByAskStatus = (selection) => {
    const temp = {
      page: 1,
      has_ask: selection.toString(),
    }
    setRequestOptions({ ...requestOptions, ...temp })
  }
  const filterByLang = (selection) => {
    if (selection) {
      setRequestOptions({ ...requestOptions, page: 1, language: selection.toString() })
    } else {
      let newReqOpts = { ...requestOptions }
      delete newReqOpts.language
      setRequestOptions(newReqOpts)
    }
  }

  const filterBySelfMedia = (selection) => {
    const temp = {
      page: 1,
      self_media: selection.toString()
    }
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const handlePagination = (index) => {
    const temp = {
      page: index,
    }
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const updatePaginationLimit = (n) => {
    const temp = {
      limit: n,
    }
    setPagination({ ...pagination, ...temp })
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const updateSorting = (sorting) => {
    const temp = {
      sort_by: sorting,
    }
    setRequestOptions({ ...requestOptions, ...temp })
  }

  const selectAsset = (asset, isChecked) => {
    if (isChecked) {
      setSelectedAssets([...selectedAssets, asset])
    } else {
      const newSelectedAssets = selectedAssets.filter((a) => {
        return a.id !== asset.id
      })
      setSelectedAssets(newSelectedAssets)
    }
  }

  const selectAllAssets = (el) => {
    const selectedAssets = []
    if (el.checked) {
      assets.forEach((el) => {
        selectedAssets.push(el)
      })
    }
    setSelectedAssets(selectedAssets)
  }

  const groupReject = (e) => {
    dispatch(modalActions.rejectFromFeed(selectedAssets, feed.id, reloadAll))
  }

  const groupDownloadSelf = (e) => {
    feedService.saveSelfAssets(feed.id, selectedAssets.filter(a => (account.instagram_usernames ?? []).includes(a.authorUsername) || a.hashtags.filter(value => (account.instagram_hashtags ?? []).includes(value)).length > 0).map(a => a.id)).then((result) => {
      dispatch(modalActions.downloadToCollection(result.assets))
      dispatch(noticesActions.postSaved())
    })
  }

  /* const groupOpenIG = (e) => {
    const urls = selectedAssets.map((el)=> {
      return assets.find(a => a.id === el).permalink
    })
    trickOpen(urls, false)
  } */


  /* const trickOpen = (urls, bool) => {
    if(urls.length > 0) {
        if(bool) {
          const randomnumber = Math.floor((Math.random()*100)+1); 
          window.open(urls.shift(), randomnumber, 'scrollbars=1,menubar=0,resizable=1,width=850,height=500');
        } else {
          const link = document.createElement('a')
          link.href = urls.shift()
          link.target = '_blank'
          link.click()
        }
        setTimeout(()=>{trickOpen(urls, !bool)}, 2000)
    }
  } */

  /* const groupAsk = (e) => {
    console.log('groupAsk')
  } */

  const RejectFromFeed = (asset) => {
    dispatch(modalActions.rejectFromFeed([asset], feed.id, reloadAll))
  }

  const ask = (media) => {
    dispatch(
      modalActions.askMessage({
        ...media,
        source: feed.source,
        callback: () => {
          updateAskButton(media.id)
        },
      })
    )
  }

  const updateAskButton = (id) => {
    console.log('updateAskButton', id)
  }

  const getContentsList = () => {
    if (isLoading) {
      return Array.apply(null, Array(requestOptions.limit)).map((el, i) => {
        return (
          <li className="mt-10 stagger-anim" key={i}>
            <Skeleton active avatar paragraph={{ rows: 3 }} />
          </li>
        )
      })
    } else {
      if (assets.length > 0) {
        return assets.map((el) => {
          if (displayMode === 'list') {
            return (
              <li key={el.id} className="stagger-anim">
                <MediasListItem
                  data={el}
                  selectFunction={selectAsset}
                  isSelected={selectedAssets.includes(el)}
                  rejectFn={RejectFromFeed}
                  askFn={ask}
                />
              </li>
            )
          } else if (displayMode === 'grid') {
            return (
              <li key={el.id} className="stagger-anim">
                <MediasCardItem
                  data={el}
                  selectFunction={selectAsset}
                  isSelected={selectedAssets.includes(el)}
                  rejectFn={RejectFromFeed}
                  askFn={ask}
                />
              </li>
            )
          }

          return null
        })
      } else if (feed && feed.status === '-1') {
        return <li className="mt-6 gen-text stagger-anim">Votre feed n'est plus actif !</li>
      } else if (feed && (feed.is_failed === true || hasFiltered)) {
        return <li className="mt-6 gen-text stagger-anim">Votre recherche n'a donné aucun résultat.</li>
      } else {
        if (requestOptions.q === '' && requestOptions.has_ask === 'both') {
          return (
            <li className="mt-6 gen-text stagger-anim">
              <img src="/assets/images/mini-loader.gif" alt="processing" /> Votre feed est en cours de création. Revenez
              dans quelques minutes !
            </li>
          )
        } else {
          return <li className="mt-6 gen-text stagger-anim">Votre recherche n'a donné aucun résultat.</li>
        }
      }
    }
  }

  return (
    <Layout className="page page-feeds-contents">
      <HeaderWithTabs feed={feed} />
      <div>
        <div className="sticky-wrapper">
          <div className={`${isLoading ? ' is-loading' : ''}`}>
            {
              feed &&
              <Filters
                handleAddManuallyClick={handleAddManuallyClick}
                searchFn={handleSearch}
                chbxFilterFn={feed.source === "instagram" ? filterByMediaType : null}
                hashtagFn={handleSearch}
                hashtags={feed.hashtags}
                languages={feed.languages ?? null}
                languageFn={filterByLang}
                askStatusFn={filterByAskStatus}
                selfMediaFn={filterBySelfMedia}
              />
            }
          </div>
          <div className="flex justify-between top-list">
            <CheckBox
              name="action"
              label="Tout selectionner"
              groupAction={true}
              isChecked={selectedAssets.length > 0 && selectedAssets.length === assets.length}
              callback={selectAllAssets}
            />
            {pagination && (
              <span className="assets-total-count">{`${pagination.total_count > 9999 ? 'Plus de ' : ''}${pagination.total_count} Média${pagination.total_count > 1 ? 's' : ''
                }`}</span>
            )}
            <div className="flex flex-nowrap items-center">
              <Sorting
                data={{
                  displayBy: [30, 60],
                  sortBy: [
                    { label: 'Date', value: 'timestamp' },
                    { label: 'Nombre de commentaires', value: 'commentsCount' },
                    { label: 'Nombre de likes', value: 'likeCount' },
                  ],
                }}
                defaultPagination={pagination.limit}
                paginFn={updatePaginationLimit}
                defaultSorting={requestOptions.sort_by}
                sortFn={updateSorting}
              />
              <ListDisplayer
                callback={(display) => {
                  dispatch(feedActions.updateDipsplayMode(display))
                  setDisplayMode(display)
                  scrollToTop()
                }}
                currentDisplay={displayMode}
              />
            </div>
          </div>
        </div>
        <div className="page__content pt-0-important">
          <ul className={`medias-list list-reset ${displayMode}`}>{getContentsList()}</ul>
          {pagination && <Pagination data={pagination} requestFn={handlePagination} />}
        </div>
      </div>
      <div className={`grouped-actions ${selectedAssets.length > 1 ? 'is-active' : ''}`}>
        <div className="grouped-actions__counter">
          Vous avez sélectionné{' '}
          <span className="text-gradient">
            {selectedAssets.length} Média{selectedAssets.length > 1 && 's'}
          </span>
        </div>
        <div className="grouped-actions__ctas">
          {
            selectedAssets.filter(a => (account.instagram_usernames ?? []).includes(a.authorUsername) || a.hashtags.filter(value => (account.instagram_hashtags ?? []).includes(value)).length > 0).length > 0 ?
              <Button onClickFn={groupDownloadSelf} btnStyle={'plain'} type={null} addedclass="button-icon"><IconDownload className="icon icon--white" />Télécharger mes médias</Button>
              : <></>
          }
          <Button onClickFn={groupReject} btnStyle={'border'} color={'grey-lighter'} type={null} addedclass="button-icon"><IconDelete className="icon" />Rejeter du feed</Button>
          {/* <Button onClickFn={groupOpenIG}  btnStyle={'border'} color={'grey-lighter'} type={null} addedclass="button-icon"><IconInsta className="icon"/>Voir sur instagram</Button>
          <Button onClickFn={groupAsk}  btnStyle={'plain'} addedclass="button-icon"><IconSend className="icon"/>demande de droit</Button> */}
        </div >
      </div >
    </Layout >
  )
}

export default FeedContents
