import { modalActions } from '_actions'
import { useDispatch, useSelector } from 'react-redux'
import { IconEdit, IconDelete, IconEditColor, IconDeleteColor, IconMention, IconUser } from '_svg'

export default function UserListItem({ data }) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.authentication.user)

  return (
    <div className="profile-list__item">
      <span>
        <IconUser stroke='#fff' style={{ width: "20px", height: "20px" }} />
      </span>
      <span>
        {data.first_name} {data.last_name} {user.id === data.id ? '(Vous-même)' : ''}
      </span>
      <span>{data.email}</span>
      <div className="profile-list__item__ctas">
        {user.permissions?.includes('user_update') || user.id === data.id ? (
          <button
            className="bordered-icon"
            onClick={() => {
              dispatch(modalActions.updateUser(data))
            }}
          >
            <IconEdit className="icon" />
            <IconEditColor className="icon icon-color" />
          </button>
        ) : (
          <></>
        )}
        {user.id !== data.id && user.permissions?.includes('user_delete') ? (
          <button
            className="bordered-icon"
            style={{ marginLeft: 10 }}
            onClick={() => {
              dispatch(modalActions.deleteUser(data))
            }}
          >
            <IconDelete className="icon" />
            <IconDeleteColor className="icon icon-color" />
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
