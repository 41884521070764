import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/fr'
import { Select } from 'antd'
import Layout from 'components/layout/Layout'
import { useParams } from 'react-router-dom'
import { accountActions, feedActions, modalActions } from '_actions'
import Button from 'components/buttons/Button'
import HeaderWithTabs from 'components/_feeds/HeaderWithTabs'
import HashtagsList from 'components/filters/HashtagsList'
import CheckBox from 'components/filters/CheckBox'
import { PLAN } from '_constants'

function FeedSettings() {
  const feedsRdux = useSelector((state) => state.feeds.items)
  const account = useSelector((state) => state.account)
  const [updateBtn, setUpdateBtn] = useState(false)
  const [labels, setLabels] = useState({
    label: "",
    placeholder: ""
  })
  const { Option } = Select
  const [feed, setFeed] = useState({
    name: '',
    type: '',
    location_type: null,
    locale: '',
    status: '-1',
    hashtags: [],
    blacklisted_words: [],
    hashtags_count: 0,
    mediaNumber: '',
    end_at: null,
    start_at: null,
    description: '',
    notification_enabled: null,
  })
  const [initialFeed, setInitialFeed] = useState({
    hashtags: [],
    blacklisted_words: []
  })

  const [feedForHeader, setFeedForHeader] = useState({
    name: '',
    status: -1,
    start_at: null,
    end_at: null,
  })

  const urlParams = useParams()

  const dispatch = useDispatch()
  const alert = useSelector((state) => state.alert)

  const NativeDatepicker = require('native-datepicker/src/react')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (feedsRdux.length <= 0) {
      dispatch(feedActions.getAllFeeds())
    } else {
      const f = feedsRdux.find((feed) => feed.id === urlParams.id)
      if (!f || !f.id) return

      setFeed({
        id: f.id,
        name: f.name,
        type: f.type,
        location_type: f.location_type,
        locale: f.locale,
        end_at: f.end_at,
        status: f.status,
        start_at: f.start_at,
        hashtags: f.hashtags,
        hashtags_count: f.hashtags_count,
        blacklisted_words: f.blacklisted_words,
        mediaNumber: f.media_number,
        description: f.description,
        notification_enabled: f.notification_enabled,
      })
      setInitialFeed({
        hashtags: f.hashtags,
        location_type: f.location_type,
        mediaNumber: f.media_number,
        blacklisted_words: f.blacklisted_words,
        notification_enabled: f.notification_enabled,
      })
      setFeedForHeader({
        id: f.id,
        name: f.name,
        status: f.status,
        end_at: f.end_at,
        start_at: f.start_at,
      })
    }
  }, [feedsRdux])

  const checkDates = useCallback(() => {
    const dateStart = feed.start_at
    const dateEnd = feed.end_at
    if (feed.status === '0') {
      if (
        moment(dateStart).isAfter(dateEnd) /* || moment(dateEnd).isBefore(dateStart) */ ||
        moment(dateStart).isSame(dateEnd)
      ) {
        let nextDay = moment(dateStart).add(1, 'd').toDate()
        setFeed((feed) => {
          return { ...feed, ...{ end_at: moment(nextDay).format('YYYY-MM-DD') } }
        })
      }
    } else if (feed.status === '1') {
      const today = moment().format('YYYY-MM-DD')
      const tomorrow = moment(today).add(1, 'd').toDate()
      if (moment(dateEnd).isBefore(today) || moment(dateEnd).isSame(today)) {
        setFeed((feed) => {
          return {
            ...feed,
            ...{ start_at: today, end_at: moment(tomorrow).format('YYYY-MM-DD') },
          }
        })
      }
    }
  }, [feed.end_at, feed.start_at, feed.status])

  useEffect(() => {
    checkDates()
  }, [feed.start_at, feed.end_at, checkDates])

  useEffect(() => {
    if (feed.type === "hashtag") {
      setLabels({
        label: "Hashtags",
        placeholder: "Renseigner un hashtag"
      })
    }
    else if (feed.type === "location") {
      setLabels({
        label: "Localisation",
        placeholder: "Renseigner une localisation"
      })
    }
    else if (feed.type === "username") {
      setLabels({
        label: "Username",
        placeholder: "Renseigner un username"
      })
    }
    else if (feed.type === "tagged") {
      setLabels({
        label: "Username",
        placeholder: "Renseigner un username"
      })
    }
  }, [feed.type])

  useEffect(() => {
    if (alert.message !== '') setIsLoading(false)
  }, [alert])

  useEffect(() => {
    if (Object.keys(account).length === 0) {
      dispatch(accountActions.getSelf())
    }
  }, [account])

  useEffect(() => {
    let hashtagDiff = feed.hashtags.filter(x => !initialFeed.hashtags.includes(x))
    let hashtagReverseDiff = initialFeed.hashtags.filter(x => !feed.hashtags.includes(x))
    let badWordsDiff = feed.blacklisted_words.filter(x => !initialFeed.blacklisted_words.includes(x))
    let badWordsReverseDiff = initialFeed.blacklisted_words.filter(x => !feed.blacklisted_words.includes(x))
    if (
      hashtagDiff.length > 0 ||
      hashtagReverseDiff.length > 0 ||
      badWordsDiff.length > 0 ||
      badWordsReverseDiff.length > 0 ||
      feed.mediaNumber !== initialFeed.mediaNumber ||
      feed.location_type !== initialFeed.location_type ||
      feed.notification_enabled !== initialFeed.notification_enabled
    ) {
      setUpdateBtn(true)
    } else {
      setUpdateBtn(false)
    }
  }, [feed])

  const submitForm = (e) => {
    e.preventDefault()
    setIsLoading(true)
    dispatch(feedActions.updateFeed(feed))
  }

  const deleteFeed = () => {
    dispatch(modalActions.deleteFeed(feed))
  }

  const updateBannedHastags = (hashtags) => {
    setFeed({ ...feed, ...{ blacklisted_words: hashtags } })
  }

  return (
    <Layout className="page page-feeds-settings">
      <HeaderWithTabs feed={feedForHeader} />
      <div className="page__content">
        <div className="fluid-grid">
          <form action="" onSubmit={submitForm}>
            <div className="row mt-10">
              <div className="col-12 md-col-6">
                <div className="text-24">Général</div>
                <div className="mt-3">
                  <label htmlFor="name" className="label">
                    Nom de votre feed
                  </label>
                  <p>{feed.name}</p>
                  {/* <input
                    type="text"
                    name="name"
                    id=""
                    className="text-input"
                    defaultValue={feed.name}
                    placeholder="Nom*"
                    required
                    onChange={(e) => {
                      setFeed({ ...feed, ...{ name: e.currentTarget.value } })
                    }}
                  /> */}
                  {/* <label htmlFor="description" className="label">
                    Description
                  </label>
                  <textarea
                    name="description"
                    className="textarea"
                    placeholder="Description"
                    defaultValue={feed.description}
                    onChange={(e) => {
                      setFeed({ ...feed, ...{ description: e.currentTarget.value } })
                    }}
                  /> */}
                </div>
                <label htmlFor="type" className="label">
                  Mode de remontée
                </label>
                <p>{feed.type.charAt(0).toUpperCase() + feed.type.slice(1)}</p>
                {/* <Select
                    name="type"
                    value={feed.type}
                    onChange={(value) => {
                      setFeed({
                        ...feed,
                        type: value,
                        hashtags: [feed.hashtags[0] ?? ''],
                      })
                    }}
                  >
                    <Option value="hashtag">Hashtag</Option>
                    <Option value="location">Localisation</Option>
                    <Option value="username">Username</Option>
                    <Option value="tag">Tag</Option>
                  </Select> */}

                {feed.type === "location" ?
                  <div className="row mt-2">
                    <div className="col-12 md-col-6">
                      <label htmlFor="location_type" className="label">
                        Type de localisation
                      </label>
                      <Select
                        name="location_type"
                        value={feed.location_type}
                        onChange={(value) => {
                          setFeed({
                            ...feed,
                            location_type: value
                          })
                        }}
                      >
                        <Option value="precise">Précise</Option>
                        <Option value="wide">Large</Option>
                      </Select>
                    </div>
                  </div>
                  : <></>}
                {feed.type === "hashtag" ?
                  <HashtagsList title={labels.label} theme={'dark'} preselection={feed.hashtags} hashtagsCount={feed.hashtags_count} limit={account.plan !== PLAN.LIGHT ? 3 : 1} callback={hashtags => {
                    setFeed({ ...feed, hashtags: hashtags.filter(h => h !== "") })
                  }} />
                  :
                  <>
                    <label htmlFor="type" className="label">
                      {labels.label}
                    </label>
                    <input
                      type="text"
                      name=""
                      id=""
                      className="text-input"
                      value={feed.hashtags[0] ?? ""}
                      placeholder={labels.placeholder}
                      onChange={(e) => {
                        setFeed({ ...feed, hashtags: [e.currentTarget.value] })
                      }}
                      required
                    />
                  </>
                }
                <HashtagsList title={'mots à banir'} theme={'dark'} preselection={feed.blacklisted_words} hashtagsCount={feed.hashtags_count} callback={updateBannedHastags} />

                <label htmlFor="type" className="label">
                  Nombre de médias maximum {feed.type === "hashtag" ? <b>par hashtag</b> : ""}
                </label>
                {/* <p>{feed.media_number}</p> */}
                <Select
                  name="mediaNumber"
                  value={feed.mediaNumber.toString()}
                  onChange={(value) => {
                    setFeed({ ...feed, mediaNumber: value })
                  }}
                >
                  <Option value="20">20</Option>
                  <Option value="200">200</Option>
                  <Option value="500">500</Option>
                  <Option value="1000">1000</Option>
                  <Option value="1500">1500</Option>
                  <Option value="2000">2000</Option>
                </Select>

                <div className="mt-10">
                  <CheckBox
                    isChecked={feed.notification_enabled ?? false}
                    label="Je souhaite être notifié des nouveaux médias ajoutés"
                    callback={() => setFeed({ ...feed, ...{ notification_enabled: !feed.notification_enabled } })}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-12 md-col-6 flex justify-start items-start">
                <Button btnStyle={'border'} color={'red'} addedclass={'mr-4'} onClickFn={deleteFeed}>
                  Supprimer le feed
                </Button>
                {updateBtn ?
                  <Button
                    type="submit"
                    btnStyle="plain"
                    loading={isLoading}
                    disabled={isLoading}
                    color={isLoading ? 'grey' : 'red'}
                  >
                    Modifier le feed
                  </Button>
                  : <></>}
              </div>
              <div className="col-12 md-col-6">
                &nbsp;
                {alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default FeedSettings
