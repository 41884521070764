import { feedConstants } from '_constants'
const initialState = { items: [], pagination: {}, hashtags_count: 0, displayMode: 'grid' }

export function feeds(state = initialState, action) {
  switch (action.type) {
    case feedConstants.GET_ALL:
      return {
        ...state,
        items: action.feeds.data,
        pagination: action.feeds.pagination,
        hashtags_count: action.feeds.hashtags_count,
      }
    /* case feedConstants.GET:
            return {
                ...state,
                items: action.feeds
            }; */
    case feedConstants.CREATE:
      return { ...state, items: [...state.items, action.feed], hashtags_count: action.feed.hashtags_count }

    case feedConstants.DELETE:
      return {
        ...state,
        items: state.items.filter((feed) => {
          if (feed.id === action.feed.id) return false
          return true
        }),
      }
    case feedConstants.UPDATE:
      // return [...state, ...[action.feed]]
      return {
        ...state,
        items: state.items.map((feed) => {
          if (feed.id === action.feed.id) {
            return action.feed
          }
          return feed
        }),
      }
    case feedConstants.GET_ASSETS:
      return {
        ...state,
        items: state.items.map((feed) => {
          if (feed.id === action.data.feedId) {
            // return updated item
            return { ...feed, assets: action.data.assets, pagination: action.data.pagination }
          }
          // Leave every other item unchanged
          return feed
        }),
      }
    case feedConstants.GET_ALL_ASSETS:
      let finalAssets = action.data.assets.reduce((a, v) => ({ ...a, [v.id]: v}), {}) 

      return {
        ...state,
        allAssets: { assets: Object.values(finalAssets), pagination: action.data.pagination, feedsData: action.data.feedsData },
      }

    case feedConstants.DELETE_ASSET:
      return {
        ...state
      }
    case feedConstants.UPDATE_ASSET:
      // return [...state, ...[action.feed]]
      return {
        ...state,
        items: state.items.map((feed) => {
          if (feed.id === action.data.feed_id) {
            feed.assets.map((asset) => {
              if (asset.id === action.data.main_asset_id) {
                asset.hasAsk = true
              }
              return asset
            })
            return feed
          }
          return feed
        }),
      }
    case feedConstants.UPDATE_DISPLAYMODE:
      return { ...state, displayMode: action.displayMode }
    default:
      return state
  }
}
