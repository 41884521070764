import { accountConstants } from '_constants'
import { accountService } from '_services'
import { alertActions, modalActions, userActions } from '.'

export const accountActions = {
  getSelf,
  deleteCred,
  updateConfig
}

function getSelf() {
  return (dispatch) => {
    // dispatch(request());
    accountService.getSelf().then(
      (result) => {
        dispatch(success(result))
      },
      (error) => {
        // check if object
        if (typeof error === 'object' && error.message) {
          error = error.message
        }

        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: accountConstants.GET_ALL } }
  function success(result) {
    return { type: accountConstants.GET_SELF_ACCOUNT, result }
  }
  // function failure(error) { return { type: accountConstants.FAILURE, error } }
}

function updateConfig(data) {
  return (dispatch) => {
    // dispatch(request());
    accountService.updateConfig(data).then(
      (result) => {
        dispatch(success(result))
        dispatch(accountActions.getSelf())
      },
      (error) => {
        // check if object
        if (typeof error === 'object' && error.message) {
          error = error.message
        }
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  // function request() { return { type: accountConstants.GET_ALL } }
  function success(result) {
    return { type: accountConstants.UPDATE_CONFIG, result }
  }
  // function failure(error) { return { type: accountConstants.FAILURE, error } }
}

function deleteCred(id) {
  return (dispatch) => {
    // dispatch(request());
    accountService.deleteCred(id).then(
      (result) => {
        dispatch(accountActions.getSelf())
        dispatch(userActions.getSelf())
        dispatch(modalActions.closeModal())
      },
      (error) => {
        // check if object
        if (typeof error === 'object' && error.message) {
          error = error.message
        }

        dispatch(alertActions.error(error.toString()))
      }
    )
  }
}
