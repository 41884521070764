import { IconLibrary } from '_svg'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { noticesActions } from '_actions/notices.actions'
import { noticesConstants } from '_constants/notices.constants'

export default function PasswordReset({ ...noticeProps }) {
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch(noticesActions.closeNotice(noticeProps.id))
    }, noticesConstants.AUTOCLOSE_TIMER)
  })

  return (
    <>
      <div className="notice-profile-updated">
        <div className="icon-wrapper">
          <div className="icon-circle">
            <IconLibrary className="icon" />
          </div>
        </div>
        <div className="content">
          <div className="notice__title">Vérifiez vos emails</div>

          <div
            className="notice__msg"
            dangerouslySetInnerHTML={{
              __html: `Un email vous a été envoyé pour réinitialiser votre mot de passe.`,
            }}
          ></div>
        </div>
      </div>
    </>
  )
}
