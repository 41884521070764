import { ReactComponent as IconClose } from "../../_svg/icon-close.svg"
import { ReactComponent as IconChevronDown } from "../../_svg/icon-chevron-down.svg"
import Infos from "./Infos"
import Product from "./Product"
import { useEffect, useState, useRef } from "react"

const Modal = ({ modalInfos , setModalOpen, modalOpen }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [moreOpen, setMoreOpen] = useState(false)
  const [product, setProduct] = useState(null)
  const [product2, setProduct2] = useState(null)
  const txtRef = useRef(null)

  const closeModal = () => {
    setModalVisible(false)

    setTimeout(() => {
      setModalOpen(false)
    }, 400)
  }

  const toggleMore = () => {
    setMoreOpen(!moreOpen)
  }

  useEffect(() => {
    if (modalOpen) {
      setModalVisible(true)
    }

    if (modalInfos.productLink) {
      setProduct({
        link: modalInfos.productLink,
        desc: modalInfos.productDesc,
        img: modalInfos.productImgLink,
        title: modalInfos.productTitle
      })
    }

    if (modalInfos.productLink2) {
      setProduct2({
        link: modalInfos.productLink2,
        desc: modalInfos.productDesc2,
        img: modalInfos.productImgLink2,
        title: modalInfos.productTitle2
      })
    }
  }, [modalOpen])

  useEffect(() => {
    if (txtRef.current) {
      if (txtRef.current.offsetHeight >= 66) {
        setHasMore(true)
      }
    }
  }, [txtRef])

  return (
    <div class={`landing-modal ${modalVisible ? 'is-visible' : ''}`}>
      <div className="landing-modal__bg" onClick={() => closeModal()}></div>
      <div className="landing-modal__inner">
        <button className="landing-modal__close" onClick={() => closeModal()}><IconClose /></button>

        <div class="landing-modal__content">
          <div class="landing-modal__picture">
            { modalInfos.type === 'VIDEO'
              ? <video className="w-full" controls autoplay>
                  <source src={modalInfos.path} />
                </video>
              : <img src={modalInfos.path} alt="" />
            }
          </div>

          <div className="landing-modal__txt-wrap">
            <div>
              <Infos
                isModal={true}
                addClass="landing-modal__top"
                platform={modalInfos.platform}
                likes={modalInfos.likeCount}
                comments={modalInfos.commentsCount}
                date={modalInfos.timestamp}
                user={modalInfos.authorUsername ?? modalInfos.authorName}
                link={modalInfos.permalink}
              />

              <div className={`landing-modal__txt ${hasMore && !moreOpen ? 'landing-modal__txt--cut' : ''}`}>
                <p ref={txtRef}>
                  {modalInfos.caption}
                </p>
              </div>
              { hasMore &&
                <button className={`landing-modal__chevron ${moreOpen ? 'landing-modal__chevron--open' : ''}`} onClick={() => toggleMore()}><IconChevronDown /></button>
              }

              { product &&
                <div className="landing-modal__products">
                  <Product isSingle={product2 === null} product={product} />
                  { product2 &&
                    <Product isSingle={false} product={product2} />
                  }
                </div>
              }
            </div>

            <p className="landing-modal__powered">Powered by <a href="https://colegram.io/" target="_blank">Colegram</a></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal