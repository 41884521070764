import React, { useState } from 'react'

export default function RadiosModule({ name, data = [{ label: '', value: '' }], callback = null, light = true, textColor = "#fff", noPurple = false, flex = false }) {
  const [value, setValue] = useState(data[0].value)

  const handleClick = (e) => {
    setValue(e.currentTarget.value)
    if (callback) callback(e.currentTarget.value)
  }

  const getContents = () => {
    return data.map((el, i) => {
      return (
        <label className={`${flex ? "radio-horizontal" : ""} custom-radio custom-radio${light ? "--light" : ""} ${noPurple ? "custom-radio-no-purple" : "" }`} key={i}>
          <input
            type="radio"
            name={name}
            value={el.value}
            onChange={(e) => handleClick(e)}
            checked={el.value.toString() === value.toString()}
          />
          <span className="checkmark mr-4" />
          <div className="text-label color--white" style={{color: textColor}}>{el.label}</div>
        </label>
      )
    })
  }

  return <div className="radios-module" style={flex ? { "display": "flex", "justifyContent": "space-between" } : {}}>{getContents()}</div>
}
