import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from 'components/layout/Layout'
import { accountActions, alertActions, modalActions, userActions } from '_actions'
import Button from 'components/buttons/Button'
import HeaderWithTabs from 'components/_account/HeaderWithTabs'

import { IconEdit, IconEditColor } from '_svg'
import { accountEmpty, userEmpty } from '_services/Utils'
import { PLAN } from '_constants'

function AccountProfile() {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.authentication.user)
  const alert = useSelector((state) => state.alert)

  const account = useSelector((state) => state.account)
  const [profile, setProfile] = useState({
    email: '',
    first_name: '',
    img: '',
    last_name: '',
    id: '',
    name: '',
    plan: '',
    ig_username: '',
    ig_name: '',
    user_list: [],
    posts: '',
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (userEmpty(user)) dispatch(userActions.getSelf('/account/profile'))
    if (accountEmpty(account)) dispatch(accountActions.getSelf())
  }, [dispatch, account, user])

  useEffect(() => {
    // setProfile({ ...profile, ...user })
    setProfile((currentProfile) => {
      return { ...currentProfile, ...user }
    })
  }, [user])

  useEffect(() => {
    // setProfile({ ...profile, ...account })
    setProfile((currentProfile) => {
      return {
        ...currentProfile,
        ...{
          feed_count: account.feed_count,
          feed_quotas: account.feed_quotas,
          hashtags_count: account.hashtags_count,
          account_id: account.id,
          ig_name: account.ig_name,
          ig_username: account.ig_username,
          name: account.name,
          plan: account.plan,
          plan_name: account.plan_name,
          user_list: account.user_list,
          widget_count: account.widget_count,
          widget_quotas: account.widget_quotas,
        },
      }
    })
  }, [account])

  const updateProfilePicture = (data) => {
    let file = data.target.files[0]
    let extension

    if (file.size > 1000000) {
      dispatch(alertActions.error('You file must not be larger than 1Mb !'))
    } else {
      switch (file.type) {
        case 'image/jpeg':
          extension = 'jpg'
          break
        case 'image/png':
          extension = 'png'
          break
        case 'image/webp':
          extension = 'webp'
          break
        default:
          extension = null
      }

      if (!extension) {
        dispatch(alertActions.error('Only jpg, png and webp files are allowed...'))
      } else {
        getBase64(data.target.files[0], (result) => {
          dispatch(userActions.updateProfilePicture(result, extension))
        })
      }
    }
  }
  const getBase64 = (file, cb) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const submitForm = (e) => {
    const { id, first_name, last_name, email, name } = profile
    if (
      id === '' ||
      first_name === '' ||
      last_name === '' ||
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    )
      return
    e.preventDefault()
    dispatch(userActions.update({ id, first_name, last_name, email }, name))
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }

  const logout = () => {
    dispatch(userActions.logout())
  }

  return (
    <Layout className="page page-account-profile">
      <HeaderWithTabs />
      <div className="page__content">
        <div className="fluid-grid">
          <form onSubmit={submitForm}>
            <div className="row mt-10">
              <div className="col-12 md-col-6">
                <div className="profile-picture flex items-center">
                  <div
                    className="visual"
                    style={{
                      backgroundImage: `url('${user && user.img ? user.img : '/assets/images/default-profile.jpg'}')`,
                    }}
                  >
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      name="profile-picture-input"
                      id="profile-picture-input"
                      onChange={updateProfilePicture}
                    />
                    <label className="profile-picture-icon" htmlFor="profile-picture-input">
                      <IconEdit className="icon" />
                      <IconEditColor className="icon icon-color" />
                    </label>
                  </div>
                  <div className="profile-name text-24">
                    <div>
                      {profile.first_name} {profile.last_name}
                    </div>
                    <Button btnStyle="border" color="red" addedclass="mt-1" onClickFn={logout}>
                      Déconnexion
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-12 md-col-6">
                &nbsp;
                {alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
              </div>
            </div>
            <div className="row">
              <div className="col-12 md-col-6">
                <div className="text-24 mt-10">Informations Générales</div>
                <div className="mt-3">
                  <label htmlFor="lastname" className="label">
                    Nom
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    className="text-input"
                    value={`${profile.last_name}`}
                    placeholder="Nom*"
                    required
                    onChange={(e) => {
                      const temp = {}
                      temp.last_name = e.currentTarget.value
                      setProfile({ ...profile, ...temp })
                    }}
                  />
                  <label htmlFor="firstname" className="label">
                    Prénom
                  </label>
                  <input
                    type="text"
                    name="firstname"
                    className="text-input"
                    value={`${profile.first_name}`}
                    placeholder="Prénom*"
                    required
                    onChange={(e) => {
                      const temp = {}
                      temp.first_name = e.currentTarget.value
                      setProfile({ ...profile, ...temp })
                    }}
                  />
                  <label htmlFor="email" className="label">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="text-input"
                    value={`${profile.email}`}
                    placeholder="Email*"
                    required
                    onChange={(e) => {
                      const temp = {}
                      temp.email = e.currentTarget.value
                      setProfile({ ...profile, ...temp })
                    }}
                  />

                  {user && user.id && (
                    <Button
                      btnStyle={'border'}
                      color={'grey'}
                      addedclass={'mt-8'}
                      onClickFn={() => {
                        dispatch(modalActions.changePassword(profile.id))
                      }}
                    >
                      {'Changer de mot de passe'}
                    </Button>
                  )}
                </div>
              </div>

              <div className="col-12 md-col-6">
                <div className="text-24 mt-10">Quotas</div>
                <p className="flex">
                  <span className="label mr-2">Plan : </span>
                  <span className="label label--med label--white"> {account.plan_name}</span>
                </p>
                {[PLAN.LIGHT, PLAN.PRO].includes(account.plan) && (
                  <>
                    <p className="flex">
                      <span className="label">Nombre d'utilisateurs max. : </span>
                      <span> </span>
                      <span className="label label--med label--white">
                        {account.user_count}/{account.user_quotas}
                      </span>
                    </p>
                    <p className="flex">
                      <span className="label">Nombre de feeds max. : </span>
                      <span className="label label--med label--white">
                        {account.feed_count}/{account.feed_quotas}
                      </span>
                    </p>
                    <p className="flex">
                      <span className="label">Nombre de widgets max : </span>
                      <span className="label label--med label--white">
                        {account.widget_count}/{account.widget_quotas}
                      </span>
                    </p>
                  </>
                )}
                {PLAN.ULTRA === account.plan && (
                  <div>
                    <span className="label label--white mt-2">
                      <b>Admin</b>
                    </span>
                    <p className="flex">
                      <span className="label mt-2">Nombre d'utilisateurs max. : </span>
                      <span> </span>
                      <span className="label mt-2 label--med label--white">Illimité</span>
                    </p>
                    <p className="flex">
                      <span className="label mt-2">Nombre de feeds max. : </span>
                      <span className="label mt-2 label--med label--white">Illimité</span>
                    </p>
                    <p className="flex">
                      <span className="label mt-2">Nombre de widgets max : </span>
                      <span className="label mt-2 label--med label--white">Illimité</span>
                    </p>
                    <span className="label label--white mt-2">
                      <b>Sous compte</b>
                    </span>
                    <p className="flex">
                      <span className="label mt-2">Nombre de feeds max. : </span>
                      <span className="label mt-2 label--med label--white">5</span>
                    </p>
                    <p className="flex">
                      <span className="label mt-2">Nombre de widgets max : </span>
                      <span className="label mt-2 label--med label--white">5</span>
                    </p>
                  </div>
                )}
              </div >
            </div >
            {user && user.id && (
              <div className="row mt-10">
                <div className="col-12 flex justify-end items-end">
                  <Button
                    type="submit"
                    btnStyle="plain"
                    loading={isLoading}
                    onClickFn={submitForm}
                    color={isLoading ? 'grey' : 'red'}
                  >
                    Sauvegarder les modifications
                  </Button>
                </div>
              </div>
            )
            }
          </form >
        </div >
      </div >
    </Layout >
  )
}

export default AccountProfile
