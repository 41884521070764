import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { userActions } from '_actions'
import Button from 'components/buttons/Button'

export default function CreateUser({ ...modalProps }) {
  const dispatch = useDispatch()
  const [user, setUser] = useState({
    first_name: modalProps.user?.first_name ?? '',
    last_name: modalProps.user?.last_name ?? '',
    email: modalProps.user?.email ?? '',
    plain_password: '',
  })
  const [userValid, setUserValid] = useState(false)

  const submitForm = (e) => {
    e.preventDefault()
    if (modalProps.user) {
      dispatch(
        userActions.update(
          {
            id: modalProps.user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
          },
          user.first_name + ' ' + user.last_name
        )
      )
    } else {
      dispatch(userActions.create(user, user.first_name + ' ' + user.last_name))
    }
  }

  useEffect(() => {
    const checkUser = () => {
      if (user.first_name.length > 0 && user.last_name.length > 0 && user.email.length > 0) {
        setUserValid(true)
      } else {
        setUserValid(false)
      }
    }
    checkUser()
  }, [user])

  return (
    <div className="modal-create-message">
      <div className="modal__title">Utilisateur</div>
      <form className="flex flex-col items-start" onSubmit={submitForm}>
        <div className="label label--med">Prénom</div>
        <input
          type="text"
          name="first_name"
          className="text-input"
          placeholder="Prénom"
          value={user.first_name}
          onChange={(e) => {
            setUser({
              ...user,
              first_name: e.target.value,
            })
          }}
          required
        />
        <div className="label label--med">Nom</div>
        <input
          type="text"
          name="last_name"
          className="text-input"
          placeholder="Nom"
          value={user.last_name ?? ''}
          onChange={(e) => {
            setUser({
              ...user,
              last_name: e.target.value,
            })
          }}
          required
        />
        <div className="label label--med">Email</div>
        <input
          type="text"
          name="email"
          className="text-input"
          placeholder="Email"
          value={user.email ?? ''}
          onChange={(e) => {
            setUser({
              ...user,
              email: e.target.value,
            })
          }}
          required
        />
        {!modalProps.user ? (
          <>
            <div className="label label--med">Mot de passe</div>
            <input
              type="password"
              name="plain_password"
              className="text-input"
              placeholder="Mot de passe"
              value={user.plain_password ?? ''}
              onChange={(e) => {
                setUser({
                  ...user,
                  plain_password: e.target.value,
                })
              }}
              required
            />
          </>
        ) : (
          <></>
        )}
        {userValid && (
          <Button btnStyle="plain" type="submit" addedclass="self-end mt-6">
            {modalProps.user ? 'Modifier' : 'Sauvegarder'}
          </Button>
        )}
      </form>
    </div>
  )
}
