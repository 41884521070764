import { history } from '_helpers'
import { alertConstants } from '_constants'

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message,
      }
    case alertConstants.ERROR:
      if (action.message === 'error with token') {
        setTimeout(() => {
          history.push('/welcome')
        }, 300)
      }

      return {
        type: 'alert-danger',
        message: action.message,
      }
    case alertConstants.CLEAR:
      return {}
    default:
      return state
  }
}
