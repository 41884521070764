import { libraryConstants } from '_constants'
const initialState = { assets: [], pagination: {}, displayMode: 'grid' }

export function library(state = initialState, action) {
  switch (action.type) {
    case libraryConstants.GET_ALL_ASSETS:
      return { ...state, assets: action.data, pagination: action.pagination }
    case libraryConstants.GET_ASSET:
      const update = state.assets.map((el) => (el.id === action.data.id ? { ...el, ...action.data } : el))
      return { ...state, assets: update }
    case libraryConstants.DELETE_ASSETS:
      const updatedAssets = state.assets.filter((el) => !action.data.includes(el))
      return { ...state, assets: updatedAssets }
    case libraryConstants.UPDATE_DISPLAYMODE:
      return { ...state, displayMode: action.displayMode }
    default:
      return state
  }
}
