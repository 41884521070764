export const templatesConstants = {
  GET_ALL: 'TEMPLATES_GET_ALL',
  CREATE: 'TEMPLATE_CREATE',
  CHOOSE_COLLECTION: 'TEMPLATE_CHOOSE_COLLECTION',
  CREATE_FAILURE: 'TEMPLATE_CREATE_FAILURE',
  UPDATE: 'TEMPLATE_UPDATE',
  DELETE: 'TEMPLATE_DELETE',
  ERROR_QUOTA_EXCEEDED:
    'Vous avez atteint le nombre de templates autorisé. Veuillez en supprimer un pour en créer un nouveau',
}
