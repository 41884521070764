import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { collectionsActions, modalActions } from '_actions'
import Button from 'components/buttons/Button'

export default function CreateCollection({ ...modalProps }) {
  const [inputValue, setinputValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const submitForm = (e) => {
    e.preventDefault()
    if (isLoading) return
    setIsLoading(true)
    dispatch(collectionsActions.createCollection({ name: inputValue, mediaIDtoGoBack: modalProps.mediaID }))
  }

  const handleInputChange = (e) => {
    setinputValue(e.target.value)
  }

  const handleBack = () => {
    dispatch(modalActions.addToCollection(modalProps.mediaID))
  }

  return (
    <div className="modal-create-collection">
      <div className="modal__title">
        {modalProps.canGoBack && (
          <span className="bt-back" onClick={handleBack}>
            ‹
          </span>
        )}
        Créer une collection
      </div>
      <div className="modal__grip">Quel nom souhaitez-vous donner à cette collection ?</div>
      <form className="flex flex-col items-start" onSubmit={submitForm}>
        <div className="label label--med">Nom de la collection</div>
        <input
          type="text"
          className="input-text-grey"
          onChange={handleInputChange}
          value={inputValue}
          placeholder="Nom de votre collection"
          autoFocus
        />
        {inputValue.length > 1 && (
          <Button loading={isLoading} disabled={isLoading} btnStyle="plain" type="submit" addedclass="self-end mt-20">
            Valider
          </Button>
        )}
      </form>
    </div>
  )
}
